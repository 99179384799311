import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Flex } from '@aws-amplify/ui-react';
import { TabBarItem } from '../ui-components';

const TabBarC = ({ gptMetaData }) => {
  const location = useLocation();
  const { audioId } = useParams();
  const [activeTab, setActiveTab] = useState('Item1');

  useEffect(() => {
    // Set active tab based on current URL path
    if (location.pathname.includes('/app/transcription')) {
      setActiveTab('Item1');
    } else if (location.pathname.includes('/app/summary')) {
      setActiveTab('Item2');
    } else if (location.pathname.includes('/app/analyse')) {
      setActiveTab('Item3');
    }
  }, [location.pathname]);

  const tabs = [
    { key: 'Item1', label: 'Transcription', path: `/app/transcription/${audioId || ''}` },
    { key: 'Item2', label: 'Documents', path: `/app/summary/${audioId || ''}` },
    { key: 'Item3', label: 'Analyse', path: `/app/analyse/${audioId || ''}` },
  ];

  return (
    <Flex direction="row" justifyContent="space-around" width="100%">
      {tabs.map((tab) => {
        const isDisabled = gptMetaData < 3 && (tab.key === 'Item2' || tab.key === 'Item3');
        const variant = isDisabled
          ? 'Disable'
          : tab.key === activeTab
          ? 'Active'
          : 'Default';

        return (
          <Flex key={tab.key} flex="1" justifyContent="center">
            <TabBarItem
              variant={variant}
              overrides={{
                Item: {
                  children: isDisabled ? (
                    <span style={{ color: 'gray' }}>{tab.label}</span>
                  ) : (
                    <Link
                      to={tab.path}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {tab.label}
                    </Link>
                  ),
                },
                TabBarItem: {
                  onClick: () => {
                    if (!isDisabled) {
                      setActiveTab(tab.key);
                    }
                  },
                  style: {
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    flexBasis: 'auto',
                    flexShrink: 0,
                  },
                },
              }}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default TabBarC;