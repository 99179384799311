import React from 'react';
import { View } from '@aws-amplify/ui-react';
import { Background } from '../ui-components';

const BackgroundC = ({ children }) => {
  return (
    <Background
      overrides={{
        Background: {
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    >
      <View
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        {children}
      </View>
    </Background>
  );
};

export default BackgroundC;