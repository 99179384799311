/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://iiru5uxupnfg7flz4kpgxwwtli.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-3:f34dbde6-db7b-4a1e-bb48-0acb58a533b6",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_ZbRYYq0ke",
    "aws_user_pools_web_client_id": "2s9rpiiml2aj9kggrb3ng2jdae",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "s3-audiodeem-storage38c46-dev",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
