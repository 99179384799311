/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function WordButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, Word: {}, WordButton: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M23.871 2.27183L14.761 2.27183L14.761 0L13.0628 0L0 2.27749L0 22.1501L12.9862 24.4219L14.761 24.4219L14.761 22.1586L23.2859 22.1586C23.7687 22.1358 24.3054 22.1728 24.72 21.8774C25.0523 21.3691 24.9671 20.733 24.9955 20.1594L24.9955 3.37363C25.0551 2.72617 24.5184 2.20649 23.871 2.27183ZM9.47339 16.0702C8.99917 16.3172 8.28921 16.0588 7.72696 16.0985C7.34927 14.2187 6.90908 12.3501 6.57119 10.4617C6.23892 12.2961 5.80728 14.1135 5.42676 15.9367C4.88154 15.9083 4.3335 15.8742 3.7854 15.8373C3.31397 13.3383 2.76025 10.8564 2.32007 8.35171C2.80566 8.32901 3.29409 8.30913 3.77969 8.29209C4.07217 10.101 4.40444 11.9014 4.66006 13.7132C5.06045 11.856 5.46934 9.99878 5.86695 8.1416C6.4065 8.11035 6.94605 8.08765 7.4856 8.05923C7.86328 9.97608 8.24947 11.89 8.6584 13.7983C8.9793 11.8276 9.33423 9.86245 9.67788 7.89453C10.2458 7.87466 10.8138 7.84341 11.3789 7.80933C10.7371 10.561 10.1748 13.3355 9.47339 16.0702ZM23.6439 20.8099L14.761 20.8099L14.761 19.0263L22.1443 19.0263L22.1443 17.8904L14.761 17.8904L14.761 16.4706L22.1443 16.4706L22.1443 15.3347L14.761 15.3347L14.761 13.9148L22.1443 13.9148L22.1443 12.7789L14.761 12.7789L14.761 11.359L22.1443 11.359L22.1443 10.2231L14.761 10.2231L14.761 8.80323L22.1443 8.80323L22.1443 7.66734L14.761 7.66734L14.761 6.24746L22.1443 6.24746L22.1443 5.11157L14.761 5.11157L14.761 3.61206L23.6439 3.61206L23.6439 20.8099Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Word: { color: "rgba(154,226,161,1)" },
        WordButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M23.871 2.27183L14.761 2.27183L14.761 0L13.0628 0L0 2.27749L0 22.1501L12.9862 24.4219L14.761 24.4219L14.761 22.1586L23.2859 22.1586C23.7687 22.1358 24.3054 22.1728 24.72 21.8774C25.0523 21.3691 24.9671 20.733 24.9955 20.1594L24.9955 3.37363C25.0551 2.72617 24.5184 2.20649 23.871 2.27183ZM9.47339 16.0702C8.99917 16.3172 8.28921 16.0588 7.72696 16.0985C7.34927 14.2187 6.90908 12.3501 6.57119 10.4617C6.23892 12.2961 5.80728 14.1135 5.42676 15.9367C4.88154 15.9083 4.3335 15.8742 3.7854 15.8373C3.31397 13.3383 2.76025 10.8564 2.32007 8.35171C2.80566 8.32901 3.29409 8.30913 3.77969 8.29209C4.07217 10.101 4.40444 11.9014 4.66006 13.7132C5.06045 11.856 5.46934 9.99878 5.86695 8.1416C6.4065 8.11035 6.94605 8.08765 7.4856 8.05923C7.86328 9.97608 8.24947 11.89 8.6584 13.7983C8.9793 11.8276 9.33423 9.86245 9.67788 7.89453C10.2458 7.87466 10.8138 7.84341 11.3789 7.80933C10.7371 10.561 10.1748 13.3355 9.47339 16.0702ZM23.6439 20.8099L14.761 20.8099L14.761 19.0263L22.1443 19.0263L22.1443 17.8904L14.761 17.8904L14.761 16.4706L22.1443 16.4706L22.1443 15.3347L14.761 15.3347L14.761 13.9148L22.1443 13.9148L22.1443 12.7789L14.761 12.7789L14.761 11.359L22.1443 11.359L22.1443 10.2231L14.761 10.2231L14.761 8.80323L22.1443 8.80323L22.1443 7.66734L14.761 7.66734L14.761 6.24746L22.1443 6.24746L22.1443 5.11157L14.761 5.11157L14.761 3.61206L23.6439 3.61206L23.6439 20.8099Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Word: { color: "rgba(49,112,61,1)" },
        WordButton: {},
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M23.871 2.27183L14.761 2.27183L14.761 0L13.0628 0L0 2.27749L0 22.1501L12.9862 24.4219L14.761 24.4219L14.761 22.1586L23.2859 22.1586C23.7687 22.1358 24.3054 22.1728 24.72 21.8774C25.0523 21.3691 24.9671 20.733 24.9955 20.1594L24.9955 3.37363C25.0551 2.72617 24.5184 2.20649 23.871 2.27183ZM9.47339 16.0702C8.99917 16.3172 8.28921 16.0588 7.72696 16.0985C7.34927 14.2187 6.90908 12.3501 6.57119 10.4617C6.23892 12.2961 5.80728 14.1135 5.42676 15.9367C4.88154 15.9083 4.3335 15.8742 3.7854 15.8373C3.31397 13.3383 2.76025 10.8564 2.32007 8.35171C2.80566 8.32901 3.29409 8.30913 3.77969 8.29209C4.07217 10.101 4.40444 11.9014 4.66006 13.7132C5.06045 11.856 5.46934 9.99878 5.86695 8.1416C6.4065 8.11035 6.94605 8.08765 7.4856 8.05923C7.86328 9.97608 8.24947 11.89 8.6584 13.7983C8.9793 11.8276 9.33423 9.86245 9.67788 7.89453C10.2458 7.87466 10.8138 7.84341 11.3789 7.80933C10.7371 10.561 10.1748 13.3355 9.47339 16.0702ZM23.6439 20.8099L14.761 20.8099L14.761 19.0263L22.1443 19.0263L22.1443 17.8904L14.761 17.8904L14.761 16.4706L22.1443 16.4706L22.1443 15.3347L14.761 15.3347L14.761 13.9148L22.1443 13.9148L22.1443 12.7789L14.761 12.7789L14.761 11.359L22.1443 11.359L22.1443 10.2231L14.761 10.2231L14.761 8.80323L22.1443 8.80323L22.1443 7.66734L14.761 7.66734L14.761 6.24746L22.1443 6.24746L22.1443 5.11157L14.761 5.11157L14.761 3.61206L23.6439 3.61206L23.6439 20.8099Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Word: { color: "rgba(239,240,240,1)" },
        WordButton: {},
      },
      variantValues: { variant: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="230px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "WordButton")}
      {...rest}
    >
      <Icon
        width="25px"
        height="24.42px"
        viewBox={{ minX: 0, minY: 0, width: 25, height: 24.4218807220459 }}
        paths={[
          {
            d: "M23.871 2.27183L14.761 2.27183L14.761 0L13.0628 0L0 2.27749L0 22.1501L12.9862 24.4219L14.761 24.4219L14.761 22.1586L23.2859 22.1586C23.7687 22.1358 24.3054 22.1728 24.72 21.8774C25.0523 21.3691 24.9671 20.733 24.9955 20.1594L24.9955 3.37363C25.0551 2.72617 24.5184 2.20649 23.871 2.27183ZM9.47339 16.0702C8.99917 16.3172 8.28921 16.0588 7.72696 16.0985C7.34927 14.2187 6.90908 12.3501 6.57119 10.4617C6.23892 12.2961 5.80728 14.1135 5.42676 15.9367C4.88154 15.9083 4.3335 15.8742 3.7854 15.8373C3.31397 13.3383 2.76025 10.8564 2.32007 8.35171C2.80566 8.32901 3.29409 8.30913 3.77969 8.29209C4.07217 10.101 4.40444 11.9014 4.66006 13.7132C5.06045 11.856 5.46934 9.99878 5.86695 8.1416C6.4065 8.11035 6.94605 8.08765 7.4856 8.05923C7.86328 9.97608 8.24947 11.89 8.6584 13.7983C8.9793 11.8276 9.33423 9.86245 9.67788 7.89453C10.2458 7.87466 10.8138 7.84341 11.3789 7.80933C10.7371 10.561 10.1748 13.3355 9.47339 16.0702ZM23.6439 20.8099L14.761 20.8099L14.761 19.0263L22.1443 19.0263L22.1443 17.8904L14.761 17.8904L14.761 16.4706L22.1443 16.4706L22.1443 15.3347L14.761 15.3347L14.761 13.9148L22.1443 13.9148L22.1443 12.7789L14.761 12.7789L14.761 11.359L22.1443 11.359L22.1443 10.2231L14.761 10.2231L14.761 8.80323L22.1443 8.80323L22.1443 7.66734L14.761 7.66734L14.761 6.24746L22.1443 6.24746L22.1443 5.11157L14.761 5.11157L14.761 3.61206L23.6439 3.61206L23.6439 20.8099Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Word"
        {...getOverrideProps(overrides, "Word")}
      ></Text>
    </Flex>
  );
}
