/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function DownloadButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Vector: {},
        "T\u00E9l\u00E9charger": {},
        DownloadButton: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M12.5 18.75L4.6875 10.9375L6.875 8.67188L10.9375 12.7344L10.9375 0L14.0625 0L14.0625 12.7344L18.125 8.67188L20.3125 10.9375L12.5 18.75ZM0 25L0 17.1875L3.125 17.1875L3.125 21.875L21.875 21.875L21.875 17.1875L25 17.1875L25 25L0 25Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "T\u00E9l\u00E9charger": { color: "rgba(154,226,161,1)" },
        DownloadButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M12.5 18.75L4.6875 10.9375L6.875 8.67188L10.9375 12.7344L10.9375 0L14.0625 0L14.0625 12.7344L18.125 8.67188L20.3125 10.9375L12.5 18.75ZM0 25L0 17.1875L3.125 17.1875L3.125 21.875L21.875 21.875L21.875 17.1875L25 17.1875L25 25L0 25Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "T\u00E9l\u00E9charger": { color: "rgba(49,112,61,1)" },
        DownloadButton: {},
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M12.5 18.75L4.6875 10.9375L6.875 8.67188L10.9375 12.7344L10.9375 0L14.0625 0L14.0625 12.7344L18.125 8.67188L20.3125 10.9375L12.5 18.75ZM0 25L0 17.1875L3.125 17.1875L3.125 21.875L21.875 21.875L21.875 17.1875L25 17.1875L25 25L0 25Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "T\u00E9l\u00E9charger": { color: "rgba(239,240,240,1)" },
        DownloadButton: {},
      },
      variantValues: { variant: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="230px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "DownloadButton")}
      {...rest}
    >
      <Icon
        width="25px"
        height="25px"
        viewBox={{ minX: 0, minY: 0, width: 25, height: 25 }}
        paths={[
          {
            d: "M12.5 18.75L4.6875 10.9375L6.875 8.67188L10.9375 12.7344L10.9375 0L14.0625 0L14.0625 12.7344L18.125 8.67188L20.3125 10.9375L12.5 18.75ZM0 25L0 17.1875L3.125 17.1875L3.125 21.875L21.875 21.875L21.875 17.1875L25 17.1875L25 25L0 25Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Télécharger"
        {...getOverrideProps(overrides, "T\u00E9l\u00E9charger")}
      ></Text>
    </Flex>
  );
}
