import React from 'react';
import { Loader, View } from '@aws-amplify/ui-react';
import { KeyPointContainer } from '../ui-components';
import SectionListC from './SectionListC';

const KeyPointContainerC = ({ keyPoints, audioTime, setAudioCurrentTime, loading }) => {
  return (
    <KeyPointContainer
      overrides={{
        KeyPointContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '480px',
          height: '744px',
          padding: '20px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <Loader size="large" style={{ transform: 'scale(2)' }} />
        </div>
      ) : (
        <View style={{ flex: 1, overflowY: 'auto' }}>
          <SectionListC keyPoints={keyPoints} audioTime={audioTime} setAudioCurrentTime={setAudioCurrentTime} />
        </View>
      )}
    </KeyPointContainer>
  );
};

export default KeyPointContainerC;