import React, { useState, useEffect, useCallback } from 'react';
import BackgroundC from '../react-components/BackgroundC';
import SideBarC from '../react-components/SideBarC';
import BlockContainerC from '../react-components/BlockContainerC';
import ManageContainerC from '../react-components/ManageContainerC';
import TabBarTemC from '../react-components/TabBarTemC';
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { downloadData, uploadData, remove } from 'aws-amplify/storage';
import { listTemplates } from '../graphql/queries';
import { createTemplate, deleteTemplate } from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

const TemplatesPage = () => {
  const [templates, setTemplates] = useState([]);
  const [originalTemplates, setOriginalTemplates] = useState([]);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [newBlockAdded, setNewBlockAdded] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state

  const fetchTemplates = useCallback(async (userId) => {
    setLoading(true); // Set loading to true when starting the fetch
    try {
      const client = generateClient();
      const result = await client.graphql({
        query: listTemplates,
        variables: { userId }
      });
      console.log('Fetched templates from API:', result.data.listTemplates.items);
      const fetchedTemplates = result.data.listTemplates.items;

      const templatesWithContent = await Promise.all(fetchedTemplates.map(async (template) => {
        const { body } = await downloadData({
          path: template.templatePath
        }).result;
        const text = await body.text();
        console.log(`Fetched template content from S3 for template ${template.id}:`, text);
        const content = JSON.parse(text);
        return { ...template, blocks: content.blocks };
      }));

      console.log('Templates with content:', templatesWithContent);
      setTemplates(templatesWithContent);
      setOriginalTemplates(JSON.parse(JSON.stringify(templatesWithContent)));
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoading(false); // Set loading to false when fetch is complete
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await getCurrentUser();
        console.log('Current user:', user);
        const userId = user.userId;
        await fetchTemplates(userId);
      } catch (error) {
        console.error('Error initializing templates page:', error);
      }
    };

    initialize();
  }, [fetchTemplates]);

  useEffect(() => {
    if (newBlockAdded) {
      setNewBlockAdded(false);
    }
  }, [newBlockAdded]);

  const addTemplate = async (templateName) => {
    try {
      const user = await getCurrentUser();
      const userId = user.userId;
      const templateId = uuidv4();
      const newTemplate = {
        userId,
        id: templateId,
        name: templateName,
        templatePath: `private/${userId}/templates/${templateId}.json`,
        blocks: [
          {
            heading: 'Default Block Heading',
            keyPoints: ['Default Key Point'],
          },
        ],
      };

      const updatedTemplates = [...templates, newTemplate];
      setTemplates(updatedTemplates);
      setSelectedTemplateIndex(updatedTemplates.length - 1); // Set to the new template
      setHasChanges(true);
      console.log('Template added:', templateName);
    } catch (error) {
      console.error('Error adding template:', error);
    }
  };

  const removeTemplate = async (index) => {
    try {
      const templateToDelete = templates[index];

      // Remove template file from S3
      await remove({ path: templateToDelete.templatePath });
      console.log(`Template file deleted from S3: ${templateToDelete.templatePath}`);

      const client = generateClient();
      await client.graphql({
        query: deleteTemplate,
        variables: { input: { id: templateToDelete.id, userId: templateToDelete.userId } }
      });

      const updatedTemplates = templates.filter((_, i) => i !== index);
      setTemplates(updatedTemplates);
      if (selectedTemplateIndex === index) {
        setSelectedTemplateIndex(0);
      } else if (selectedTemplateIndex > index) {
        setSelectedTemplateIndex(selectedTemplateIndex - 1);
      }
      setHasChanges(true);
      console.log('Template removed:', index);
    } catch (error) {
      console.error('Error removing template:', error);
    }
  };

  const addBlockToTemplate = (index) => {
    const updatedTemplates = [...templates];
    updatedTemplates[index].blocks.push({
      heading: 'New Block Heading',
      keyPoints: ['New Key Point'],
    });
    setTemplates(updatedTemplates);
    setHasChanges(true);
    setNewBlockAdded(true);
    console.log('Block added to template:', index);
  };

  const handleBlocksChange = (updatedBlocks) => {
    const updatedTemplates = [...templates];
    updatedTemplates[selectedTemplateIndex].blocks = updatedBlocks;
    setTemplates(updatedTemplates);
    setHasChanges(true);
  };

  const validateTemplates = async () => {
    try {
      const user = await getCurrentUser();
      const userId = user.userId;
      const session = await fetchAuthSession();
      const identityId = session.identityId;

      const templatesToUpload = templates.filter(template =>
        !originalTemplates.some(orig => orig.id === template.id && JSON.stringify(orig.blocks) === JSON.stringify(template.blocks))
      );

      for (const template of templatesToUpload) {
        const s3Path = `templates/${identityId}/templates/${template.id}.json`;
        await uploadData({
          path: s3Path,
          data: JSON.stringify({ blocks: template.blocks })
        });

        // Only create a new template if it doesn't exist in the originalTemplates
        if (!originalTemplates.some(orig => orig.id === template.id)) {
          const client = generateClient();
          const templateData = {
            userId,
            id: template.id,
            name: template.name,
            templatePath: `private/${identityId}/templates/${template.id}.json`,
          };

          await client.graphql({
            query: createTemplate,
            variables: { input: templateData }
          });
        }
      }

      console.log('Validated templates:', JSON.stringify(templates, null, 2));
      setHasChanges(false);
      setOriginalTemplates(JSON.parse(JSON.stringify(templates)));
    } catch (error) {
      console.error('Error validating templates:', error);
    }
  };

  const cancelChanges = () => {
    setTemplates(JSON.parse(JSON.stringify(originalTemplates)));
    setHasChanges(false);
    setSelectedTemplateIndex(0);
  };

  console.log('Passing template names to TabBarTemC:', templates.map(template => template.name));

  return (
    <div className="TemplatesPage" style={{ width: '100vw', height: '100vh', overflowY: 'auto', position: 'relative' }}>
      <BackgroundC>
        <div style={{ position: 'relative', zIndex: 1002 }}>
          <SideBarC />
        </div>
        <div style={{ marginLeft: '90px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 80px)', minHeight: '100%' }}>
          <div style={{ display: 'flex', width: '100%', maxWidth: '1440px', flex: '1 0 auto', justifyContent: 'space-between', padding: '10px', boxSizing: 'border-box' }}>
            <div style={{ flex: '1 1 66%', paddingRight: '10px', overflow: 'visible' }}>
              <div style={{ marginBottom: '15px' }}>
                <TabBarTemC
                  templates={templates.map(template => ({ name: template.name }))}
                  selectedTemplateIndex={selectedTemplateIndex}
                  onTemplateChange={setSelectedTemplateIndex}
                  loading={loading}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'visible' }}>
                <BlockContainerC
                  key={`${selectedTemplateIndex}-${templates[selectedTemplateIndex]?.blocks.length}`}
                  blocks={templates[selectedTemplateIndex]?.blocks || []}
                  onBlocksChange={handleBlocksChange}
                  newBlockAdded={newBlockAdded}
                  loading={loading} // Pass the loading state here
                />
              </div>
            </div>
            <div style={{ flex: '1 1 27%', paddingTop: '25px', paddingLeft: '10px', paddingRight: '20px', overflow: 'visible' }}>
              <ManageContainerC
                templates={templates}
                selectedTemplateIndex={selectedTemplateIndex}
                addTemplate={addTemplate}
                deleteTemplate={removeTemplate}
                addBlockToTemplate={addBlockToTemplate}
                validateTemplates={validateTemplates}
                hasChanges={hasChanges}
                onCancel={cancelChanges}
                setNewBlockAdded={setNewBlockAdded}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </BackgroundC>
    </div>
  );
};

export default TemplatesPage;