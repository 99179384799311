/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function LogoutxText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Vector: {},
        LogoutLogo: {},
        "D\u00E9connexion": {},
        LogoutxText: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 20.8L0 0L10.426 0L10.426 1.3L1.3 1.3L1.3 19.5L10.426 19.5L10.426 20.8L0 20.8ZM16.2006 15.0007L15.288 14.0647L18.3027 11.05L6.7496 11.05L6.7496 9.75L18.3027 9.75L15.2867 6.734L16.1993 5.8006L20.8 10.4L16.2006 15.0007Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoutLogo: {},
        "D\u00E9connexion": { color: "rgba(154,226,161,1)" },
        LogoutxText: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 20.8L0 0L10.426 0L10.426 1.3L1.3 1.3L1.3 19.5L10.426 19.5L10.426 20.8L0 20.8ZM16.2006 15.0007L15.288 14.0647L18.3027 11.05L6.7496 11.05L6.7496 9.75L18.3027 9.75L15.2867 6.734L16.1993 5.8006L20.8 10.4L16.2006 15.0007Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoutLogo: {},
        "D\u00E9connexion": { fontWeight: "700", color: "rgba(49,112,61,1)" },
        LogoutxText: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="150px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "LogoutxText")}
      {...rest}
    >
      <Flex
        gap="13.000000953674316px"
        direction="row"
        width="31.2px"
        height="31.2px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="5.200000286102295px 5.200000286102295px 5.200000286102295px 5.200000286102295px"
        display="flex"
        {...getOverrideProps(overrides, "LogoutLogo")}
      >
        <Icon
          width="20.8px"
          height="20.8px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 20.799999237060547,
            height: 20.799999237060547,
          }}
          paths={[
            {
              d: "M0 20.8L0 0L10.426 0L10.426 1.3L1.3 1.3L1.3 19.5L10.426 19.5L10.426 20.8L0 20.8ZM16.2006 15.0007L15.288 14.0647L18.3027 11.05L6.7496 11.05L6.7496 9.75L18.3027 9.75L15.2867 6.734L16.1993 5.8006L20.8 10.4L16.2006 15.0007Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Déconnexion"
        {...getOverrideProps(overrides, "D\u00E9connexion")}
      ></Text>
    </Flex>
  );
}
