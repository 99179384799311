import React from 'react';
import { Flex, Text, Image } from '@aws-amplify/ui-react';
import userIcon from '../assets/User.svg';
import systemIcon from '../assets/LogoVector.svg';

const ChatListC = ({ chatItems }) => {
  const getIcon = (item) => {
    return item === 'User' ? userIcon : systemIcon;
  };

  const getBorderColor = (item) => {
    return item === 'User' ? '#DCDDE1' : '#9AE2A1';
  };

  const processedChatItems = [...chatItems].sort((a, b) => {
    const idA = parseInt(a.id.replace('Error', ''), 10);
    const idB = parseInt(b.id.replace('Error', ''), 10);

    if (idA !== idB) {
      return idA - idB;
    }

    return a.item === 'User' ? -1 : 1;
  })

  return (
    <Flex direction="column" gap="25px" width="100%">
      {processedChatItems.map((chatItem, index) => (
        <Flex
          key={index}
          direction="row"
          alignItems="flex-start"
          gap="20px"
          padding="15px"
          style={{
            border: `1px solid ${getBorderColor(chatItem.item)}`,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            maxWidth: '100%',
            backgroundColor: chatItem.status === 'error' ? '#FCE9E9' : 'white',
            borderColor: chatItem.status === 'error' ? '#950404' : getBorderColor(chatItem.item),
          }}
        >
          <Image src={getIcon(chatItem.item)} alt={chatItem.item} width="30px" height="30px" />
          <Flex direction="column" justifyContent="center" width="100%">
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="#0D1A26"
              lineHeight="25px"
              textAlign="left"
              width="100%"
              whiteSpace="pre-wrap"
              style={{ wordWrap: 'break-word', paddingTop: '5px', paddingBottom: '5px'}}
            >
              {chatItem.text}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default ChatListC;