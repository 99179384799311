import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Flex, TextAreaField } from '@aws-amplify/ui-react';
import { Item, ItemEdit } from '../ui-components';

const SpeakerListC = ({
  items,
  audioTime,
  setAudioCurrentTime,
  currentItemRef,
  setModifiedItems,
  setNewSpeakers,
  setLocalChanges,
  setNewLocalSpeakerNames,
  sendFormattedItemsToParent, // New prop to send data up
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editedValues, setEditedValues] = useState(items.map(item => item.text));
  const [temporaryValues, setTemporaryValues] = useState(editedValues);
  const [selectedSpeaker, setSelectedSpeaker] = useState(items.map(item => item.speakerName));
  const [temporarySpeakers, setTemporarySpeakers] = useState(selectedSpeaker);
  const [speakers, setSpeakers] = useState([...new Set(items.map(item => item.speakerName))]);
  const [applyToAll, setApplyToAll] = useState(false);
  const textAreaRefs = useRef([]);
  const [localSpeakerChanges, setLocalSpeakerChanges] = useState({});

  useEffect(() => {
    // Format the items with text, speakerId, and time
    const formattedItems = items.map(item => ({
      text: item.text,
      speaker: item.speaker,
      start: item.start,
      end: item.end,
    }));

    // Send the formatted items to the parent
    sendFormattedItemsToParent(formattedItems);
  }, [items, sendFormattedItemsToParent]);

  useEffect(() => {
    if (editIndex !== null && textAreaRefs.current[editIndex]) {
      textAreaRefs.current[editIndex].style.height = 'auto';
      textAreaRefs.current[editIndex].style.height = textAreaRefs.current[editIndex].scrollHeight + 'px';
    }
  }, [editIndex, temporaryValues]);

  useEffect(() => {
    setEditedValues(items.map(item => item.text));
    setSelectedSpeaker(items.map(item => item.speakerName));
    setTemporaryValues(items.map(item => item.text));
    setTemporarySpeakers(items.map(item => item.speakerName));
    setSpeakers([...new Set(items.map(item => item.speakerName))]);
    setLocalSpeakerChanges({});
  }, [items]);

  const handleEditChange = (index, value) => {
    const newValues = [...temporaryValues];
    newValues[index] = value;
    setTemporaryValues(newValues);
    if (textAreaRefs.current[index]) {
      textAreaRefs.current[index].style.height = 'auto';
      textAreaRefs.current[index].style.height = textAreaRefs.current[index].scrollHeight + 'px';
    }
  };

  const handleSpeakerChange = (index, value) => {
    const newSpeakers = [...temporarySpeakers];
    newSpeakers[index] = value;
    setTemporarySpeakers(newSpeakers);

    if (!applyToAll) {
      setLocalChanges(prev => new Set(prev).add(index));
      setLocalSpeakerChanges(prev => ({ ...prev, [index]: value }));
    }
  };

  const handleSpeakerRename = (index, value) => {
    const newSpeakers = [...temporarySpeakers];
    newSpeakers[index] = value;
    setTemporarySpeakers(newSpeakers);

    const updatedSpeakers = [...new Set(newSpeakers)];
    setSpeakers(updatedSpeakers);

    if (!applyToAll) {
      setLocalChanges(prev => new Set(prev).add(index));
      setLocalSpeakerChanges(prev => ({ ...prev, [index]: value }));
    }
  };

  const handleApplyToAllChange = (checked) => {
    setApplyToAll(checked);

    if (checked && editIndex !== null) {
      const currentSpeaker = selectedSpeaker[editIndex];
      setTemporarySpeakers(temporarySpeakers.map((spk, idx) =>
        spk === currentSpeaker ? temporarySpeakers[editIndex] : spk
      ));

      const updatedLocalChanges = new Set();
      selectedSpeaker.forEach((spk, idx) => {
        if (spk === currentSpeaker) {
          updatedLocalChanges.add(idx);
        }
      });
      setLocalChanges(updatedLocalChanges);
    }
  };

  const handleSave = () => {
    let newSpeakers = [...selectedSpeaker];
    const speakerMap = {};
    let nextSpeakerId = Math.max(...items.map(item => parseInt(item.speaker.split('_')[1]))) + 1;
    const newSpeakerIds = {};
  
    const existingSpeakerMap = {};
    items.forEach(item => {
      existingSpeakerMap[item.speakerName] = item.speaker;
    });
  
    if (applyToAll && editIndex !== null) {
      const oldSpeaker = selectedSpeaker[editIndex];
      const newSpeakerName = temporarySpeakers[editIndex];
      items.forEach((item, idx) => {
        if (item.speakerName === oldSpeaker) {
          speakerMap[item.speaker] = newSpeakerName;
          newSpeakers[idx] = newSpeakerName;
        } else {
          speakerMap[item.speaker] = item.speakerName;
        }
      });
    } else {
      const localChangeMap = {};
      items.forEach((item, idx) => {
        if (localSpeakerChanges[idx]) {
          const newSpeakerName = localSpeakerChanges[idx];
          let newSpeakerId;
  
          if (existingSpeakerMap[newSpeakerName]) {
            newSpeakerId = existingSpeakerMap[newSpeakerName];
          } else if (localChangeMap[newSpeakerName]) {
            newSpeakerId = localChangeMap[newSpeakerName];
          } else {
            newSpeakerId = `SPEAKER_${String(nextSpeakerId).padStart(2, '0')}`;
            localChangeMap[newSpeakerName] = newSpeakerId;
            nextSpeakerId++;
          }
  
          speakerMap[newSpeakerId] = newSpeakerName;
          newSpeakers[idx] = newSpeakerName;
          newSpeakerIds[idx] = newSpeakerId;
        } else {
          speakerMap[item.speaker] = item.speakerName;
          newSpeakers[idx] = item.speakerName;
        }
      });
    }
  
    setSelectedSpeaker(newSpeakers);
    setSpeakers([...new Set(Object.values(speakerMap))]);
    setApplyToAll(false);
    setEditIndex(null);
  
    const finalSpeakers = Object.entries(speakerMap).map(([speakerId, name]) => ({
      speaker_id: speakerId,
      name: name,
    }));
  
    console.log('Final Speakers for API:', finalSpeakers);
  
    const modifiedItems = items.map((item, idx) => {
      let speakerId;
      if (newSpeakerIds[idx]) {
        speakerId = newSpeakerIds[idx];
      } else if (applyToAll && item.speakerName === selectedSpeaker[editIndex]) {
        speakerId = item.speaker;
      } else {
        speakerId = item.speaker;
      }
      return {
        ...item,
        text: temporaryValues[idx] || item.text, // Use temporary values or original text
        speakerName: newSpeakers[idx],
        speaker: speakerId,
      };
    });
  
    setModifiedItems(modifiedItems);
    setNewSpeakers(finalSpeakers);
    setLocalSpeakerChanges({});
    setNewLocalSpeakerNames(newSpeakers);
    return modifiedItems;
  };

  const handleCancelChanges = () => {
    setTemporaryValues(editedValues);
    setTemporarySpeakers(selectedSpeaker);
    setSpeakers([...new Set(items.map(item => item.speakerName))]);
    setApplyToAll(false);
    setEditIndex(null);
    setLocalSpeakerChanges({});
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${h > 0 ? h.toString().padStart(2, '0') + ':' : ''}${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const overrides = (speaker, time, value, index) => ({
    Speaker: {
      children: speaker,
    },
    Time: {
      children: formatTime(time),
    },
    Value: {
      children: value,
    },
    Button: {
      style: {
        opacity: editIndex === null ? 0 : 1,
        transition: 'opacity 0.3s ease-in-out',
        display: editIndex === null ? 'block' : 'none',
      },
      onClick: (e) => {
        e.stopPropagation();
        setEditIndex(index);
        setTemporaryValues(editedValues);
        setTemporarySpeakers(selectedSpeaker);
      },
    },
    Item: {
      onMouseEnter: (e) => {
        if (editIndex === null) {
          e.currentTarget.querySelector('button').style.opacity = 1;
        }
      },
      onMouseLeave: (e) => {
        if (editIndex === null) {
          e.currentTarget.querySelector('button').style.opacity = 0;
        }
      },
      onClick: () => {
        if (editIndex === null) {
          setAudioCurrentTime(time);
        }
      },
      ref: getVariant(index) === 'Current' ? currentItemRef : null,
    },
  });

  const editOverrides = (value, index, speaker) => ({
    SelectField: {
      value: temporarySpeakers[index],
      onChange: (e) => handleSpeakerChange(index, e.target.value),
      children: [...new Set([...speakers, "new"])].filter(speaker => speaker).map((speaker, idx) => (
        <option key={idx} value={speaker === "new" ? "new" : speaker}>
          {speaker === "new" ? "+ Add new speaker" : speaker}
        </option>
      )),
      placeholder: "",
    },
    TextField: {
      value: temporarySpeakers[index],
      onChange: (e) => handleSpeakerRename(index, e.target.value),
    },
    SwitchField: {
      isChecked: applyToAll,
      onChange: (e) => handleApplyToAllChange(e.target.checked),
    },
    Value: {
      children: (
        <TextAreaField
          ref={(el) => (textAreaRefs.current[index] = el)}
          value={value}
          onChange={(e) => handleEditChange(index, e.target.value)}
          width="100%"
          padding="0px"
          style={{
            border: 'none',
            resize: 'none',
            overflow: 'hidden',
            outline: 'none',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(13,26,38,1)',
            lineHeight: '19.363636016845703px',
            textAlign: 'left',
            display: 'block',
            direction: 'column',
            justifyContent: 'unset',
            alignItems: 'unset',
            alignSelf: 'stretch',
            minHeight: '40px',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
          }}
        />
      ),
    },
    Button3878192: {
      onClick: handleSave,
    },
    Button3879212: {
      onClick: handleCancelChanges,
    },
  });

  const getVariant = useCallback((index) => {
    const itemStartTime = items[index].start;
    const itemEndTime = items[index].end;
    if (audioTime >= itemStartTime && audioTime < itemEndTime) {
      return "Current";
    }
    return "Default";
  }, [audioTime, items]);

  return (
    <Flex direction="column" alignItems="center" width="100%" padding="10px" gap="10px">
      {items.map((item, index) => (
        <Flex
          key={index}
          width="100%"
          maxWidth="850px"
          padding="0px"
        >
          {editIndex === index ? (
            <ItemEdit
              overrides={editOverrides(temporaryValues[index], index, temporarySpeakers[index])}
              width="100%"
            />
          ) : (
            <Item
              variant={getVariant(index)}
              overrides={overrides(selectedSpeaker[index], item.start, editedValues[index], index)}
              width="100%"
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default SpeakerListC;