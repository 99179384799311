import React, { useState } from 'react';
import { Grid, View, useBreakpointValue } from '@aws-amplify/ui-react';
import { PdfButton, WordButton, ShareButton, AppleButton, GoogleButton, DownloadButton } from '../ui-components';

const ShareC = ({ focusedMenuItem }) => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);
  const isSingleColumn = useBreakpointValue({ base: true, medium: false });

  const buttons = [
    { Component: PdfButton, name: 'pdf' },
    { Component: AppleButton, name: 'apple' },
    { Component: WordButton, name: 'word' },
    { Component: GoogleButton, name: 'google' },
    { Component: ShareButton, name: 'share' },
    { Component: DownloadButton, name: 'download' },
  ];

  const isButtonEnabled = (name) => {
    switch (focusedMenuItem) {
      case 'summary':
      case 'note':
        return ['pdf', 'word', 'share'].includes(name);
      case 'event':
        return ['apple', 'google'].includes(name);
      case 'mindmap':
        return name === 'download';
      default:
        return false;
    }
  };

  const getVariant = (name) => {
    if (!isButtonEnabled(name)) return 'Disable';
    if (clickedButton === name) return 'Focus';
    if (hoveredButton === name) return 'Hover';
    return 'Default';
  };

  const renderButton = (Component, name) => (
    <View key={name} className="button-wrapper" style={{ height: '100%' }}>
      <Component
        width="100%"
        height="100%"
        variant={getVariant(name)}
        onMouseDown={() => isButtonEnabled(name) && setClickedButton(name)}
        onMouseUp={() => setClickedButton(null)}
        onMouseLeave={() => {
          setClickedButton(null);
          isButtonEnabled(name) && setHoveredButton(null);
        }}
        onMouseEnter={() => isButtonEnabled(name) && setHoveredButton(name)}
        className="share-button"
        overrides={{
          [Component.name]: {
            width: '100%',
            height: '100%',
            cursor: isButtonEnabled(name) ? 'pointer' : 'default',
            style: {
              cursor: isButtonEnabled(name) ? 'pointer' : 'default',
            },
          }
        }}
      />
    </View>
  );

  return (
    <Grid
      templateColumns={isSingleColumn ? "1fr" : "1fr 1fr"}
      gap={isSingleColumn ? "35px" : "100px 25px"}
      width="100%"
      padding="0 20px"
    >
      {buttons.map(({ Component, name }) => renderButton(Component, name))}
    </Grid>
  );
};

export default ShareC;