/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getAudio, listCategories, listTemplates } from "../graphql/queries";
import { updateAudio } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function AudioUpdateForm(props) {
  const {
    id: idProp,
    audio: audioModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    userId: "",
    name: "",
    audioPath: "",
    transcriptionPath: "",
    status: "",
    category: undefined,
    template: undefined,
    automatic: false,
    speakers: "",
    keyPoints: "",
    gptMetaData: "",
  };
  const [userId, setUserId] = React.useState(initialValues.userId);
  const [name, setName] = React.useState(initialValues.name);
  const [audioPath, setAudioPath] = React.useState(initialValues.audioPath);
  const [transcriptionPath, setTranscriptionPath] = React.useState(
    initialValues.transcriptionPath
  );
  const [status, setStatus] = React.useState(initialValues.status);
  const [category, setCategory] = React.useState(initialValues.category);
  const [categoryLoading, setCategoryLoading] = React.useState(false);
  const [categoryRecords, setCategoryRecords] = React.useState([]);
  const [template, setTemplate] = React.useState(initialValues.template);
  const [templateLoading, setTemplateLoading] = React.useState(false);
  const [templateRecords, setTemplateRecords] = React.useState([]);
  const [automatic, setAutomatic] = React.useState(initialValues.automatic);
  const [speakers, setSpeakers] = React.useState(initialValues.speakers);
  const [keyPoints, setKeyPoints] = React.useState(initialValues.keyPoints);
  const [gptMetaData, setGptMetaData] = React.useState(
    initialValues.gptMetaData
  );
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = audioRecord
      ? { ...initialValues, ...audioRecord, category, template }
      : initialValues;
    setUserId(cleanValues.userId);
    setName(cleanValues.name);
    setAudioPath(cleanValues.audioPath);
    setTranscriptionPath(cleanValues.transcriptionPath);
    setStatus(cleanValues.status);
    setCategory(cleanValues.category);
    setCurrentCategoryValue(undefined);
    setCurrentCategoryDisplayValue("");
    setTemplate(cleanValues.template);
    setCurrentTemplateValue(undefined);
    setCurrentTemplateDisplayValue("");
    setAutomatic(cleanValues.automatic);
    setSpeakers(cleanValues.speakers);
    setKeyPoints(cleanValues.keyPoints);
    setGptMetaData(cleanValues.gptMetaData);
    setErrors({});
  };
  const [audioRecord, setAudioRecord] = React.useState(audioModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getAudio.replaceAll("__typename", ""),
              variables: { ...idProp },
            })
          )?.data?.getAudio
        : audioModelProp;
      const categoryRecord = record ? await record.category : undefined;
      setCategory(categoryRecord);
      const templateRecord = record ? await record.template : undefined;
      setTemplate(templateRecord);
      setAudioRecord(record);
    };
    queryData();
  }, [idProp, audioModelProp]);
  React.useEffect(resetStateValues, [audioRecord, category, template]);
  const [currentCategoryDisplayValue, setCurrentCategoryDisplayValue] =
    React.useState("");
  const [currentCategoryValue, setCurrentCategoryValue] =
    React.useState(undefined);
  const categoryRef = React.createRef();
  const [currentTemplateDisplayValue, setCurrentTemplateDisplayValue] =
    React.useState("");
  const [currentTemplateValue, setCurrentTemplateValue] =
    React.useState(undefined);
  const templateRef = React.createRef();
  const getIDValue = {
    category: (r) => JSON.stringify({ userId: r?.userId, id: r?.id }),
    template: (r) => JSON.stringify({ userId: r?.userId, id: r?.id }),
  };
  const categoryIdSet = new Set(
    Array.isArray(category)
      ? category.map((r) => getIDValue.category?.(r))
      : getIDValue.category?.(category)
  );
  const templateIdSet = new Set(
    Array.isArray(template)
      ? template.map((r) => getIDValue.template?.(r))
      : getIDValue.template?.(template)
  );
  const getDisplayValue = {
    category: (r) => `${r?.name ? r?.name + " - " : ""}${r?.userId}-${r?.id}`,
    template: (r) => `${r?.name ? r?.name + " - " : ""}${r?.userId}-${r?.id}`,
  };
  const validations = {
    userId: [{ type: "Required" }],
    name: [],
    audioPath: [],
    transcriptionPath: [],
    status: [],
    category: [],
    template: [],
    automatic: [],
    speakers: [],
    keyPoints: [],
    gptMetaData: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchCategoryRecords = async (value) => {
    setCategoryLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { name: { contains: value } },
            { userId: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listCategories.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCategories?.items;
      var loaded = result.filter(
        (item) => !categoryIdSet.has(getIDValue.category?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCategoryRecords(newOptions.slice(0, autocompleteLength));
    setCategoryLoading(false);
  };
  const fetchTemplateRecords = async (value) => {
    setTemplateLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { name: { contains: value } },
            { userId: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listTemplates.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listTemplates?.items;
      var loaded = result.filter(
        (item) => !templateIdSet.has(getIDValue.template?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setTemplateRecords(newOptions.slice(0, autocompleteLength));
    setTemplateLoading(false);
  };
  React.useEffect(() => {
    fetchCategoryRecords("");
    fetchTemplateRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          userId,
          name: name ?? null,
          audioPath: audioPath ?? null,
          transcriptionPath: transcriptionPath ?? null,
          status: status ?? null,
          category: category ?? null,
          template: template ?? null,
          automatic: automatic ?? null,
          speakers: speakers ?? null,
          keyPoints: keyPoints ?? null,
          gptMetaData: gptMetaData ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            userId: modelFields.userId,
            name: modelFields.name ?? null,
            audioPath: modelFields.audioPath ?? null,
            transcriptionPath: modelFields.transcriptionPath ?? null,
            status: modelFields.status ?? null,
            categoryAudiosUserId: modelFields?.category?.userId ?? null,
            categoryAudiosId: modelFields?.category?.id ?? null,
            templateAudiosUserId: modelFields?.template?.userId ?? null,
            templateAudiosId: modelFields?.template?.id ?? null,
            automatic: modelFields.automatic ?? null,
            speakers: modelFields.speakers ?? null,
            keyPoints: modelFields.keyPoints ?? null,
            gptMetaData: modelFields.gptMetaData ?? null,
          };
          await client.graphql({
            query: updateAudio.replaceAll("__typename", ""),
            variables: {
              input: {
                userId: audioRecord.userId,
                id: audioRecord.id,
                ...modelFieldsToSave,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "AudioUpdateForm")}
      {...rest}
    >
      <TextField
        label="User id"
        isRequired={true}
        isReadOnly={true}
        value={userId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId: value,
              name,
              audioPath,
              transcriptionPath,
              status,
              category,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.userId ?? value;
          }
          if (errors.userId?.hasError) {
            runValidationTasks("userId", value);
          }
          setUserId(value);
        }}
        onBlur={() => runValidationTasks("userId", userId)}
        errorMessage={errors.userId?.errorMessage}
        hasError={errors.userId?.hasError}
        {...getOverrideProps(overrides, "userId")}
      ></TextField>
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              name: value,
              audioPath,
              transcriptionPath,
              status,
              category,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Audio path"
        isRequired={false}
        isReadOnly={false}
        value={audioPath}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath: value,
              transcriptionPath,
              status,
              category,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.audioPath ?? value;
          }
          if (errors.audioPath?.hasError) {
            runValidationTasks("audioPath", value);
          }
          setAudioPath(value);
        }}
        onBlur={() => runValidationTasks("audioPath", audioPath)}
        errorMessage={errors.audioPath?.errorMessage}
        hasError={errors.audioPath?.hasError}
        {...getOverrideProps(overrides, "audioPath")}
      ></TextField>
      <TextField
        label="Transcription path"
        isRequired={false}
        isReadOnly={false}
        value={transcriptionPath}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath: value,
              status,
              category,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.transcriptionPath ?? value;
          }
          if (errors.transcriptionPath?.hasError) {
            runValidationTasks("transcriptionPath", value);
          }
          setTranscriptionPath(value);
        }}
        onBlur={() =>
          runValidationTasks("transcriptionPath", transcriptionPath)
        }
        errorMessage={errors.transcriptionPath?.errorMessage}
        hasError={errors.transcriptionPath?.hasError}
        {...getOverrideProps(overrides, "transcriptionPath")}
      ></TextField>
      <SelectField
        label="Status"
        placeholder="Please select an option"
        isDisabled={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status: value,
              category,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      >
        <option
          children="Pending"
          value="PENDING"
          {...getOverrideProps(overrides, "statusoption0")}
        ></option>
        <option
          children="Review"
          value="REVIEW"
          {...getOverrideProps(overrides, "statusoption1")}
        ></option>
        <option
          children="Success"
          value="SUCCESS"
          {...getOverrideProps(overrides, "statusoption2")}
        ></option>
        <option
          children="Fail"
          value="FAIL"
          {...getOverrideProps(overrides, "statusoption3")}
        ></option>
      </SelectField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status,
              category: value,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          setCategory(value);
          setCurrentCategoryValue(undefined);
          setCurrentCategoryDisplayValue("");
        }}
        currentFieldValue={currentCategoryValue}
        label={"Category"}
        items={category ? [category] : []}
        hasError={errors?.category?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("category", currentCategoryValue)
        }
        errorMessage={errors?.category?.errorMessage}
        getBadgeText={getDisplayValue.category}
        setFieldValue={(model) => {
          setCurrentCategoryDisplayValue(
            model ? getDisplayValue.category(model) : ""
          );
          setCurrentCategoryValue(model);
        }}
        inputFieldRef={categoryRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Category"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Category"
          value={currentCategoryDisplayValue}
          options={categoryRecords
            .filter((r) => !categoryIdSet.has(getIDValue.category?.(r)))
            .map((r) => ({
              id: getIDValue.category?.(r),
              label: getDisplayValue.category?.(r),
            }))}
          isLoading={categoryLoading}
          onSelect={({ id, label }) => {
            setCurrentCategoryValue(
              categoryRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentCategoryDisplayValue(label);
            runValidationTasks("category", label);
          }}
          onClear={() => {
            setCurrentCategoryDisplayValue("");
          }}
          defaultValue={category}
          onChange={(e) => {
            let { value } = e.target;
            fetchCategoryRecords(value);
            if (errors.category?.hasError) {
              runValidationTasks("category", value);
            }
            setCurrentCategoryDisplayValue(value);
            setCurrentCategoryValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("category", currentCategoryDisplayValue)
          }
          errorMessage={errors.category?.errorMessage}
          hasError={errors.category?.hasError}
          ref={categoryRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "category")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status,
              category,
              template: value,
              automatic,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.template ?? value;
          }
          setTemplate(value);
          setCurrentTemplateValue(undefined);
          setCurrentTemplateDisplayValue("");
        }}
        currentFieldValue={currentTemplateValue}
        label={"Template"}
        items={template ? [template] : []}
        hasError={errors?.template?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("template", currentTemplateValue)
        }
        errorMessage={errors?.template?.errorMessage}
        getBadgeText={getDisplayValue.template}
        setFieldValue={(model) => {
          setCurrentTemplateDisplayValue(
            model ? getDisplayValue.template(model) : ""
          );
          setCurrentTemplateValue(model);
        }}
        inputFieldRef={templateRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Template"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Template"
          value={currentTemplateDisplayValue}
          options={templateRecords
            .filter((r) => !templateIdSet.has(getIDValue.template?.(r)))
            .map((r) => ({
              id: getIDValue.template?.(r),
              label: getDisplayValue.template?.(r),
            }))}
          isLoading={templateLoading}
          onSelect={({ id, label }) => {
            setCurrentTemplateValue(
              templateRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentTemplateDisplayValue(label);
            runValidationTasks("template", label);
          }}
          onClear={() => {
            setCurrentTemplateDisplayValue("");
          }}
          defaultValue={template}
          onChange={(e) => {
            let { value } = e.target;
            fetchTemplateRecords(value);
            if (errors.template?.hasError) {
              runValidationTasks("template", value);
            }
            setCurrentTemplateDisplayValue(value);
            setCurrentTemplateValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("template", currentTemplateDisplayValue)
          }
          errorMessage={errors.template?.errorMessage}
          hasError={errors.template?.hasError}
          ref={templateRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "template")}
        ></Autocomplete>
      </ArrayField>
      <SwitchField
        label="Automatic"
        defaultChecked={false}
        isDisabled={false}
        isChecked={automatic}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status,
              category,
              template,
              automatic: value,
              speakers,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.automatic ?? value;
          }
          if (errors.automatic?.hasError) {
            runValidationTasks("automatic", value);
          }
          setAutomatic(value);
        }}
        onBlur={() => runValidationTasks("automatic", automatic)}
        errorMessage={errors.automatic?.errorMessage}
        hasError={errors.automatic?.hasError}
        {...getOverrideProps(overrides, "automatic")}
      ></SwitchField>
      <TextField
        label="Speakers"
        isRequired={false}
        isReadOnly={false}
        value={speakers}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status,
              category,
              template,
              automatic,
              speakers: value,
              keyPoints,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.speakers ?? value;
          }
          if (errors.speakers?.hasError) {
            runValidationTasks("speakers", value);
          }
          setSpeakers(value);
        }}
        onBlur={() => runValidationTasks("speakers", speakers)}
        errorMessage={errors.speakers?.errorMessage}
        hasError={errors.speakers?.hasError}
        {...getOverrideProps(overrides, "speakers")}
      ></TextField>
      <TextField
        label="Key points"
        isRequired={false}
        isReadOnly={false}
        value={keyPoints}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status,
              category,
              template,
              automatic,
              speakers,
              keyPoints: value,
              gptMetaData,
            };
            const result = onChange(modelFields);
            value = result?.keyPoints ?? value;
          }
          if (errors.keyPoints?.hasError) {
            runValidationTasks("keyPoints", value);
          }
          setKeyPoints(value);
        }}
        onBlur={() => runValidationTasks("keyPoints", keyPoints)}
        errorMessage={errors.keyPoints?.errorMessage}
        hasError={errors.keyPoints?.hasError}
        {...getOverrideProps(overrides, "keyPoints")}
      ></TextField>
      <TextField
        label="Gpt meta data"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={gptMetaData}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              userId,
              name,
              audioPath,
              transcriptionPath,
              status,
              category,
              template,
              automatic,
              speakers,
              keyPoints,
              gptMetaData: value,
            };
            const result = onChange(modelFields);
            value = result?.gptMetaData ?? value;
          }
          if (errors.gptMetaData?.hasError) {
            runValidationTasks("gptMetaData", value);
          }
          setGptMetaData(value);
        }}
        onBlur={() => runValidationTasks("gptMetaData", gptMetaData)}
        errorMessage={errors.gptMetaData?.errorMessage}
        hasError={errors.gptMetaData?.hasError}
        {...getOverrideProps(overrides, "gptMetaData")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || audioModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || audioModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
