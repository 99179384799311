/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function Item(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Speaker: {},
        Time: {},
        SpeakerxTime: {},
        Value: {},
        Button: {},
        Item: { backgroundColor: "rgba(199,239,202,1)" },
      },
      variantValues: { variant: "Current" },
    },
    {
      overrides: {
        Speaker: {},
        Time: {},
        SpeakerxTime: {},
        Value: {},
        Button: {},
        Item: {},
      },
      variantValues: { variant: "Default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="852px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(220,222,224,1)"
      borderRadius="4px"
      padding="5px 5px 5px 5px"
      display="flex"
      {...getOverrideProps(overrides, "Item")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "SpeakerxTime")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="16.94318199157715px"
          textAlign="right"
          display="block"
          direction="column"
          justifyContent="unset"
          width="135px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Speaker"
          {...getOverrideProps(overrides, "Speaker")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="200"
          color="rgba(13,26,38,1)"
          lineHeight="16.94318199157715px"
          textAlign="right"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Time"
          {...getOverrideProps(overrides, "Time")}
        ></Text>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(13,26,38,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Value"
        {...getOverrideProps(overrides, "Value")}
      ></Text>
      <Button
        width="unset"
        height="unset"
        shrink="0"
        size="small"
        isDisabled={false}
        variation="link"
        children="modifier"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
