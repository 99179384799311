/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function PdfButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, PDF: {}, PdfButton: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M3.22581 23.3871L5.64516 23.3871L5.64516 25.8064L3.22581 25.8064C1.44657 25.8064 0 24.3599 0 22.5806L0 3.22581C0 1.44657 1.44657 0 3.22581 0L11.5675 0C12.4244 0 13.246 0.337702 13.8508 0.94254L18.4123 5.50403C19.0171 6.10887 19.3548 6.93044 19.3548 7.7873L19.3548 15.3226L16.9355 15.3226L16.9355 8.06452L12.9032 8.06452C12.0111 8.06452 11.2903 7.34375 11.2903 6.45161L11.2903 2.41935L3.22581 2.41935C2.78226 2.41935 2.41935 2.78226 2.41935 3.22581L2.41935 22.5806C2.41935 23.0242 2.78226 23.3871 3.22581 23.3871ZM8.87097 17.7419L10.4839 17.7419C12.0413 17.7419 13.3065 19.0071 13.3065 20.5645C13.3065 22.122 12.0413 23.3871 10.4839 23.3871L9.67742 23.3871L9.67742 25C9.67742 25.4435 9.31452 25.8064 8.87097 25.8064C8.42742 25.8064 8.06452 25.4435 8.06452 25L8.06452 18.5484C8.06452 18.1048 8.42742 17.7419 8.87097 17.7419ZM10.4839 21.7742C11.1542 21.7742 11.6935 21.2349 11.6935 20.5645C11.6935 19.8942 11.1542 19.3548 10.4839 19.3548L9.67742 19.3548L9.67742 21.7742L10.4839 21.7742ZM15.3226 17.7419L16.9355 17.7419C18.2712 17.7419 19.3548 18.8256 19.3548 20.1613L19.3548 23.3871C19.3548 24.7228 18.2712 25.8064 16.9355 25.8064L15.3226 25.8064C14.879 25.8064 14.5161 25.4435 14.5161 25L14.5161 18.5484C14.5161 18.1048 14.879 17.7419 15.3226 17.7419ZM16.9355 24.1935C17.379 24.1935 17.7419 23.8306 17.7419 23.3871L17.7419 20.1613C17.7419 19.7177 17.379 19.3548 16.9355 19.3548L16.129 19.3548L16.129 24.1935L16.9355 24.1935ZM20.9677 18.5484C20.9677 18.1048 21.3306 17.7419 21.7742 17.7419L24.1935 17.7419C24.6371 17.7419 25 18.1048 25 18.5484C25 18.9919 24.6371 19.3548 24.1935 19.3548L22.5806 19.3548L22.5806 20.9677L24.1935 20.9677C24.6371 20.9677 25 21.3306 25 21.7742C25 22.2177 24.6371 22.5806 24.1935 22.5806L22.5806 22.5806L22.5806 25C22.5806 25.4435 22.2177 25.8064 21.7742 25.8064C21.3306 25.8064 20.9677 25.4435 20.9677 25L20.9677 18.5484Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        PDF: { color: "rgba(154,226,161,1)" },
        PdfButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M3.22581 23.3871L5.64516 23.3871L5.64516 25.8064L3.22581 25.8064C1.44657 25.8064 0 24.3599 0 22.5806L0 3.22581C0 1.44657 1.44657 0 3.22581 0L11.5675 0C12.4244 0 13.246 0.337702 13.8508 0.94254L18.4123 5.50403C19.0171 6.10887 19.3548 6.93044 19.3548 7.7873L19.3548 15.3226L16.9355 15.3226L16.9355 8.06452L12.9032 8.06452C12.0111 8.06452 11.2903 7.34375 11.2903 6.45161L11.2903 2.41935L3.22581 2.41935C2.78226 2.41935 2.41935 2.78226 2.41935 3.22581L2.41935 22.5806C2.41935 23.0242 2.78226 23.3871 3.22581 23.3871ZM8.87097 17.7419L10.4839 17.7419C12.0413 17.7419 13.3065 19.0071 13.3065 20.5645C13.3065 22.122 12.0413 23.3871 10.4839 23.3871L9.67742 23.3871L9.67742 25C9.67742 25.4435 9.31452 25.8064 8.87097 25.8064C8.42742 25.8064 8.06452 25.4435 8.06452 25L8.06452 18.5484C8.06452 18.1048 8.42742 17.7419 8.87097 17.7419ZM10.4839 21.7742C11.1542 21.7742 11.6935 21.2349 11.6935 20.5645C11.6935 19.8942 11.1542 19.3548 10.4839 19.3548L9.67742 19.3548L9.67742 21.7742L10.4839 21.7742ZM15.3226 17.7419L16.9355 17.7419C18.2712 17.7419 19.3548 18.8256 19.3548 20.1613L19.3548 23.3871C19.3548 24.7228 18.2712 25.8064 16.9355 25.8064L15.3226 25.8064C14.879 25.8064 14.5161 25.4435 14.5161 25L14.5161 18.5484C14.5161 18.1048 14.879 17.7419 15.3226 17.7419ZM16.9355 24.1935C17.379 24.1935 17.7419 23.8306 17.7419 23.3871L17.7419 20.1613C17.7419 19.7177 17.379 19.3548 16.9355 19.3548L16.129 19.3548L16.129 24.1935L16.9355 24.1935ZM20.9677 18.5484C20.9677 18.1048 21.3306 17.7419 21.7742 17.7419L24.1935 17.7419C24.6371 17.7419 25 18.1048 25 18.5484C25 18.9919 24.6371 19.3548 24.1935 19.3548L22.5806 19.3548L22.5806 20.9677L24.1935 20.9677C24.6371 20.9677 25 21.3306 25 21.7742C25 22.2177 24.6371 22.5806 24.1935 22.5806L22.5806 22.5806L22.5806 25C22.5806 25.4435 22.2177 25.8064 21.7742 25.8064C21.3306 25.8064 20.9677 25.4435 20.9677 25L20.9677 18.5484Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        PDF: { color: "rgba(49,112,61,1)" },
        PdfButton: {},
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M3.22581 23.3871L5.64516 23.3871L5.64516 25.8064L3.22581 25.8064C1.44657 25.8064 0 24.3599 0 22.5806L0 3.22581C0 1.44657 1.44657 0 3.22581 0L11.5675 0C12.4244 0 13.246 0.337702 13.8508 0.94254L18.4123 5.50403C19.0171 6.10887 19.3548 6.93044 19.3548 7.7873L19.3548 15.3226L16.9355 15.3226L16.9355 8.06452L12.9032 8.06452C12.0111 8.06452 11.2903 7.34375 11.2903 6.45161L11.2903 2.41935L3.22581 2.41935C2.78226 2.41935 2.41935 2.78226 2.41935 3.22581L2.41935 22.5806C2.41935 23.0242 2.78226 23.3871 3.22581 23.3871ZM8.87097 17.7419L10.4839 17.7419C12.0413 17.7419 13.3065 19.0071 13.3065 20.5645C13.3065 22.122 12.0413 23.3871 10.4839 23.3871L9.67742 23.3871L9.67742 25C9.67742 25.4435 9.31452 25.8064 8.87097 25.8064C8.42742 25.8064 8.06452 25.4435 8.06452 25L8.06452 18.5484C8.06452 18.1048 8.42742 17.7419 8.87097 17.7419ZM10.4839 21.7742C11.1542 21.7742 11.6935 21.2349 11.6935 20.5645C11.6935 19.8942 11.1542 19.3548 10.4839 19.3548L9.67742 19.3548L9.67742 21.7742L10.4839 21.7742ZM15.3226 17.7419L16.9355 17.7419C18.2712 17.7419 19.3548 18.8256 19.3548 20.1613L19.3548 23.3871C19.3548 24.7228 18.2712 25.8064 16.9355 25.8064L15.3226 25.8064C14.879 25.8064 14.5161 25.4435 14.5161 25L14.5161 18.5484C14.5161 18.1048 14.879 17.7419 15.3226 17.7419ZM16.9355 24.1935C17.379 24.1935 17.7419 23.8306 17.7419 23.3871L17.7419 20.1613C17.7419 19.7177 17.379 19.3548 16.9355 19.3548L16.129 19.3548L16.129 24.1935L16.9355 24.1935ZM20.9677 18.5484C20.9677 18.1048 21.3306 17.7419 21.7742 17.7419L24.1935 17.7419C24.6371 17.7419 25 18.1048 25 18.5484C25 18.9919 24.6371 19.3548 24.1935 19.3548L22.5806 19.3548L22.5806 20.9677L24.1935 20.9677C24.6371 20.9677 25 21.3306 25 21.7742C25 22.2177 24.6371 22.5806 24.1935 22.5806L22.5806 22.5806L22.5806 25C22.5806 25.4435 22.2177 25.8064 21.7742 25.8064C21.3306 25.8064 20.9677 25.4435 20.9677 25L20.9677 18.5484Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        PDF: { color: "rgba(239,240,240,1)" },
        PdfButton: {},
      },
      variantValues: { variant: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="230px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "PdfButton")}
      {...rest}
    >
      <Icon
        width="25px"
        height="25.81px"
        viewBox={{ minX: 0, minY: 0, width: 25, height: 25.80644989013672 }}
        paths={[
          {
            d: "M3.22581 23.3871L5.64516 23.3871L5.64516 25.8064L3.22581 25.8064C1.44657 25.8064 0 24.3599 0 22.5806L0 3.22581C0 1.44657 1.44657 0 3.22581 0L11.5675 0C12.4244 0 13.246 0.337702 13.8508 0.94254L18.4123 5.50403C19.0171 6.10887 19.3548 6.93044 19.3548 7.7873L19.3548 15.3226L16.9355 15.3226L16.9355 8.06452L12.9032 8.06452C12.0111 8.06452 11.2903 7.34375 11.2903 6.45161L11.2903 2.41935L3.22581 2.41935C2.78226 2.41935 2.41935 2.78226 2.41935 3.22581L2.41935 22.5806C2.41935 23.0242 2.78226 23.3871 3.22581 23.3871ZM8.87097 17.7419L10.4839 17.7419C12.0413 17.7419 13.3065 19.0071 13.3065 20.5645C13.3065 22.122 12.0413 23.3871 10.4839 23.3871L9.67742 23.3871L9.67742 25C9.67742 25.4435 9.31452 25.8064 8.87097 25.8064C8.42742 25.8064 8.06452 25.4435 8.06452 25L8.06452 18.5484C8.06452 18.1048 8.42742 17.7419 8.87097 17.7419ZM10.4839 21.7742C11.1542 21.7742 11.6935 21.2349 11.6935 20.5645C11.6935 19.8942 11.1542 19.3548 10.4839 19.3548L9.67742 19.3548L9.67742 21.7742L10.4839 21.7742ZM15.3226 17.7419L16.9355 17.7419C18.2712 17.7419 19.3548 18.8256 19.3548 20.1613L19.3548 23.3871C19.3548 24.7228 18.2712 25.8064 16.9355 25.8064L15.3226 25.8064C14.879 25.8064 14.5161 25.4435 14.5161 25L14.5161 18.5484C14.5161 18.1048 14.879 17.7419 15.3226 17.7419ZM16.9355 24.1935C17.379 24.1935 17.7419 23.8306 17.7419 23.3871L17.7419 20.1613C17.7419 19.7177 17.379 19.3548 16.9355 19.3548L16.129 19.3548L16.129 24.1935L16.9355 24.1935ZM20.9677 18.5484C20.9677 18.1048 21.3306 17.7419 21.7742 17.7419L24.1935 17.7419C24.6371 17.7419 25 18.1048 25 18.5484C25 18.9919 24.6371 19.3548 24.1935 19.3548L22.5806 19.3548L22.5806 20.9677L24.1935 20.9677C24.6371 20.9677 25 21.3306 25 21.7742C25 22.2177 24.6371 22.5806 24.1935 22.5806L22.5806 22.5806L22.5806 25C22.5806 25.4435 22.2177 25.8064 21.7742 25.8064C21.3306 25.8064 20.9677 25.4435 20.9677 25L20.9677 18.5484Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="PDF"
        {...getOverrideProps(overrides, "PDF")}
      ></Text>
    </Flex>
  );
}
