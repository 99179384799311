import React from 'react';
import { AudioContainer } from '../ui-components';
import { Loader } from '@aws-amplify/ui-react';
import AudioPlayerC from './AudioPlayerC';

const AudioContainerC = ({ audio, setAudioTime, audioRef, loading }) => {
  return (
    <AudioContainer
      overrides={{
        AudioContainer: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '80px',
          padding: '10px',
          boxSizing: 'border-box',
        },
      }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <Loader size="large" style={{ transform: 'scale(1.5)' }} />
        </div>
      ) : (
        <AudioPlayerC audio={audio} setAudioTime={setAudioTime} audioRef={audioRef} />
      )}
    </AudioContainer>
  );
};

export default AudioContainerC;