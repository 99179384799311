import React from 'react';
import { SummaryContainer } from '../ui-components';

const DocumentContainerC = ({ focusedMenuItem, content }) => {
  const renderContent = () => {
    const sectionContent = content[focusedMenuItem];
    if (sectionContent && sectionContent.render) {
      return sectionContent.render(sectionContent.data);
    }
    return <div>Select a menu item</div>;
  };

  return (
    <SummaryContainer
      overrides={{
        SummaryContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minWidth: '455px',
          height: '700px',
          padding: '10px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto', padding: '20px' }}>
        {renderContent()}
      </div>
    </SummaryContainer>
  );
};

export default DocumentContainerC;