import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import SpeakerListC from './SpeakerListC';
import { ItemContainer, Confirmation } from '../ui-components';

const ItemContainerC = ({ items, audioTime, setAudioCurrentTime, setNewSpeakers, handleSaveChanges, loading, gptMetaData, sendFormattedItemsToParent }) => {
  const containerRef = useRef(null);
  const currentItemRef = useRef(null);
  const [modifiedItems, setModifiedItems] = useState(items);
  const [newSpeakers, setLocalNewSpeakers] = useState([]);
  const [localChanges, setLocalChanges] = useState(new Set());
  const [newLocalSpeakerNames, setNewLocalSpeakerNames] = useState([]);

  useEffect(() => {
    if (currentItemRef.current) {
      currentItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [audioTime]);

  useEffect(() => {
    setModifiedItems(items);
  }, [items]);

  const handleSave = () => {
    console.log('Handle Save triggered');
    console.log('New Speakers:', newSpeakers);
    console.log('Local Changes:', localChanges);
    console.log('New Local Speaker Names:', newLocalSpeakerNames);
    
    // Log the formatted items being sent to the parent
    const formattedItems = modifiedItems.map(item => ({
      text: item.text,
      speaker: item.speaker,
      start: item.start,
      end: item.end,
    }));

    console.log('Formatted Items to send to parent:', formattedItems);
    
    sendFormattedItemsToParent(formattedItems);
    handleSaveChanges(false, newSpeakers, localChanges, formattedItems); // Pass formattedItems instead of newLocalSpeakerNames
  };

  return (
    <ItemContainer
      overrides={{
        ItemContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '600px',
          padding: '10px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <Loader size="large" style={{ transform: 'scale(2.5)' }} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
          <div style={{ flex: 1, overflowY: 'auto' }} ref={containerRef}>
            <SpeakerListC
              items={modifiedItems}
              audioTime={audioTime}
              setAudioCurrentTime={setAudioCurrentTime}
              currentItemRef={currentItemRef}
              setModifiedItems={setModifiedItems}
              setNewSpeakers={(speakers) => {
                setLocalNewSpeakers(speakers);
                setNewSpeakers(speakers);
              }}
              setLocalChanges={setLocalChanges}
              setNewLocalSpeakerNames={setNewLocalSpeakerNames}
              sendFormattedItemsToParent={sendFormattedItemsToParent} // Pass the function
            />
          </div>
          {gptMetaData === 2 && (
            <div style={{ flexShrink: 0, boxSizing: 'border-box' }}>
              <Confirmation
                overrides={{
                  Confirmation: {
                    width: '100%',
                  },
                  Buttons: {
                    padding: '5px 15px',
                  },
                  Button: {
                    onClick: handleSave,
                  },
                }}
              />
            </div>
          )}
        </div>
      )}
    </ItemContainer>
  );
};

export default ItemContainerC;