import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import TabBarC from '../react-components/TabBarC';
import BackgroundC from '../react-components/BackgroundC';
import SideBarC from '../react-components/SideBarC';
import DocumentContainerC from '../react-components/DocumentContainerC';
import MenusContainerC from '../react-components/MenusContainerC';

const SummaryPage = () => {
  const [focusedMenuItem, setFocusedMenuItem] = useState('summary');

  // Content for each section
  const summaryContent = `
# Summary Title

This is a summary of the content. Here are some key points:

- Point 1
- Point 2
- **Bold Point 3**

## Subsection

1. Numbered item 1
2. Numbered item 2
3. Numbered item 3

*Italic text* and _more italic text_
  `;

  const noteContent = `
  # Assemblée Générale de Renault Group

  ## Ouverture et Introduction
  Jean-Dominique Senard a ouvert l'Assemblée Générale en exprimant sa satisfaction de retrouver les actionnaires pour cet événement annuel important. Il a souligné que cette assemblée est une occasion de dialogue constructif et fécond.
  
  Les personnes présentes à ses côtés incluent :
  
  - M. Luca De Meo, directeur général du groupe
  - M. Thierry Piéton, directeur financier
  - Mme Kytrie Pelleport, directrice juridique
  
  Jean-Dominique Senard a également mentionné la présence des membres du conseil d'administration, du comité exécutif, des commissaires aux comptes, ainsi que de deux représentants d'actionnaires qui agiront en tant que scrutateurs. Il a informé l'assemblée de la présence d'un huissier de justice pour l'événement.
  
  **Informations juridiques** :
  
  - L'avis de convocation a été publié le **31 mars 2023** dans le bulletin des annonces légales obligatoires et dans un journal d'annonces légales.
  - Les actionnaires au nominatif, les porteurs de parts de l'FCPE, ainsi que les commissaires aux comptes ont été convoqués par courrier postal ou électronique.
  
  Jean-Dominique Senard a annoncé la composition du bureau de l'Assemblée :
  
  - **Président du Conseil d'administration** : Jean-Dominique Senard
  - **Scrutateurs** : M. Victor Cauchois (représentant l'État français) et M. Edouard Dubois (représentant la société Amundi)
  - **Secrétaire de l'Assemblée** : Mme Kytrie Pelleport
  
  ## Documents et Validité
  Tous les documents légaux ont été déposés sur le bureau conformément aux dispositions légales et sont disponibles pour les actionnaires depuis la convocation de l'Assemblée. Jean-Dominique Senard a indiqué que ces pièces ont été reconnues régulières par le bureau.
  
  La brochure de convocation, comprenant l'ordre du jour et les textes des résolutions, ainsi que la documentation requise pour l'assemblée, ont été mises à disposition sur le site internet de la société.
  
  Jean-Dominique Senard a confirmé que l'assemblée peut valablement délibérer sur les **résolutions ordinaires et extraordinaires**, car elle réunit **plus du quart des actions composant le capital social ayant le droit de vote**.
  
  La secrétaire de l'Assemblée fournira les chiffres définitifs juste avant le vote des résolutions.
  
  Jean-Dominique Senard a informé que l'Assemblée est **filmée et retransmise sur le site Internet du groupe Renault**.
  
  Il a annoncé qu'il passera la parole à Luca De Meo, qui discutera de la transformation du groupe et de la manière dont Renault s'adapte aux révolutions de l'industrie.
  
  ## Résultats et Contexte Économique
  Jean-Dominique Senard a exprimé sa satisfaction concernant les **résultats exceptionnels de 2022**, obtenus malgré un contexte de crise prolongée (semi-conducteurs, approvisionnement, tensions géopolitiques, inflation, crise de l'énergie, guerre en Ukraine). Il a remercié Luca De Meo et les équipes du groupe pour leurs réalisations, ainsi que les actionnaires pour leur soutien constant.
  
  Le versement d'un **dividende modeste** a été souligné comme un signe tangible de la conquête de l'entreprise, permettant d'envisager l'avenir avec sérénité et volontarisme. Les réalisations de l'entreprise sont remarquables tant sur le plan quantitatif que qualitatif. Thierry Piéton rappellera les chiffres traduisant l'ampleur de la performance de l'entreprise.
  
  Jean-Dominique Senard a mis en avant l'aspect qualitatif des résultats, soulignant l'agilité, la résilience et la solidarité de Renault Group face aux défis. L'entreprise s'est appuyée sur son socle de raison d'être, incarnant l'innovation à tous les niveaux de l'organisation. La raison d'être de Renault affirme un engagement envers un **progrès responsable et respectueux**, ainsi qu'une volonté de rendre la mobilité plus équitable.
  
  L'idée de créer les conditions d'une **transition juste** a été discutée lors de quatre séances du comité de la raison d'être, avec des recommandations présentées au conseil d'administration. Ces efforts ont permis de travailler dans un environnement serein malgré les difficultés du contexte actuel. Une illustration de cette confiance est la réussite du **plan d'actionnariat salarié**, témoignant de la confiance des équipes dans la stratégie de l'entreprise.
  
  Jean-Dominique Senard abordera plus en détail la gouvernance de l'entreprise au cours de l'Assemblée. Il a affirmé que les réalisations de l'entreprise n'auraient pas été possibles sans la confiance mutuelle entre le Conseil d'administration et le Management. Il a également indiqué qu'il avait demandé à Luca De Meo de rejoindre le **Conseil d'administration**, une décision qui sera soumise au vote des actionnaires.
  
  ## Alliance Renault-Nissan-Mitsubishi
  Jean-Dominique Senard a évoqué l'alliance entre Renault, Nissan et Mitsubishi, soulignant que beaucoup doutaient de la capacité de Renault à redynamiser cette collaboration après des années de crise. Il a rappelé que les décisions prises en janvier de l'année précédente avaient déjà montré la volonté de Renault d'**accélérer le partage des ressources**, notamment en matière de **plateformes communes, d'électrification, de batteries et d'architecture électronique**.
  
  L'accord conclu le 6 février entre Renault, Nissan et Mitsubishi vise à **maximiser la création de valeur pour toutes les parties prenantes de l'Alliance**. Renault Group s'engage dans des projets opérationnels dans plusieurs régions, notamment en **Amérique latine, en Inde et en Europe**, en développant des **pick-up, de nouveaux SUV, des véhicules électriques et des initiatives d'économies circulaires**.
  
  L'accord permet une **plus grande agilité**, permettant aux partenaires de s'engager dans de nouvelles initiatives qui renforceront les plans stratégiques des entreprises, tels que **Renault-Lucion et Nissan Ambition 2030**. Les domaines de collaboration offrent la possibilité à Nissan ou Mitsubishi d'**investir dans Ampère**, l'entité dédiée aux véhicules électriques et logiciels de Renault Group en Europe, et de devenir également des clients de **Horse** dans le domaine des technologies et des motorisations thermiques et hybrides à faible émission.
  
  L'accord repose sur un **rééquilibrage des participations croisées et sur une gouvernance renforcée**, ce qui donne à l'Alliance un nouvel élan et lui permet d'entamer un nouveau départ prometteur. Jean-Dominique Senard a exprimé sa satisfaction quant aux réalisations concrètes de l'Alliance, affirmant que le groupe est non seulement redressé, mais qu'il se prépare également pour l'avenir avec une vision proactive.
  
  ## Environnement Économique et Stratégies
  Jean-Dominique Senard a évoqué un environnement nouveau marqué par le début de la déglobalisation, remettant en question les filières d'approvisionnement et les chaînes logistiques, tout en soulignant le basculement économique vers l'Asie. Il a noté la montée en puissance des pays asiatiques dans la chaîne de valeur de la mobilité électrique et les tensions croissantes entre les grandes puissances militaro-industrielles, engendrant davantage d'incertitudes.
  
  Renault Group doit faire face à des défis multiples : **environnementaux, démographiques, d'urbanisation, technologiques et éthiques** liés à l'utilisation des nouvelles technologies, comme l'intelligence artificielle. La question centrale posée est de savoir comment Renault Group peut affronter l'avenir en étant encore plus fort, réactif et agile.
  
  Jean-Dominique Senard a annoncé que Renault Group a décidé d'**évoluer son organisation en adoptant une approche innovante**, permettant ainsi de développer une méthode inédite. Luca De Meo rappellera la philosophie de cette évolution vers un groupe plus ouvert, articulé autour de différents écosystèmes et s'appuyant sur divers partenaires pour répondre aux nouveaux défis commerciaux et aux nouvelles chaînes de valeur.
  
  Lors de cette Assemblée générale, Jean-Dominique Senard a souligné qu'il s'agit de la cinquième qu'il préside, marquant ainsi **quatre années de changements radicaux** dans le monde. Il a fait remarquer les différences entre le monde de 2018 et celui de 2023, en tenant compte de la crise sanitaire, des problèmes d'approvisionnement, de l'inflation des matières premières et d'énergie, ainsi que de la guerre en Ukraine.
  
  En prenant la présidence de Renault en 2019, Jean-Dominique Senard a dû faire face à un contexte difficile pour l'entreprise, où la priorité était de prendre des mesures d'urgence pour éviter l'implosion et la faillite de Renault Group et de l'Alliance. En 2020, l'accent a été mis sur la résistance et la reconstruction des fondamentaux, notamment à travers le plan stratégique **Renolution** élaboré par Luca De Meo et ses équipes. L'année 2021 a été marquée par le redressement de l'entreprise, tandis que 2022 a été à la fois un aboutissement et un point de départ vers une nouvelle génération automobile chez Renault et une alliance plus concrète et offensive.
  
  Pendant ces quatre années, les piliers du groupe ont été patiemment construits, incluant :
  
  - **Stratégie axée sur la valeur des produits et des marques**
  - **Renforcement de la production en France**
  - **Vision d'innovation à 360 degrés**
  - **Politique de responsabilité sociétale des entreprises (RSE)** intégrant performance sociale, environnementale et économique
  
  Jean-Dominique Senard a également souligné la mise en place d'**écosystèmes agiles**, la préparation des compétences de demain, et la définition d'une nouvelle organisation, précisant que toutes les décisions prises reposent désormais sur ces piliers.
  
  Certaines réalisations de Renault Group sont symboliques, illustrant les progrès réalisés par l'entreprise :
  
  - Accord avec **Valléo** pour développer et produire en France un moteur électrique de nouvelle génération sans utiliser de terres rares.
  - Annonce en avril dernier que le partenaire **Vercors** réserverait la majeure partie de ses batteries électriques produites dans sa future usine de Dunkerque, témoignant de l'engagement de l'entreprise envers la création de valeur en France dans le cadre de son écosystème électrique.
  
  ## Écosystème Électrique et Innovations
  Jean-Dominique Senard a précisé que l'écosystème électrique de Renault implique de nombreux partenaires, y compris des entreprises de premier plan, des start-up, ainsi que des acteurs régionaux et territoriaux, permettant ainsi de répondre aux défis environnementaux et de promouvoir la mobilité durable. Il a informé que le 15 décembre dernier, le Conseil d'administration a proposé le renouvellement de son mandat.
  
  Luca De Meo a remercié les actionnaires pour leur confiance et leur soutien durant les périodes difficiles que le groupe a traversées. Il a noté que le paysage de Renault Group a beaucoup changé depuis l'an dernier, affirmant qu'ils ont définitivement tourné la page sur une crise profonde. En 2020, Renault Group perdait des dizaines de millions chaque semaine, totalisant une perte de **8 milliards d'euros** pour l'année.
  
  Le plan Rénolution se compose de trois phases : la résurrection, la rénovation et la révolution. La première phase, la résurrection, est désormais achevée. Renault Group a atteint un taux de profitabilité de **5,6 %**, l'un des meilleurs résultats des 20 dernières années. En deux ans, Renault Group a généré plus de **2 milliards d'euros de cash**, un montant supérieur à celui des dix années précédentes.
  
  **Résultats obtenus** :
  
  - Environnement difficile marqué par une inflation impactant le groupe de **3 milliards d'euros** l'année précédente.
  - Difficultés sur les chaînes logistiques globales.
  - Crise en Russie, où Renault était très exposé.
  
  Malgré les prévisions pessimistes, Renault a réussi à compenser le choc causé par la crise en seulement six mois. En 2022, Renault a dégagé la plus haute marge par véhicule depuis dix ans, avec une augmentation de **40 %** par rapport à 2020. Le taux d'utilisation des usines a presque atteint **100 %** au premier semestre 2023, bien que Renault ait dû réduire sa capacité de production de **1,2 million d'unités** depuis 2019. Le point mort a été divisé par deux, une première pour Renault.
  
  La deuxième phase du plan Rénolution, intitulée "la rénovation", est centrée sur le produit et est bien lancée. Renault prépare la meilleure gamme de produits que le groupe ait eue depuis 30 ans, avec **18 lancements prévus entre 2023 et 2025**. Des modèles comme la Mégane électrique et l'Austral ont déjà donné un aperçu des nouveautés, mais le meilleur reste à venir. Des modèles emblématiques tels que la Renault 4, la Renault 5 et le Scénic sont attendus, ainsi que le nouveau Jogger, le nouvel Espace et la nouvelle Clio.
  
  Renault a exprimé son ambition de devenir une entreprise automobile de prochaine génération, avec une idée simple en tête : se concentrer sur de nouvelles chaînes de valeur telles que l'électrique, le software, les nouvelles mobilités et l'économie circulaire. Renault a créé quatre équipes compactes et agiles dédiées aux nouveaux domaines de la mobilité : **Ampère, Alpine, Mobilize et The Future is Neutral**. Renault n'oublie pas son métier traditionnel, en mettant en avant **Ors**, qui sera un leader dans la fourniture de groupes moteurs thermiques.
  
  **Objectifs et stratégies** :
  
  - Maintenir une structure de groupe légère et simplifiée pour orienter stratégiquement, soutenir et coordonner les différents secteurs d'activité.
  - Adopter une approche horizontale et écosystémique pour centrer le système sur l'agilité et la capacité d'innovation.
  - S'associer avec les meilleurs acteurs pour trouver les bonnes technologies, en adoptant une approche de co-développement et de co-création.
  
  Renault a conclu plus de **20 partenariats** depuis 2020 et vise à retrouver un leadership dans plusieurs secteurs. Luca De Meo a annoncé qu'Aramco a rejoint l'aventure d'Ors, renforçant la crédibilité du projet. Les entités légales nécessaires ont été créées dans chacun des sept pays où Ors sera présent, et les opérations devraient démarrer dans les mois à venir.
  
  Concernant **Ampère**, le champion de Renault dans l'électrique et le software, l'équipe en charge du projet a été nommée et ils explorent la possibilité d'associer d'autres investisseurs stratégiques en plus de Qualcomm. Une possible ouverture du capital est envisagée et un Capital Market Day est prévu dans les prochains mois.
  
  Du côté de **Mobilize**, Renault avance vers un renforcement de ses activités dans le secteur du leasing et souhaite également étendre sa couverture de la chaîne de valeur en développant des offres de services autour de l'énergie. Pour **The Future is Neutral**, l'ambition est de couvrir l'ensemble de la chaîne de valeur de l'économie circulaire.
  
  ## Performances Financières et Initiatives
  Renault génère actuellement **840 millions d'euros de chiffre d'affaires** à travers les initiatives Indra, Gaïa et Buncomé Or. La prochaine étape consiste à investir dans le **recyclage des batteries**, avec un partenariat déjà en préparation.
  
  **Projet Alpine** :
  
  - Développement de la nouvelle gamme de produits en cours.
  - Révélation de l'A290 Beta et annonce de deux nouveaux modèles dans les deux prochaines années.
  - Objectif : **six modèles et une gamme 100% électrique d'ici 2027**.
  - Préparations pour entrer sur le marché américain et potentiellement en Chine.
  
  **Digitalisation** :
  
  - Création d'un **jumeau digital** de toute l'entreprise.
  - Développement de **six plateformes digitales** à travers des partenariats stratégiques.
  - Collaboration avec Dassault Systèmes pour numériser le développement produit via la plateforme 3D Experience, générant des gains de **200 millions d'euros par an à partir de 2026**.
  - Utilisation de SAP Forana pour la finance, avec un gain de productivité attendu de **200 millions d'euros par an**.
  - Partenariat avec Google pour optimiser l'industrie et la logistique end-to-end, visant à réduire de **30% la consommation énergétique des usines d'ici 2025**.
  
  **Développement durable** :
  
  - Réduction de **25% de l'empreinte globale entre 2010 et 2022**.
  - Objectif de **neutralité carbone en Europe d'ici 2040** et à l'échelle mondiale d'ici 2050.
  - Initiatives comme "The Future is Neutral" et Ampère pour augmenter le taux de matière recyclée et atteindre une industrie neutre en carbone d'ici 2025.
  - Mobilize se concentre sur l'innovation concernant l'utilisation des batteries en deuxième cycle.
  
  **Inclusion et formation** :
  
  - Renault University développe un programme de transformation des compétences pour s'adapter aux métiers de la nouvelle chaîne de valeur de la mobilité.
  - Objectif de former **20 000 personnes, dont 15 000 salariés du groupe, entre 2021 et 2025** dans des domaines tels que la cybersécurité, le software, l'électrification des véhicules et l'économie circulaire.
  
  **Sécurité** :
  
  - Méganitech inclut un régulateur de vitesse adaptif intelligent, une sécurité dédiée aux batteries des véhicules électriques, et le Rescue Code développé en partenariat avec les pompiers de 17 pays.
  - Le modèle Austral intègre un assistant éco-conduite prédictif, et la future Scénic sera équipée du concept Safety Score.
  
  **Plan climat** :
  
  - Objectif de **neutralité carbone pour les usines en Europe**.
  - 100% des nouveaux véhicules particuliers électrifiés d'ici 2030 en Europe.
  - Déploiement de technologies hybrides, hydrogène ou GPL sur l'ensemble des marques.
  - Ambition de devenir le leader européen de la mobilité hydrogène pour les véhicules utilitaires légers.
  - Initiatives pour prolonger la durée de vie des véhicules et augmenter leur taux d'utilisation grâce à la mobilité partagée, le reconditionnement, les rétrofits et les futures technologies embarquées.
  
  **Engagement des employés** :
  
  - 40 000 employés ont acheté des actions lors du plan d'actionnaires à salariés lancé en novembre.
  - Confiance dans le management augmentée de près de **30% depuis 2020**.
  
  Renault s'engage à être le constructeur le plus responsable en matière de mobilité future, intégrant des innovations qui prennent en compte l'environnement, les clients et les employés.
  
  ## Relations avec les Actionnaires
  Luca De Meo a exprimé sa gratitude envers les actionnaires pour leur confiance et a sollicité leur soutien pour rejoindre le Conseil d'administration. Il a annoncé que le retour du dividende est le fruit du travail collectif de l'équipe de Renault.
  
  Thierry Piéton, directeur financier de Renault, a commenté les résultats financiers de 2022 et l'évolution des ventes en 2023. Il a souligné que 2022 a été une année difficile pour le secteur automobile, affecté par l'inflation, la crise des semi-conducteurs et des problèmes logistiques. Renault a dû gérer la cession de ses activités en Russie, entraînant une perte non cash de **2,3 milliards d'euros**.
  
  **Résultats financiers de 2022** :
  
  - Renault a vendu **2,1 millions de véhicules**, soit une baisse de 5,9 % par rapport à 2021.
  - Les ventes du segment C ont progressé de 23 % en Europe, atteignant plus de **420 000 unités**.
  - La marque Renault a vendu **1,4 million de véhicules**, enregistrant un repli de 9,4 %.
  - Dacia a vendu plus de **570 000 véhicules**, une progression de près de 7 %.
  - Alpine a atteint un niveau record de ventes avec plus de **3 500 véhicules**, une croissance de 33 %.
  - Le chiffre d'affaires du groupe a augmenté de **11,4 %**, atteignant **46,4 milliards d'euros**.
  - La marge opérationnelle du groupe a plus que doublé, atteignant **2,6 milliards d'euros** (5,6 % du chiffre d'affaires).
  - Le free cash flow opérationnel de l'automobile a atteint **2,1 milliards d'euros**, le niveau le plus élevé en 17 ans.
  - La position financière nette de l'automobile est redevenue positive, atteignant **549 millions d'euros**.
  
  **Perspectives pour 2023** :
  
  - Le groupe a matriculé **535 000 véhicules** au premier trimestre, une augmentation de plus de 14 % par rapport à 2022.
  - En Europe, les ventes de Renault ont progressé de 27 %.
  - Le chiffre d'affaires du premier trimestre 2023 a augmenté de près de 30 %, atteignant **11,5 milliards d'euros**.
  - Le carnet de commandes reste à des niveaux records, représentant **3,3 mois de vente**.
  - Les perspectives financières pour 2023 incluent une marge opérationnelle supérieure ou égale à **6 %** et un free cash flow opérationnel de l'automobile supérieur ou égal à **2 milliards d'euros**.
  
  Thierry Piéton a également annoncé l'intention de Renault de rétablir le versement d'un dividende en 2023, avec une proposition de **25 centimes par action** pour l'exercice 2022. La politique de dividende vise à atteindre un taux de distribution de 35 % du résultat net du groupe.
  
  ## Présentation des Rapports
  Thierry Piéton a invité M. Loïc Wallard, de la société Mazar, à présenter un résumé des rapports du Collège des commissaires aux comptes.
  
  Loïc Wallard a annoncé qu'il a émis **cinq rapports pour l'exercice 2022** :
  
  - **Comptes annuels**
  - **Comptes consolidés**
  - **Rémunération des titres participatifs**
  - **Conventions réglementées**
  - **Opération relative au capital social**
  
  **Comptes annuels** :
  - Certification sans réserve des comptes annuels de l'exercice 2022.
  - Évaluation des titres de participation comme point d'attention.
  
  **Comptes consolidés** :
  - Certification sans réserve des comptes consolidés 2022 du groupe Renault.
  - Quatre points clés de l'audit :
    1. Valeur recouvrable des actifs du secteur automobile.
    2. Méthode de comptabilisation et valeur recouvrable de l'investissement de Renault dans Nissan.
    3. Calcul des pertes attendues sur les créances de financement des ventes.
    4. Traitement en activité abandonnée des sessions du groupe Renault en fédération de Russie.
  
  Pour chaque point, les méthodes comptables appliquées ont été revues et le caractère raisonnable des estimations effectuées par Renault a été vérifié.
  
  **Rapports de l'Assemblée Générale Ordinaire** :
  - **Titres participatifs** : Attestation de la conformité des éléments de calcul de la rémunération variable avec les termes du contrat d'émission.
  - **Conventions réglementées** : Description des caractéristiques et modalités essentielles des conventions. Aucune convention autorisée par le Conseil d'administration n'a été soumise à l'approbation de l'Assemblée Générale au cours de l'exercice écoulé. Les conventions déjà approuvées et poursuivies en 2022 sont mentionnées dans le rapport.
  
  **Rapport spécial pour l'Assemblée Générale Extraordinaire** :
  - Concernant une résolution susceptible d'affecter le capital social, confirmant que cette opération respecte les conditions prévues par le Code de commerce.
  
  Loïc Wallard a conclu en affirmant que son rapport ne contenait pas de remarques ou d'observations particulières à porter à l'attention de l'Assemblée.
  
  ## Gouvernance et Confiance
  Jean-Dominique Senard a souligné l'importance de la gouvernance pour établir la confiance, essentielle pour une performance durable. Il a mis en avant la collaboration entre le Conseil d'administration et le management, permettant au groupe d'avancer rapidement. Il a salué la présence de Blucademeo et l'apport de Lucas au sein du Conseil.
  
  Pierre Fleuriau, administrateur référent et président du Comité de la gouvernance et des rémunérations, a présenté le bilan des travaux du Conseil d'administration et les politiques de rémunération des mandataires sociaux. 
  
  **Activité du Conseil d'administration** :
  
  - 12 réunions du Conseil en 2022 (contre 9 en 2021)
  - 17 réunions de comités
  - Taux d'assiduité de plus de 95%
  - Cession des activités en Russie, approuvée le 11 mai 2022
  - Discussions avec Nissan et Mitsubishi, aboutissant à un nouvel accord signé le 6 février 2023
  - Organisation d'un séminaire stratégique avec visites d'usines
  
  **Composition du Conseil d'administration** :
  
  - 16 membres : 4 représentants des salariés, 2 représentants de l'État, 2 représentants de l'ISAN, 6 administrateurs indépendants, 1 président du conseil indépendant, et Lucas de Méo en tant que directeur général
  - Taux d'indépendance : 58%
  - Taux de féminisation : 42%
  - Renouvellements : Jean-Dominique Senard et Annette Winclair
  - Entrée : Luca de Meo
  - Départ : Frédéric Mazella
  
  **Rémunération des mandataires sociaux** :
  
  - Président du Conseil d'administration : 450 000 € pour 2022 et 2023, sans rémunération variable
  - Directeur général (Lucas de Méo) : 
    - Rémunération fixe de 1,3 million d'euros
    - Rémunération variable pouvant atteindre 150% de la rémunération fixe
    - 75 000 actions de performance attribuées
    - Plan de co-investissement permettant d'investir jusqu'à 25% de la rémunération fixe et variable
    - Ajout de 30% au plan d'action de performance pour 2023, conditionné par la réalisation du plan de transformation
  
  **Actionnariat salarié** :
  
  - Distribution gratuite de 6 actions par salarié
  - Programme permettant d'acheter des actions Renault avec une décote de 30%, avec un abondement de trois actions supplémentaires pour les deux premières actions achetées
  - Augmentation de l'actionnariat salarié de 3,8% à 4,7%
  - Objectif d'atteindre 10% d'actionnariat salarié d'ici 2030
  - Reconduction du programme d'actionnariat salarié pour 2023
  
  Pierre Fleuriau a confirmé que les critères de performance et leur pondération restent inchangés par rapport aux années précédentes. La rémunération totale versée aux administrateurs pour 2022 s'élève à 1 133 750 €, inférieure au budget alloué de 1,5 million d'euros.
  
  ## Échanges avec les Actionnaires
  Jean-Dominique Senard a remercié Pierre Fleuriau pour sa présentation et a annoncé le début des échanges avec les actionnaires. Les réponses aux questions écrites sont disponibles sur le site internet de l'entreprise.
  
  Kytrie Pelleport a donné des consignes pour le bon déroulement des échanges, demandant des questions directes et courtes. Des hôtes et hôtesses viendront avec un micro pour les participants.
  
  Jean-Dominique Senard a rappelé l'importance du comité consultatif des actionnaires et de la journée des actionnaires. Il a proposé de visionner des images de la journée des actionnaires.
  
  Luca De Meo a exprimé sa satisfaction de voir l'engagement des actionnaires. Il a déclaré que la transformation de Renault en entreprise automobile de nouvelle génération va changer la donne.
  
  **Points clés abordés** :
  
  - **Transformation de Renault** : Nouvelle feuille de route, nouveaux produits, nouvelles technologies.
  - **Engagement des collaborateurs** : Collaborateurs très engagés et compétents.
  - **Stratégie de Renault** : Devenir un leader dans la mobilité électrique, 100% électrique d'ici 2030.
  
  Jean-Dominique Senard a introduit une question sur les nouvelles synergies avec Nissan et Mitsubishi. L'accord de février a donné un nouvel élan opérationnel à l'Alliance, avec des projets industriels majeurs à l'échelle mondiale.
  
  **Synergies envisagées** :
  
  - **Projets Ampère et Ors** : Nissan et Mitsubishi pourraient rejoindre le projet Ampère.
  - **Création de valeur** : Projets concrets pouvant générer des centaines de millions, voire des milliards d'euros par an.
  
  Carlos Tavares, un actionnaire individuel, a exprimé son mécontentement concernant sa situation financière avec Renault et la rémunération des dirigeants. Jean-Dominique Senard a défendu la rémunération du directeur général, soulignant qu'elle est conditionnée à des performances ambitieuses sur trois ans.
  
  Un intervenant de l'APAI a posé des questions sur les stratégies pour attirer et retenir les talents. Luca De Meo a souligné l'importance de l'approche ESG et de l'éthique dans la stratégie de Renault.
  
  **Attractivité de Renault** :
  
  - **Approche ESG** : Environnementale, sociale et de gouvernance.
  - **Éthique** : Intégrée dans l'ADN de Renault.
  
  Thierry Piéton, le directeur financier, a confirmé que Renault cherche à attirer des investisseurs anglo-saxons ayant une stratégie à long terme. Il a mentionné que Renault communique régulièrement avec les investisseurs à travers des événements et des rencontres.
  
  **Investisseurs anglo-saxons** :
  
  - **Performance** : Attentes à court et long terme.
  - **Clarté sur la stratégie** : Capital Market Day organisé en novembre.
  
  Un représentant de l'ANAF a posé des questions sur l'interdiction de motorisation thermique pour 2035 et l'arrivée de véhicules électriques chinois. Luca De Meo a souligné que Renault doit s'adapter aux nouvelles règles et se concentrer sur l'électrique.
  
  **Défis et opportunités** :
  
  - **Échéance 2035** : Accent sur l'électrique pour décarboner la flotte.
  - **Concurrence chinoise** : Renault doit produire des voitures plus compétitives.
  
  Luca De Meo a mentionné que Renault bénéficie d'une expérience de dix ans dans la production de voitures électriques, ce qui lui permet de récupérer les batteries des modèles plus anciens pour le recyclage.
  
  **Recyclage des batteries** :
  
  - **Gigafactories en France** : Douai avec Envision AEC et Dunkerque avec Vercors.
  - **Sources d'approvisionnement** : Relation historique avec LG.
  
  Jean-Dominique Senard a souligné l'importance de l'analyse d'impact avant de prendre des décisions en Europe. Luca De Meo a ajouté que Renault doit produire des voitures plus compétitives pour rivaliser avec les technologies chinoises.
  
  ## Gestion des Questions et Réponses
  Jean-Dominique Senard a indiqué qu'il restait encore un peu de temps pour répondre aux questions, en encourageant des questions courtes pour des réponses concises.
  
  Michel Koudra, un actionnaire individuel, a exprimé sa confusion concernant la gestion des actions attribuées aux salariés ou aux directeurs généraux. Thierry Piéton a précisé que **Renault n'émet pas de nouvelles actions** pour éviter de diluer les autres actionnaires et opte plutôt pour le **rachat d'actions sur le marché**. Chaque année, lors de l'Assemblée Générale, une autorisation est demandée pour procéder à ces rachats, avec une délégation donnée au conseil d'administration.
  
  Jean-Dominique Senard a mentionné une question en ligne concernant l'impact de l'IPO d'Ampère, prévue pour fin 2023 ou début 2024, sur les actionnaires de Renault. Thierry Piéton a expliqué que l'objectif avec Ampère est de **créer une franchise électrique performante** et de maximiser les chances de succès en termes de ressources et de capital. Renault vise à renforcer le bilan du groupe et à améliorer sa santé financière, tout en ayant la possibilité de faire appel au marché pour injecter des ressources supplémentaires dans le développement de l'activité Ampère. Cette stratégie permettrait aux actionnaires de Renault de voir l'IPO potentielle d'Ampère comme un levier pour améliorer la performance d'Ampère et, par conséquent, celle du groupe.
  
  Vincent Engrain, un actionnaire et salarié, a exprimé des préoccupations concernant l'impact de l'IPO d'Ampère sur les ressources et les usines de Renault. Thierry Piéton a affirmé que **Renault conservera une forte majorité** même si l'IPO a lieu, et que l'activité d'Ampère continuera à être consolidée dans les comptes du groupe Renault. L'IPO d'Ampère doit être perçue comme une opportunité pour cette entité d'accéder à des capitaux externes, permettant un développement plus rapide.
  
  Luca De Meo a abordé le thème d'Ampère sous un angle stratégique, en soulignant l'importance de l'électrique et du logiciel. Il a mentionné que des sites comme Douai seront intégrés dans Ampère et que l'ancienne usine Ruiz sera réorientée pour fabriquer des boîtes destinées aux batteries. Un accord a été conclu avec Valeo pour transformer l'ancienne usine Mécléon en la plus grande usine de moteurs électriques en Europe. Renault a réussi à attirer deux entreprises pour établir deux gigafactories dans le nord de la France, ce qui pourrait générer des **investissements induits de 20 milliards d'euros**.
  
  Jean-Dominique Senard a indiqué que le temps des questions touchait à sa fin et a encouragé ceux qui n'ont pas pu poser de questions à le faire par écrit, en précisant que l'équipe de Renault se fera un plaisir d'y répondre.
  
  ## Changements Organisationnels et Partenariats
  Jean-Pierre Dufour a posé des questions sur les conséquences des changements d'organisation proposés sur la valeur des actions, la nature de l'augmentation de capital et la situation de l'entité Horst.
  
  Thierry Piéton a confirmé que les activités liées aux moteurs thermiques et aux boîtes de vitesse seront transférées à Ors, une co-entreprise avec Djili et Aramco, dans laquelle Renault ne sera plus majoritaire. Cette co-entreprise sera déconsolidée, ce qui signifie que les comptes de Horst ne seront plus intégrés dans ceux de Renault.
  
  **Objectifs de la nouvelle structure** :
  
  - Créer un équipementier avec une masse critique importante.
  - Fournir Renault et sept autres entreprises.
  - Réaliser des synergies en matière d'achats et de développement.
  - Réduire les coûts de production des moteurs.
  
  Thierry Piéton a souligné que cette stratégie est bénéfique pour les actionnaires de Renault, car elle contribuera à diminuer la structure de coût tout en maintenant une participation significative dans une société qui devrait générer des bénéfices et du cash à long terme. Renault reste actionnaire d'une société avec le potentiel de distribuer des dividendes à l'avenir.
  
  **Partenariat avec Aramco** :
  
  - Apporte un soutien financier et technologique.
  - Essentiel pour le développement de l'activité.
  - Garantir la production de véhicules thermiques les plus propres et efficaces.
  
  Luca De Meo a mentionné qu'il existe un pacte d'actionnariat qui protège considérablement la position de Renault, stipulant qu'aucune décision stratégique ne peut être prise sans l'accord de Renault.
  
  Thierry Piéton a indiqué que la participation de Renault dans Ors est équilibrée, avec une valorisation des actifs apportés par Geely et Renault quasiment égale, favorisant un partenariat à 50-50 entre les deux entreprises, à l'exception d'Aramco qui injecte des fonds séparément.
  
  Jean-Dominique Senard a conclu en affirmant que cette nouvelle activité devrait révéler une valeur significative par rapport à la situation actuelle et a exprimé sa confiance que les actionnaires de Renault seront satisfaits des résultats dans les mois à venir.
  
  ## Informations Générales et Résolutions
  Kytri de Pelleport a informé les actionnaires que l'Assemblée allait délibérer sur un total de **17 résolutions**, dont **16 de nature ordinaire** et **une extraordinaire**. Le total des titres détenus par les actionnaires présents ou représentés s'élève à **159 719 428 actions**, représentant **64,47 % des actions ayant le droit de vote**, atteignant ainsi le quorum requis pour la validité des délibérations.
  
  Conformément à l'accord de gouvernance du 4 février 2016, les votes de l'État sont plafonnés à **17,9 % des droits de vote** pour toutes les résolutions, sauf pour la troisième résolution. Les droits de vote de l'État français seront exercés de manière neutre au-delà de ce seuil.
  
  Un boîtier de vote a été remis aux actionnaires, avec des instructions claires sur son utilisation :
  
  - **Touche verte** : vote pour
  - **Touche jaune** : abstention
  - **Touche rouge** : vote contre
  
  Les résultats du vote seront affichés sur l'écran de projection peu après la clôture du scrutin. Les participants ont été priés d'éteindre leurs téléphones portables pendant le vote et de restituer les boîtiers à la sortie de la salle.
  
  **Résolutions adoptées** :
  
  1. **Approbation des comptes annuels de l'exercice 2022** : Bénéfice de **363 637 277,74 €** (Renault SA uniquement).
  2. **Approbation des comptes consolidés de l'exercice 2022** : Perte de **699 655 850,46 €**.
  3. **Affectation du résultat de l'exercice 2022 et fixation du dividende** : Dividende de **0,25 € par action**, totalisant **72 602 830,75 €**.
  4. Prise acte du rapport des commissaires aux comptes concernant la rémunération des titres participatifs.
  5. Prise acte des informations relatives aux conventions et engagements conclus et autorisés au cours d'exercices antérieurs.
  6. **Renouvellement du mandat d'administrateur de M. Jean-Dominique Sénard** pour 4 ans.
  7. **Renouvellement du mandat d'administratrice de Mme Annette Winclair** pour 4 ans.
  8. **Nomination de M. Luca De Meo en qualité d'administrateur** pour 4 ans.
  9. Approbation des informations relatives aux rémunérations versées ou attribuées aux mandataires sociaux pour l'exercice 2022.
  10. Éléments de rémunération versés ou attribués à M. Jean-Dominique Sénard pour l'exercice 2022 (résultats non mentionnés).
  11. Éléments de rémunération versés ou attribués à M. Luca De Meo pour l'exercice 2022.
  12. Approbation de la politique de rémunération du président du conseil d'administration pour l'exercice 2023.
  13. Approbation de la politique de rémunération du directeur général pour l'exercice 2023.
  14. Approbation de la politique de rémunération des administrateurs pour l'exercice 2023.
  15. Autorisation au Conseil d'administration d'acheter et vendre des actions de la société pour une durée maximale de 18 mois et dans la limite de 10 % du capital.
  16. Autorisation au Conseil d'administration de réduire le capital de la société par annulation d'actions pour une durée maximale de 18 mois et dans la limite de 10 % du capital.
  17. Pouvoirs nécessaires pour accomplir les formalités légales requises à l'issue de cette assemblée.
  
  Kytri de Pelleport a confirmé que la résolution numéro 8, concernant la nomination de Luca De Meo en qualité d'administrateur, a été adoptée malgré un problème d'affichage. Les résultats détaillés des votes seront publiés sur les sites internes de la société.
  
  Jean-Dominique Sénard a remercié les participants pour leurs votes et la confiance accordée en renouvelant son mandat. Il a exprimé son engagement à œuvrer pour que le groupe Renault achève son impressionnante conquête dans l'industrie de la mobilité, soulignant l'importance du redressement réalisé par les équipes.
  
  L'assemblée a été déclarée terminée après ces remerciements.
  `;

  const eventContent = `
Event: Team Meeting
Date: 2023-06-15
Time: 14:00

Participants:
- Alice
- Bob
- Charlie

Agenda:
1. Project updates
2. Budget review
3. Q&A session
  `;

  const mindmapContent = `
Project X
├── Planning
│   ├── Timeline
│   ├── Resources
│   └── Goals
├── Execution
│   ├── Phase 1
│   ├── Phase 2
│   └── Phase 3
└── Review
    ├── Feedback
    ├── Metrics
    └── Lessons Learned
  `;

  // Custom styles for markdown content
  const markdownStyles = {
    padding: '0 25px'
  };

  // Rendering logic for each section
  const renderMarkdown = (content) => (
    <div style={markdownStyles}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
  const renderText = (content) => <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{content}</pre>;

  const content = {
    summary: { data: summaryContent, render: renderMarkdown },
    note: { data: noteContent, render: renderMarkdown },
    event: { data: eventContent, render: renderText },
    mindmap: { data: mindmapContent, render: renderText }
  };

  return (
    <div className="SummaryPage" style={{ width: '100vw', height: '100vh', overflowY: 'auto', position: 'relative' }}>
      <BackgroundC>
        <div style={{ position: 'relative', zIndex: 1002 }}>
          <SideBarC />
        </div>
        <div style={{ marginLeft: '90px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 80px)', minHeight: '100%' }}>
          <div style={{ display: 'flex', width: '100%', maxWidth: '1440px', flex: '1 0 auto', justifyContent: 'space-between', padding: '10px', boxSizing: 'border-box' }}>
            <div style={{ flex: '1 1 66%', paddingRight: '10px', overflow: 'visible' }}>
              <div style={{ marginBottom: '15px' }}>
                <TabBarC />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'visible' }}>
                <DocumentContainerC 
                  focusedMenuItem={focusedMenuItem} 
                  content={content}
                />
              </div>
            </div>
            <div style={{ flex: '1 1 27%', paddingTop: '25px', paddingLeft: '10px', paddingRight: '20px', overflow: 'visible' }}>
              <MenusContainerC 
                focusedMenuItem={focusedMenuItem}
                setFocusedMenuItem={setFocusedMenuItem}
              />
            </div>
          </div>
        </div>
      </BackgroundC>
    </div>
  );
}

export default SummaryPage;