import React, { useState } from 'react';
import { View, Loader } from '@aws-amplify/ui-react';
import { UploadContainer } from '../ui-components';
import StorageInfoC from './StorageInfoC';
import UploadC from './UploadC';
import RecC from './RecC';

const UploadContainerC = ({ totalStorage, usedStorage, categories, maxCategory, templates, onUpload, loading, automaticOption }) => {
  const [recordedAudio, setRecordedAudio] = useState(null);

  const handleAudioRecorded = (audioFile) => {
    setRecordedAudio(audioFile);
  };

  return (
    <UploadContainer
      overrides={{
        UploadContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '480px',
          height: '744px',
          padding: '20px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <View style={{ flex: 1, overflowY: 'auto', paddingTop: '5px', position: 'relative' }}>
        {/* StorageInfoC with loader */}
        <div style={{ position: 'relative' }}>
          <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
            <StorageInfoC totalStorage={totalStorage} usedStorage={usedStorage} />
          </div>
          {loading && (
            <div style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10, // Ensure the loader is above the hidden component
            }}>
              <Loader size="large" style={{ transform: 'scale(1.2)' }} />
            </div>
          )}
        </div>

        <RecC onAudioRecorded={handleAudioRecorded} />
        <UploadC 
          categories={categories} 
          maxCategory={maxCategory} 
          templates={templates} 
          onUpload={onUpload} 
          recordedAudio={recordedAudio}
          automaticOption={automaticOption} // Pass the automaticOption prop to UploadC
        />
      </View>
    </UploadContainer>
  );
};

export default UploadContainerC;