import React, { useState, useRef, useEffect } from 'react';
import { Button, Flex, TextField, Heading, useTheme, View, Alert, Text } from '@aws-amplify/ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KeyPointListC from './KeyPointListC';

const BlockListC = ({ blocks, onBlocksChange, newBlockAdded }) => {
  const { tokens } = useTheme();
  const [blockList, setBlockList] = useState(blocks);
  const [isEditing, setIsEditing] = useState(null);
  const [editedHeading, setEditedHeading] = useState('');
  const [editedKeyPoints, setEditedKeyPoints] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const blockRefs = useRef([]);

  const editButtonRef = useRef(null);
  const deleteButtonRef = useRef(null);
  const saveButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const addKeyPointButtonRef = useRef(null);

  useEffect(() => {
    if (addKeyPointButtonRef.current) {
      addKeyPointButtonRef.current.blur();
    }
    if (cancelButtonRef.current) {
      cancelButtonRef.current.blur();
    }
    if (saveButtonRef.current) {
      saveButtonRef.current.blur();
    }
  }, [isEditing]);

  useEffect(() => {
    setBlockList(blocks);
  }, [blocks]);

  useEffect(() => {
    if (newBlockAdded && blockRefs.current.length > 0) {
      blockRefs.current[blockRefs.current.length - 1]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [blockList, newBlockAdded]);

  const handleEdit = (index) => {
    setIsEditing(index);
    setEditedHeading(blockList[index].heading);
    setEditedKeyPoints([...blockList[index].keyPoints]);
    if (editButtonRef.current) editButtonRef.current.blur();
  };

  const handleSave = (index) => {
    if (!editedHeading.trim()) {
      setErrorMessage('Le titre ne peut pas être vide.');
      return;
    }

    if (editedKeyPoints.length === 0) {
      setErrorMessage('Au moins un point clé est requis.');
      return;
    }

    if (editedKeyPoints.some(keyPoint => !keyPoint.trim())) {
      setErrorMessage('Un point clé ne peut pas être vide.');
      return;
    }

    const updatedBlocks = [...blockList];
    updatedBlocks[index].heading = editedHeading;
    updatedBlocks[index].keyPoints = editedKeyPoints;
    setBlockList(updatedBlocks);
    onBlocksChange(updatedBlocks);
    setIsEditing(null);
    if (saveButtonRef.current) saveButtonRef.current.blur();
  };

  const handleCancel = () => {
    setIsEditing(null);
    if (cancelButtonRef.current) cancelButtonRef.current.blur();
  };

  const handleDelete = (index) => {
    const updatedBlocks = blockList.filter((_, i) => i !== index);
    setBlockList(updatedBlocks);
    onBlocksChange(updatedBlocks);
    if (deleteButtonRef.current) deleteButtonRef.current.blur();
  };

  const handleKeyPointChange = (kpIndex, value) => {
    const updatedKeyPoints = [...editedKeyPoints];
    updatedKeyPoints[kpIndex] = value;
    setEditedKeyPoints(updatedKeyPoints);
  };

  const handleAddKeyPoint = () => {
    if (editedKeyPoints.length >= 20) {
      setErrorMessage('Vous ne pouvez pas ajouter plus de 20 points clés.');
      return;
    }

    setEditedKeyPoints([...editedKeyPoints, '']);
    if (addKeyPointButtonRef.current) addKeyPointButtonRef.current.blur();
  };

  const handleDeleteKeyPoint = (kpIndex) => {
    const updatedKeyPoints = editedKeyPoints.filter((_, i) => i !== kpIndex);
    setEditedKeyPoints(updatedKeyPoints);
  };

  const dismissError = () => {
    setErrorMessage('');
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedList = Array.from(blockList);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setBlockList(reorderedList);
    onBlocksChange(reorderedList);
  };

  return (
    <>
      {errorMessage && (
        <View
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1002',
          }}
        >
          <Alert
            variation="error"
            isDismissible={false}
            hasIcon={true}
            heading="Erreur"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              maxWidth: '400px',
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              border: '2px solid black',
            }}
          >
            <Text style={{ paddingTop: '20px' }}>{errorMessage}</Text>
            <Flex justifyContent="flex-end" gap="10px" marginTop="20px">
              <Button size="small" variation="warning" onClick={dismissError}>
                OK
              </Button>
            </Flex>
          </Alert>
        </View>
      )}
      <Flex direction='column' gap='20px' width='100%' maxWidth="550px" margin="0 auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="blockList">
            {(provided) => (
              <Flex
                direction='column'
                gap='20px'
                width='100%'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {blockList.map((block, index) => (
                  <Draggable key={index} draggableId={String(index)} index={index} isDragDisabled={isEditing !== null}>
                    {(provided, snapshot) => (
                      <Flex
                        direction='column'
                        width={snapshot.isDragging ? provided.draggableProps.style.width : '100%'}
                        ref={(el) => {
                          provided.innerRef(el);
                          blockRefs.current[index] = el;
                        }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        maxWidth="550px"
                        margin="0 auto"
                        className={`block-container ${hoveredIndex === index ? 'hovered' : ''}`}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{ ...provided.draggableProps.style, width: '100%' }}
                      >
                        <Flex
                          direction='column'
                          gap='10px'
                          width='100%'
                          padding="20px 40px"
                          border={`1px solid ${tokens.colors.brand.primary[20]}`}
                          borderRadius="5px"
                          boxShadow="0 4px 8px rgba(0, 0, 0, 0.3)"
                          backgroundColor="white"
                          position="relative"
                        >
                          {isEditing === index ? (
                            <>
                              <TextField
                                placeholder='Titre'
                                value={editedHeading}
                                onChange={(e) => setEditedHeading(e.target.value)}
                                labelHidden
                                width="100%"
                              />
                              {editedKeyPoints.map((keyPoint, kpIndex) => (
                                <Flex key={kpIndex} direction="row" alignItems="center" gap="10px" width="100%">
                                  <TextField
                                    placeholder={'Contenu'}
                                    value={keyPoint}
                                    onChange={(e) => handleKeyPointChange(kpIndex, e.target.value)}
                                    labelHidden
                                    width="100%"
                                  />
                                  <Button
                                    onClick={() => handleDeleteKeyPoint(kpIndex)}
                                    variation="warning"
                                  >
                                    -
                                  </Button>
                                </Flex>
                              ))}
                              <Flex justifyContent="center">
                                <Button ref={addKeyPointButtonRef} onClick={handleAddKeyPoint} variation="link">
                                  + Ajouter un point clé
                                </Button>
                              </Flex>
                              <Flex justifyContent="flex-end" gap="10px" className="always-visible-buttons">
                                <Button ref={cancelButtonRef} onClick={handleCancel} variation="warning">Annuler</Button>
                                <Button ref={saveButtonRef} onClick={() => handleSave(index)} variation="primary">Enregistrer</Button>
                              </Flex>
                            </>
                          ) : (
                            <>
                              <Heading level={6} textAlign="center">{block.heading}</Heading>
                              <KeyPointListC keyPoints={block.keyPoints} />
                              <Flex justifyContent="flex-end" gap="10px" className="edit-delete-buttons">
                                <Button ref={editButtonRef} onClick={() => handleEdit(index)}>Modifier</Button>
                                <Button ref={deleteButtonRef} onClick={() => handleDelete(index)} variation="destructive">Supprimer</Button>
                              </Flex>
                            </>
                          )}
                        </Flex>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
        <style jsx>{`
          .edit-delete-buttons {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 0.3s ease, max-height 0.3s ease;
          }
          .block-container.hovered .edit-delete-buttons {
            opacity: 1;
            max-height: 40px;
          }

          /* Safari fix for border rendering issue */
          .block-container {
            will-change: transform;
          }

          /* Prevent the creation of multiple borders in Safari */
          @media not all and (min-resolution:.001dpcm) { 
            @supports (-webkit-appearance:none) {
              .block-container {
                border: 1px solid transparent;
              }
            }
          }
        `}</style>
      </Flex>
    </>
  );
};

export default BlockListC;