import React, { useState, useEffect } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { StorageInfo } from '../ui-components';

const StorageInfoC = ({ totalStorage, usedStorage }) => {
  const [percentageUsed, setPercentageUsed] = useState(0);

  useEffect(() => {
    if (totalStorage > 0) {
      setPercentageUsed((usedStorage / totalStorage) * 100);
    }
  }, [totalStorage, usedStorage]);

  const formattedPercentage = `${Math.min(Math.max(percentageUsed, 0), 100).toFixed(2)}%`;

  const overrides = {
    Pourcent: {
      overrides: {
        PourcentA41062204: {
          children: `${usedStorage} GB`,
        },
        PourcentA41062205: {
          children: `${totalStorage} GB`,
        },
      },
    },
    StorageBar: {
      width: '100%',
      height: '10px',
      position: 'relative',
      overflow: 'hidden',
      overrides: {
        Bar: {
          width: `${formattedPercentage}`,
          height: '100%',
        },
      },
    },
  };

  return (
    <Flex direction="column" alignItems="flex-start" width="100%" minWidth="110px" boxSizing="border-box">
      <StorageInfo overrides={overrides} width="100%" />
    </Flex>
  );
};

export default StorageInfoC;