import React, { useEffect, useRef, useState } from 'react';
import ChatListC from './ChatListC';
import SendC from './SendC';
import { ChatContainer } from '../ui-components';

const ChatContainerC = ({ chatItems }) => {
  const sendContainerRef = useRef(null);
  const chatListRef = useRef(null);
  const [sendContainerHeight, setSendContainerHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (sendContainerRef.current) {
        setSendContainerHeight(sendContainerRef.current.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    const sendContainerElement = sendContainerRef.current;
    if (sendContainerElement) {
      resizeObserver.observe(sendContainerElement);
      handleResize(); // Initialize the height on component mount
    }

    return () => {
      if (sendContainerElement) {
        resizeObserver.unobserve(sendContainerElement);
      }
    };
  }, []);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTo({
        top: chatListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [chatItems, sendContainerHeight]);

  const reversedChatItems = [...chatItems].reverse();

  return (
    <ChatContainer
      overrides={{
        ChatContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minWidth: '455px',
          height: '700px',
          padding: '0px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
          backgroundColor: 'rgba(255,255,255,1)',
        },
      }}
    >
      <div
        ref={chatListRef}
        style={{
          flex: 1,
          overflowY: 'auto',
          paddingTop: '30px',
          paddingBottom: '35px',
          paddingLeft: '30px',
          paddingRight: '30px',
          marginBottom: `${sendContainerHeight}px`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ChatListC chatItems={reversedChatItems} />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          boxSizing: 'border-box',
          padding: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div
          ref={sendContainerRef}
          style={{
            width: '100%',
            paddingRight: '30px',
            paddingLeft: '30px',
            paddingTop: '20px',
            paddingBottom: '20px',
            backgroundColor: 'rgba(255,255,255,1)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            border: '1px solid black',
            borderRadius: '5px',
            margin: '20px',
          }}
        >
          <SendC chatItems={chatItems} />
        </div>
      </div>
    </ChatContainer>
  );
};

export default ChatContainerC;