/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateAudioByUserId = /* GraphQL */ `
  subscription OnUpdateAudioByUserId($userId: ID!) {
    onUpdateAudioByUserId(userId: $userId) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const onCreateAudioByUserId = /* GraphQL */ `
  subscription OnCreateAudioByUserId($userId: ID!) {
    onCreateAudioByUserId(userId: $userId) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const onDeleteAudioByUserId = /* GraphQL */ `
  subscription OnDeleteAudioByUserId($userId: ID!) {
    onDeleteAudioByUserId(userId: $userId) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onCreateUser(filter: $filter, id: $id) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onUpdateUser(filter: $filter, id: $id) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onDeleteUser(filter: $filter, id: $id) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAudio = /* GraphQL */ `
  subscription OnCreateAudio(
    $filter: ModelSubscriptionAudioFilterInput
    $PlusPlan: String
  ) {
    onCreateAudio(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const onUpdateAudio = /* GraphQL */ `
  subscription OnUpdateAudio(
    $filter: ModelSubscriptionAudioFilterInput
    $PlusPlan: String
  ) {
    onUpdateAudio(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const onDeleteAudio = /* GraphQL */ `
  subscription OnDeleteAudio(
    $filter: ModelSubscriptionAudioFilterInput
    $PlusPlan: String
  ) {
    onDeleteAudio(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory(
    $filter: ModelSubscriptionCategoryFilterInput
    $PlusPlan: String
  ) {
    onCreateCategory(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory(
    $filter: ModelSubscriptionCategoryFilterInput
    $PlusPlan: String
  ) {
    onUpdateCategory(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory(
    $filter: ModelSubscriptionCategoryFilterInput
    $PlusPlan: String
  ) {
    onDeleteCategory(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const onCreateTemplate = /* GraphQL */ `
  subscription OnCreateTemplate(
    $filter: ModelSubscriptionTemplateFilterInput
    $PlusPlan: String
  ) {
    onCreateTemplate(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const onUpdateTemplate = /* GraphQL */ `
  subscription OnUpdateTemplate(
    $filter: ModelSubscriptionTemplateFilterInput
    $PlusPlan: String
  ) {
    onUpdateTemplate(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const onDeleteTemplate = /* GraphQL */ `
  subscription OnDeleteTemplate(
    $filter: ModelSubscriptionTemplateFilterInput
    $PlusPlan: String
  ) {
    onDeleteTemplate(filter: $filter, PlusPlan: $PlusPlan) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const onCreateQuota = /* GraphQL */ `
  subscription OnCreateQuota(
    $filter: ModelSubscriptionQuotaFilterInput
    $userId: String
  ) {
    onCreateQuota(filter: $filter, userId: $userId) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateQuota = /* GraphQL */ `
  subscription OnUpdateQuota(
    $filter: ModelSubscriptionQuotaFilterInput
    $userId: String
  ) {
    onUpdateQuota(filter: $filter, userId: $userId) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteQuota = /* GraphQL */ `
  subscription OnDeleteQuota(
    $filter: ModelSubscriptionQuotaFilterInput
    $userId: String
  ) {
    onDeleteQuota(filter: $filter, userId: $userId) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
