/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function KeyPointItem(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Square: {},
        Line: {},
        SquarexLine: {},
        KeyPoint: {},
        Text: {},
        KeyPointItem: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Square: {},
        Line: { backgroundColor: "rgba(0,0,0,0)" },
        SquarexLine: {},
        KeyPoint: {},
        Text: {},
        KeyPointItem: {},
      },
      variantValues: { variant: "last" },
    },
    {
      overrides: {
        Square: { backgroundColor: "rgba(154,226,161,1)" },
        Line: {},
        SquarexLine: {},
        KeyPoint: { color: "rgba(48,64,80,1)" },
        Text: {},
        KeyPointItem: {},
      },
      variantValues: { variant: "DefaultHover" },
    },
    {
      overrides: {
        Square: { backgroundColor: "rgba(49,112,61,1)" },
        Line: {},
        SquarexLine: {},
        KeyPoint: { fontWeight: "700" },
        Text: {},
        KeyPointItem: {},
      },
      variantValues: { variant: "DefaultFocus" },
    },
    {
      overrides: {
        Square: { backgroundColor: "rgba(154,226,161,1)" },
        Line: { backgroundColor: "rgba(0,0,0,0)" },
        SquarexLine: {},
        KeyPoint: { color: "rgba(48,64,80,1)" },
        Text: {},
        KeyPointItem: {},
      },
      variantValues: { variant: "lastHover" },
    },
    {
      overrides: {
        Square: { backgroundColor: "rgba(49,112,61,1)" },
        Line: { backgroundColor: "rgba(0,0,0,0)" },
        SquarexLine: {},
        KeyPoint: { fontWeight: "700" },
        Text: {},
        KeyPointItem: {},
      },
      variantValues: { variant: "lastFocus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="460px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "KeyPointItem")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "SquarexLine")}
      >
        <View
          width="20px"
          height="20px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(76,203,104,1)"
          {...getOverrideProps(overrides, "Square")}
        ></View>
        <View
          width="2px"
          height="unset"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(76,203,104,1)"
          {...getOverrideProps(overrides, "Line")}
        ></View>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 33px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Text")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="16.94318199157715px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="KeyPoint"
          {...getOverrideProps(overrides, "KeyPoint")}
        ></Text>
      </Flex>
    </Flex>
  );
}
