/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function AudioItem(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Rectangle 1": {},
        Audio: {},
        Audios: {},
        Category: {},
        Template: {},
        Date: {},
        label: {},
        Badge: {},
        Vector: {},
        icon: {},
        AudioItem: {},
      },
      variantValues: { variant: "Success" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(239,143,143,1)" },
        Audio: {},
        Audios: {},
        Category: {},
        Template: {},
        Date: {},
        label: { color: "rgba(102,0,0,1)", children: "Erreur" },
        Badge: { backgroundColor: "rgba(252,233,233,1)" },
        Vector: {},
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(149,4,4,1)" },
      },
      variantValues: { variant: "Error" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(239,143,143,1)" },
        Audio: {},
        Audios: {},
        Category: {},
        Template: {},
        Date: {},
        label: { color: "rgba(102,0,0,1)", children: "Erreur" },
        Badge: { backgroundColor: "rgba(252,233,233,1)" },
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(149,4,4,1)" },
      },
      variantValues: { variant: "ErrorDelHover" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(239,143,143,1)" },
        Audio: {},
        Audios: {},
        Category: {},
        Template: {},
        Date: {},
        label: { color: "rgba(102,0,0,1)", children: "Erreur" },
        Badge: { backgroundColor: "rgba(252,233,233,1)" },
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(77,0,0,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(149,4,4,1)" },
      },
      variantValues: { variant: "ErrorDelFocus" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(199,239,202,1)" },
        Audio: { color: "rgba(137,148,159,1)" },
        Audios: {},
        Category: { color: "rgba(137,148,159,1)" },
        Template: { color: "rgba(137,148,159,1)" },
        Date: { color: "rgba(137,148,159,1)" },
        label: { color: "rgba(13,26,38,1)", children: "En cours" },
        Badge: { backgroundColor: "rgba(239,240,240,1)" },
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(239,240,240,1)" },
      },
      variantValues: { variant: "Pending" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(49,112,61,1)" },
        Audio: { fontWeight: "700" },
        Audios: {},
        Category: { fontWeight: "600" },
        Template: { fontWeight: "600" },
        Date: { fontWeight: "500" },
        label: {},
        Badge: {},
        Vector: {},
        icon: {},
        AudioItem: {},
      },
      variantValues: { variant: "SuccessFocus" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(154,226,161,1)" },
        Audio: { color: "rgba(48,64,80,1)" },
        Audios: {},
        Category: { color: "rgba(48,64,80,1)" },
        Template: { color: "rgba(48,64,80,1)" },
        Date: { color: "rgba(48,64,80,1)" },
        label: {},
        Badge: {},
        Vector: {},
        icon: {},
        AudioItem: {},
      },
      variantValues: { variant: "SuccessHover" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(154,226,161,1)" },
        Audio: { color: "rgba(48,64,80,1)" },
        Audios: {},
        Category: { color: "rgba(48,64,80,1)" },
        Template: { color: "rgba(48,64,80,1)" },
        Date: { color: "rgba(48,64,80,1)" },
        label: {},
        Badge: {},
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: {},
      },
      variantValues: { variant: "SuccessDelHover" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(154,226,161,1)" },
        Audio: { color: "rgba(48,64,80,1)" },
        Audios: {},
        Category: { color: "rgba(48,64,80,1)" },
        Template: { color: "rgba(48,64,80,1)" },
        Date: { color: "rgba(48,64,80,1)" },
        label: {},
        Badge: {},
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(77,0,0,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: {},
      },
      variantValues: { variant: "SuccessDelFocus" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(125,161,232,1)" },
        Audio: {},
        Audios: {},
        Category: {},
        Template: {},
        Date: {},
        label: { color: "rgba(0,34,102,1)", children: "Relecture" },
        Badge: { backgroundColor: "rgba(230,238,254,1)" },
        Vector: {},
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(184,206,249,1)" },
      },
      variantValues: { variant: "Review" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(184,206,249,1)" },
        Audio: { color: "rgba(48,64,80,1)" },
        Audios: {},
        Category: { color: "rgba(48,64,80,1)" },
        Template: { color: "rgba(48,64,80,1)" },
        Date: { color: "rgba(48,64,80,1)" },
        label: { color: "rgba(0,34,102,1)", children: "Relecture" },
        Badge: { backgroundColor: "rgba(230,238,254,1)" },
        Vector: {},
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(184,206,249,1)" },
      },
      variantValues: { variant: "ReviewHover" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(4,52,149,1)" },
        Audio: { fontWeight: "700" },
        Audios: {},
        Category: { fontWeight: "600" },
        Template: { fontWeight: "600" },
        Date: { fontWeight: "500" },
        label: { color: "rgba(0,34,102,1)", children: "Relecture" },
        Badge: { backgroundColor: "rgba(230,238,254,1)" },
        Vector: {},
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(184,206,249,1)" },
      },
      variantValues: { variant: "ReviewFocus" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(184,206,249,1)" },
        Audio: { color: "rgba(48,64,80,1)" },
        Audios: {},
        Category: { color: "rgba(48,64,80,1)" },
        Template: { color: "rgba(48,64,80,1)" },
        Date: { color: "rgba(48,64,80,1)" },
        label: { color: "rgba(0,34,102,1)", children: "Relecture" },
        Badge: { backgroundColor: "rgba(230,238,254,1)" },
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(184,206,249,1)" },
      },
      variantValues: { variant: "ReviewDelHover" },
    },
    {
      overrides: {
        "Rectangle 1": { backgroundColor: "rgba(184,206,249,1)" },
        Audio: { color: "rgba(48,64,80,1)" },
        Audios: {},
        Category: { color: "rgba(48,64,80,1)" },
        Template: { color: "rgba(48,64,80,1)" },
        Date: { color: "rgba(48,64,80,1)" },
        label: { color: "rgba(0,34,102,1)", children: "Relecture" },
        Badge: { backgroundColor: "rgba(230,238,254,1)" },
        Vector: {
          paths: [
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(77,0,0,1)",
              fillRule: "nonzero",
            },
          ],
        },
        icon: {},
        AudioItem: { border: "0.5px SOLID rgba(184,206,249,1)" },
      },
      variantValues: { variant: "ReviewDelFocus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="801px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      border="0.5px SOLID rgba(154,226,161,1)"
      borderRadius="5.5px"
      padding="5px 20px 5px 20px"
      display="flex"
      {...getOverrideProps(overrides, "AudioItem")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Audios")}
      >
        <View
          width="20px"
          height="20px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(76,203,104,1)"
          {...getOverrideProps(overrides, "Rectangle 1")}
        ></View>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="14.522727012634277px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Audio"
          {...getOverrideProps(overrides, "Audio")}
        ></Text>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="12px"
        fontWeight="500"
        color="rgba(13,26,38,1)"
        lineHeight="14.522727012634277px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Category"
        {...getOverrideProps(overrides, "Category")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="12px"
        fontWeight="500"
        color="rgba(13,26,38,1)"
        lineHeight="14.522727012634277px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Template"
        {...getOverrideProps(overrides, "Template")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="12px"
        fontWeight="400"
        color="rgba(13,26,38,1)"
        lineHeight="14.522727012634277px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Date"
        {...getOverrideProps(overrides, "Date")}
      ></Text>
      <Flex
        gap="10px"
        direction="row"
        width="65px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="32px"
        padding="6px 8px 6px 8px"
        backgroundColor="rgba(199,239,202,1)"
        display="flex"
        {...getOverrideProps(overrides, "Badge")}
      >
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="600"
          color="rgba(34,66,38,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Terminé"
          {...getOverrideProps(overrides, "label")}
        ></Text>
      </Flex>
      <View
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "icon")}
      >
        <Icon
          width="14px"
          height="18px"
          viewBox={{ minX: 0, minY: 0, width: 14, height: 18 }}
          paths={[
            {
              d: "M1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4L1 4L1 16ZM14 1L10.5 1L9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1Z",
              fill: "rgba(137,148,159,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="12.5%"
          bottom="12.5%"
          left="20.83%"
          right="20.83%"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </View>
    </Flex>
  );
}
