/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function TabBarItem(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Item: { fontWeight: "600" },
        led: { backgroundColor: "rgba(76,203,104,1)" },
        frame: {},
        TabBarItem: {},
      },
      variantValues: { variant: "Active" },
    },
    {
      overrides: { Item: {}, led: {}, frame: {}, TabBarItem: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Item: { color: "rgba(239,240,240,1)" },
        led: {},
        frame: {},
        TabBarItem: {},
      },
      variantValues: { variant: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="10px 10px 10px 10px"
      display="flex"
      {...getOverrideProps(overrides, "TabBarItem")}
      {...rest}
    >
      <Flex
        gap="6px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "frame")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24.204544067382812px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Item"
          {...getOverrideProps(overrides, "Item")}
        ></Text>
        <View
          width="unset"
          height="4px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(0,0,0,0)"
          {...getOverrideProps(overrides, "led")}
        ></View>
      </Flex>
    </Flex>
  );
}
