import React, { useState, useEffect, useCallback } from 'react';
import BackgroundC from '../react-components/BackgroundC';
import SideBarC from '../react-components/SideBarC';
import AudiosContainerC from '../react-components/AudiosContainerC';
import TabBarCatC from '../react-components/TabBarCatC';
import UploadContainerC from '../react-components/UploadContainerC';
import { uploadData } from 'aws-amplify/storage';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createAudio, createCategory } from '../graphql/mutations';
import { listAudio, listCategories, listTemplates, getQuota } from '../graphql/queries';
import { onUpdateAudioByUserId, onCreateAudioByUserId, onDeleteAudioByUserId } from '../graphql/subscriptions';
import { v4 as uuidv4 } from 'uuid';

const AudiosPage = () => {
  const [audioItems, setAudioItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [usedStorage, setUsedStorage] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state

  const mapStatus = (status) => {
    switch (status) {
      case 'SUCCESS':
        return 'Success';
      case 'REVIEW':
        return 'Review';
      case 'FAIL':
        return 'Error';
      case 'PENDING':
        return 'Pending';
      default:
        return 'Pending';
    }
  };

  const stripExtension = (fileName) => fileName.replace(/\.[^/.]+$/, "");

  const fetchQuota = useCallback(async (userId) => {
    try {
      const client = generateClient();
      const quotaResult = await client.graphql({
        query: getQuota,
        variables: { userId }
      });

      const quota = quotaResult.data.getQuota;
      setUsedStorage((quota.s3Count / (1024 * 1024 * 1024)).toFixed(2)); // Convert from bytes to GB and format to 2 decimal places
      setTotalStorage((quota.s3Quota / (1024 * 1024 * 1024)).toFixed(2)); // Convert from bytes to GB and format to 2 decimal places

    } catch (error) {
      console.error('Error fetching quota data:', error);
    }
  }, []);

  const fetchData = useCallback(async (userId) => {
    try {
      const client = generateClient();

      const audioResult = await client.graphql({
        query: listAudio,
        variables: { userId }
      });

      const categoryResult = await client.graphql({
        query: listCategories,
        variables: { userId }
      });

      const templateResult = await client.graphql({
        query: listTemplates,
        variables: { userId }
      });

      const categoriesMap = categoryResult.data.listCategories.items.reduce((acc, category) => {
        acc[category.id] = category.name;
        return acc;
      }, {});

      const templatesMap = templateResult.data.listTemplates.items.reduce((acc, template) => {
        acc[template.id] = template.name;
        return acc;
      }, {});

      const items = audioResult.data.listAudio.items.map(item => ({
        audio: stripExtension(item.name),
        category: categoriesMap[item.categoryAudiosId] || 'Unknown',
        template: templatesMap[item.templateAudiosId] || 'Automatique',
        date: item.createdAt,
        status: mapStatus(item.status),
        id: item.id // Added ID to the mapped items
      }));

      setAudioItems(items);
      setFilteredItems(items);
      setCategories(categoryResult.data.listCategories.items);
      setTemplates(templateResult.data.listTemplates.items.map(template => ({ id: template.id, name: template.name })));
    } catch (error) {
      console.error('Error fetching audio data:', error);
    } finally {
      setLoading(false); // Stop loading once data is fetched
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const client = generateClient();
        const user = await getCurrentUser();
        const userId = user.userId;

        await fetchQuota(userId); // Fetch quota data
        await fetchData(userId);

        const subscriptions = [
          client.graphql({
            query: onUpdateAudioByUserId,
            variables: { userId: userId }
          }).subscribe({
            next: async ({ data }) => {
              console.log('Update subscription data:', data);
              await fetchQuota(userId); // Refresh quota data on update
              await fetchData(userId);
            },
            error: (error) => console.error('Update subscription error:', error)
          }),
          client.graphql({
            query: onCreateAudioByUserId,
            variables: { userId: userId }
          }).subscribe({
            next: async ({ data }) => {
              console.log('Create subscription data:', data);
              await fetchQuota(userId); // Refresh quota data on create
              await fetchData(userId);
            },
            error: (error) => console.error('Create subscription error:', error)
          }),
          client.graphql({
            query: onDeleteAudioByUserId,
            variables: { userId: userId }
          }).subscribe({
            next: async ({ data }) => {
              console.log('Delete subscription data:', data);
              await fetchQuota(userId); // Refresh quota data on delete
              await fetchData(userId);
            },
            error: (error) => console.error('Delete subscription error:', error)
          })
        ];

        return () => subscriptions.forEach(sub => sub.unsubscribe());
      } catch (error) {
        console.error('Error initializing page:', error);
      }
    };

    initialize();
  }, [fetchData, fetchQuota]);

  const handleCategoryChange = (category) => {
    if (category === 'Tous') {
      setFilteredItems(audioItems);
    } else {
      setFilteredItems(audioItems.filter(item => item.category === category));
    }
  };

  const handleUpload = async ({ file, fileName, fileCategory, fileTemplate, isAutomatic }) => {
    if (!file) return;
    try {
      const session = await fetchAuthSession();
      const identityId = session.identityId;

      console.log('Uploading file:', file);
      console.log('File name:', fileName);
      console.log('Identity ID:', identityId);

      const audioId = uuidv4();
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      const s3FileName = `${audioId}${extension}`;
      const path = `uploads/${identityId}/${audioId}/${s3FileName}`;
      console.log('S3 Path:', path);

      await uploadData({
        path,
        data: file,
      });
      console.log('File uploaded successfully');

      const user = await getCurrentUser();
      const userId = user.userId;
      const client = generateClient();

      let categoryId;
      let templateId;

      // Check if the category already exists
      const existingCategory = categories.find(category => category.name === fileCategory && category.userId === userId);

      if (!existingCategory) {
        // Create new category if it doesn't exist
        const newCategoryId = uuidv4();
        const categoryResult = await client.graphql({
          query: createCategory,
          variables: {
            input: { userId: userId, id: newCategoryId, name: fileCategory }
          }
        });
        categoryId = categoryResult.data.createCategory.id;
        setCategories(prevCategories => [...prevCategories, { name: fileCategory, id: categoryId, userId }]);
      } else {
        categoryId = existingCategory.id;
      }

      if (!isAutomatic) {
        // Find the selected template only if "Automatique" is not selected
        const selectedTemplate = templates.find(template => template.name === fileTemplate);
        if (selectedTemplate) {
          templateId = selectedTemplate.id;
        }
      }

      const audioDetails = {
        userId: userId,
        id: audioId,
        name: fileName,
        audioPath: `private/${identityId}/${audioId}/${audioId}.mp3`,
        categoryAudiosUserId: userId,
        categoryAudiosId: categoryId,
        automatic: isAutomatic || false, // Set automatic flag based on "Automatique" option
        ...(isAutomatic ? {} : { templateAudiosUserId: userId, templateAudiosId: templateId }) // Only add template details if not automatic
      };

      await client.graphql({
        query: createAudio,
        variables: {
          input: audioDetails
        }
      });
      console.log('Database item created successfully');

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="AudiosPage" style={{ width: '100vw', height: '100vh', overflowY: 'auto', position: 'relative' }}>
      <BackgroundC>
        <div style={{ position: 'relative', zIndex: 1002 }}>
          <SideBarC />
        </div>
        <div style={{ marginLeft: '90px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 80px)', minHeight: '100%' }}>
          <div style={{ display: 'flex', width: '100%', maxWidth: '1440px', flex: '1 0 auto', justifyContent: 'space-between', padding: '10px', boxSizing: 'border-box' }}>
            <div style={{ flex: '1 1 66%', paddingRight: '10px', overflow: 'visible' }}>
              <div style={{ marginBottom: '15px' }}>
                <TabBarCatC categories={categories.map(category => category.name)} onCategoryChange={handleCategoryChange} loading={loading} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'visible' }}>
                <AudiosContainerC audioItems={filteredItems} loading={loading} />
              </div>
            </div>
            <div style={{ flex: '1 1 27%', paddingTop: '25px', paddingLeft: '10px', paddingRight: '20px', overflow: 'visible' }}>
              <UploadContainerC
                totalStorage={parseFloat(totalStorage)} // Ensure that the value is a number
                usedStorage={parseFloat(usedStorage)} // Ensure that the value is a number
                categories={categories.map(category => category.name)}
                templates={templates.map(template => template.name)}
                maxCategory={5}
                onUpload={handleUpload}
                loading={loading}
                automaticOption // Pass the automatic option to the UploadContainerC
              />
            </div>
          </div>
        </div>
      </BackgroundC>
    </div>
  );
};

export default AudiosPage;