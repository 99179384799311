import React, { useEffect, useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { KeyPointItem } from '../ui-components';

const SectionListC = ({ keyPoints, audioTime, setAudioCurrentTime }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(null);

  useEffect(() => {
    // Update focus based on the current audio time
    const currentIndex = keyPoints.findIndex(
      (keyPoint, index) => audioTime >= keyPoint.temps && (index === keyPoints.length - 1 || audioTime < keyPoints[index + 1].temps)
    );

    if (currentIndex !== -1 && currentIndex !== focusedIndex) {
      setFocusedIndex(currentIndex);
    }
  }, [audioTime, keyPoints, focusedIndex]);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  const handleFocus = (index) => setFocusedIndex(index);
  const handleBlur = () => setFocusedIndex(null);

  const handleClick = (index) => {
    setAudioCurrentTime(keyPoints[index].temps);
    setFocusedIndex(index);
  };

  return (
    <Flex direction="column" gap="0px" width="100%">
      {keyPoints.map((keyPoint, index) => {
        const isLast = index === keyPoints.length - 1;
        const variant =
          focusedIndex === index
            ? isLast
              ? 'lastFocus'
              : 'DefaultFocus'
            : hoveredIndex === index
            ? isLast
              ? 'lastHover'
              : 'DefaultHover'
            : isLast
            ? 'last'
            : 'Default';

        return (
          <KeyPointItem
            key={index}
            variant={variant}
            overrides={{
              KeyPoint: {
                children: keyPoint.section,
              },
              KeyPointItem: {
                width: '100%',
                boxSizing: 'border-box',
                paddingTop: index === 0 ? '20px' : '0px',
                onMouseEnter: () => handleMouseEnter(index),
                onMouseLeave: handleMouseLeave,
                onFocus: () => handleFocus(index),
                onBlur: handleBlur,
                onClick: () => handleClick(index),
                style: { cursor: 'pointer' },
                tabIndex: 0,
                outline: 'none',
              },
            }}
          />
        );
      })}
    </Flex>
  );
};

export default SectionListC;