import React, { useState, useEffect } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { HistoricItem } from '../ui-components';
import { Link } from 'react-router-dom';

const HistoricListC = ({ historicItems, currentAudio }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(null);

  useEffect(() => {
    // Find the index of the item with the current audio
    const initialFocusIndex = historicItems.findIndex(
      item => item.audio === currentAudio
    );
    setFocusedIndex(initialFocusIndex);
  }, [currentAudio, historicItems]);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  const handleFocus = (index) => setFocusedIndex(index);
  const handleBlur = () => setFocusedIndex(null);
  const handleClick = (index) => setFocusedIndex(index);

  // Function to format date
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  // Sort items by date, most recent first
  const sortedItems = [...historicItems].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <Flex direction="column" gap="0px" width="100%">
      {sortedItems.map((item, index) => (
        <Link 
          to={`/app/analyse/${item.audio}`} 
          style={{ textDecoration: 'none', display: 'block' }}
          key={index}
          onClick={() => handleClick(index)}
        >
          <HistoricItem
            variant={
              focusedIndex === index
                ? 'Focus'
                : hoveredIndex === index
                ? 'Hover'
                : 'Default'
            }
            overrides={{
              Heading: {
                children: item.audio,
              },
              Content: {
                children: formatDate(item.date), // Format the date
              },
              HistoricItem: {
                width: '100%',
                boxSizing: 'border-box',
                paddingTop: index === 0 ? '20px' : '0px',
                onMouseEnter: () => handleMouseEnter(index),
                onMouseLeave: handleMouseLeave,
                onFocus: () => handleFocus(index),
                onBlur: handleBlur,
                style: { cursor: 'pointer' },
                tabIndex: 0,
                outline: 'none',
              },
            }}
          />
        </Link>
      ))}
    </Flex>
  );
};

export default HistoricListC;