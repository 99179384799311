import { Routes, Route, Navigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import TranscriptPage from './pages/TranscriptPage';
import SummaryPage from './pages/SummaryPage';
import AnalysePage from './pages/AnalysePage';
import AudiosPage from './pages/AudiosPage';
import TemplatesPage from './pages/TemplatesPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/app/audios" />} />
        <Route path="/app/transcription/:audioId" element={<TranscriptPage />} />
        <Route path="/app/summary/:audioId" element={<SummaryPage />} />
        <Route path="/app/analyse/:audioId" element={<AnalysePage />} />
        <Route path="/app/audios" element={<AudiosPage />} />
        <Route path="/app/templates" element={<TemplatesPage />} />
      </Routes>
    </div>
  );
}

export default withAuthenticator(App);