/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function GoogleButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, "Google Agenda": {}, GoogleButton: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M8.58047 0.69848C6.00086 1.59337 3.7762 3.29191 2.23327 5.5446C0.690334 7.79729 -0.0895452 10.4854 0.00818177 13.2141C0.105909 15.9428 1.07609 18.5682 2.77622 20.7047C4.47635 22.8413 6.81682 24.3763 9.45385 25.0844C11.5917 25.636 13.8316 25.6603 15.981 25.155C17.928 24.7176 19.7282 23.7821 21.2051 22.4401C22.7422 21.0006 23.8579 19.1694 24.4323 17.1433C25.0566 14.9401 25.1677 12.623 24.7571 10.3701L12.7517 10.3701L12.7517 15.3502L19.7044 15.3502C19.5654 16.1445 19.2677 16.9026 18.8289 17.5791C18.3901 18.2556 17.8194 18.8366 17.1508 19.2874C16.3018 19.849 15.3448 20.2269 14.3411 20.3968C13.3345 20.584 12.302 20.584 11.2954 20.3968C10.2752 20.1859 9.31003 19.7648 8.46147 19.1604C7.09826 18.1954 6.07467 16.8245 5.53678 15.2433C4.98979 13.6325 4.98979 11.8862 5.53678 10.2753C5.91966 9.14624 6.55262 8.1182 7.38841 7.26795C8.34487 6.27708 9.55577 5.56879 10.8883 5.22082C12.2208 4.87284 13.6234 4.8986 14.9422 5.29529C15.9724 5.61155 16.9145 6.1641 17.6934 6.90892C18.4774 6.129 19.26 5.34706 20.0412 4.56311C20.4446 4.14155 20.8844 3.74016 21.2817 3.30852C20.0928 2.20213 18.6973 1.34122 17.175 0.775127C14.403 -0.231392 11.37 -0.258441 8.58047 0.69848Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Google Agenda": { color: "rgba(154,226,161,1)" },
        GoogleButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M8.58047 0.69848C6.00086 1.59337 3.7762 3.29191 2.23327 5.5446C0.690334 7.79729 -0.0895452 10.4854 0.00818177 13.2141C0.105909 15.9428 1.07609 18.5682 2.77622 20.7047C4.47635 22.8413 6.81682 24.3763 9.45385 25.0844C11.5917 25.636 13.8316 25.6603 15.981 25.155C17.928 24.7176 19.7282 23.7821 21.2051 22.4401C22.7422 21.0006 23.8579 19.1694 24.4323 17.1433C25.0566 14.9401 25.1677 12.623 24.7571 10.3701L12.7517 10.3701L12.7517 15.3502L19.7044 15.3502C19.5654 16.1445 19.2677 16.9026 18.8289 17.5791C18.3901 18.2556 17.8194 18.8366 17.1508 19.2874C16.3018 19.849 15.3448 20.2269 14.3411 20.3968C13.3345 20.584 12.302 20.584 11.2954 20.3968C10.2752 20.1859 9.31003 19.7648 8.46147 19.1604C7.09826 18.1954 6.07467 16.8245 5.53678 15.2433C4.98979 13.6325 4.98979 11.8862 5.53678 10.2753C5.91966 9.14624 6.55262 8.1182 7.38841 7.26795C8.34487 6.27708 9.55577 5.56879 10.8883 5.22082C12.2208 4.87284 13.6234 4.8986 14.9422 5.29529C15.9724 5.61155 16.9145 6.1641 17.6934 6.90892C18.4774 6.129 19.26 5.34706 20.0412 4.56311C20.4446 4.14155 20.8844 3.74016 21.2817 3.30852C20.0928 2.20213 18.6973 1.34122 17.175 0.775127C14.403 -0.231392 11.37 -0.258441 8.58047 0.69848Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Google Agenda": { color: "rgba(49,112,61,1)" },
        GoogleButton: {},
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M8.58047 0.69848C6.00086 1.59337 3.7762 3.29191 2.23327 5.5446C0.690334 7.79729 -0.0895452 10.4854 0.00818177 13.2141C0.105909 15.9428 1.07609 18.5682 2.77622 20.7047C4.47635 22.8413 6.81682 24.3763 9.45385 25.0844C11.5917 25.636 13.8316 25.6603 15.981 25.155C17.928 24.7176 19.7282 23.7821 21.2051 22.4401C22.7422 21.0006 23.8579 19.1694 24.4323 17.1433C25.0566 14.9401 25.1677 12.623 24.7571 10.3701L12.7517 10.3701L12.7517 15.3502L19.7044 15.3502C19.5654 16.1445 19.2677 16.9026 18.8289 17.5791C18.3901 18.2556 17.8194 18.8366 17.1508 19.2874C16.3018 19.849 15.3448 20.2269 14.3411 20.3968C13.3345 20.584 12.302 20.584 11.2954 20.3968C10.2752 20.1859 9.31003 19.7648 8.46147 19.1604C7.09826 18.1954 6.07467 16.8245 5.53678 15.2433C4.98979 13.6325 4.98979 11.8862 5.53678 10.2753C5.91966 9.14624 6.55262 8.1182 7.38841 7.26795C8.34487 6.27708 9.55577 5.56879 10.8883 5.22082C12.2208 4.87284 13.6234 4.8986 14.9422 5.29529C15.9724 5.61155 16.9145 6.1641 17.6934 6.90892C18.4774 6.129 19.26 5.34706 20.0412 4.56311C20.4446 4.14155 20.8844 3.74016 21.2817 3.30852C20.0928 2.20213 18.6973 1.34122 17.175 0.775127C14.403 -0.231392 11.37 -0.258441 8.58047 0.69848Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Google Agenda": { color: "rgba(239,240,240,1)" },
        GoogleButton: {},
      },
      variantValues: { variant: "Disable" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M8.58047 0.69848C6.00086 1.59337 3.7762 3.29191 2.23327 5.5446C0.690334 7.79729 -0.0895452 10.4854 0.00818177 13.2141C0.105909 15.9428 1.07609 18.5682 2.77622 20.7047C4.47635 22.8413 6.81682 24.3763 9.45385 25.0844C11.5917 25.636 13.8316 25.6603 15.981 25.155C17.928 24.7176 19.7282 23.7821 21.2051 22.4401C22.7422 21.0006 23.8579 19.1694 24.4323 17.1433C25.0566 14.9401 25.1677 12.623 24.7571 10.3701L12.7517 10.3701L12.7517 15.3502L19.7044 15.3502C19.5654 16.1445 19.2677 16.9026 18.8289 17.5791C18.3901 18.2556 17.8194 18.8366 17.1508 19.2874C16.3018 19.849 15.3448 20.2269 14.3411 20.3968C13.3345 20.584 12.302 20.584 11.2954 20.3968C10.2752 20.1859 9.31003 19.7648 8.46147 19.1604C7.09826 18.1954 6.07467 16.8245 5.53678 15.2433C4.98979 13.6325 4.98979 11.8862 5.53678 10.2753C5.91966 9.14624 6.55262 8.1182 7.38841 7.26795C8.34487 6.27708 9.55577 5.56879 10.8883 5.22082C12.2208 4.87284 13.6234 4.8986 14.9422 5.29529C15.9724 5.61155 16.9145 6.1641 17.6934 6.90892C18.4774 6.129 19.26 5.34706 20.0412 4.56311C20.4446 4.14155 20.8844 3.74016 21.2817 3.30852C20.0928 2.20213 18.6973 1.34122 17.175 0.775127C14.403 -0.231392 11.37 -0.258441 8.58047 0.69848Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Google Agenda": { color: "rgba(199,239,202,1)" },
        GoogleButton: {},
      },
      variantValues: { variant: "NoSelect" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="230px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "GoogleButton")}
      {...rest}
    >
      <Icon
        width="25px"
        height="25.52px"
        viewBox={{ minX: 0, minY: 0, width: 25, height: 25.516820907592773 }}
        paths={[
          {
            d: "M8.58047 0.69848C6.00086 1.59337 3.7762 3.29191 2.23327 5.5446C0.690334 7.79729 -0.0895452 10.4854 0.00818177 13.2141C0.105909 15.9428 1.07609 18.5682 2.77622 20.7047C4.47635 22.8413 6.81682 24.3763 9.45385 25.0844C11.5917 25.636 13.8316 25.6603 15.981 25.155C17.928 24.7176 19.7282 23.7821 21.2051 22.4401C22.7422 21.0006 23.8579 19.1694 24.4323 17.1433C25.0566 14.9401 25.1677 12.623 24.7571 10.3701L12.7517 10.3701L12.7517 15.3502L19.7044 15.3502C19.5654 16.1445 19.2677 16.9026 18.8289 17.5791C18.3901 18.2556 17.8194 18.8366 17.1508 19.2874C16.3018 19.849 15.3448 20.2269 14.3411 20.3968C13.3345 20.584 12.302 20.584 11.2954 20.3968C10.2752 20.1859 9.31003 19.7648 8.46147 19.1604C7.09826 18.1954 6.07467 16.8245 5.53678 15.2433C4.98979 13.6325 4.98979 11.8862 5.53678 10.2753C5.91966 9.14624 6.55262 8.1182 7.38841 7.26795C8.34487 6.27708 9.55577 5.56879 10.8883 5.22082C12.2208 4.87284 13.6234 4.8986 14.9422 5.29529C15.9724 5.61155 16.9145 6.1641 17.6934 6.90892C18.4774 6.129 19.26 5.34706 20.0412 4.56311C20.4446 4.14155 20.8844 3.74016 21.2817 3.30852C20.0928 2.20213 18.6973 1.34122 17.175 0.775127C14.403 -0.231392 11.37 -0.258441 8.58047 0.69848Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Google Agenda"
        {...getOverrideProps(overrides, "Google Agenda")}
      ></Text>
    </Flex>
  );
}
