/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Send(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { Send: {} }, variantValues: { variant: "Default" } },
    {
      overrides: {
        Send: {
          paths: [
            {
              d: "M31.8185 0C33.5755 0 35 1.4245 35 3.1815L35 31.8185C35 32.2363 34.9177 32.65 34.7578 33.036C34.5979 33.422 34.3636 33.7727 34.0682 34.0682C33.7727 34.3636 33.422 34.5979 33.036 34.7578C32.65 34.9177 32.2363 35 31.8185 35L3.1815 35C2.7637 35 2.34999 34.9177 1.96399 34.7578C1.578 34.5979 1.22727 34.3636 0.93184 34.0682C0.63641 33.7727 0.402063 33.422 0.242177 33.036C0.0822919 32.65 -5.58581e-16 32.2363 2.83651e-30 31.8185L4.85723e-17 3.1815C4.85723e-17 1.4245 1.4245 0 3.1815 0L31.8185 0ZM16.6583 13.3298L9.842 20.1023C9.61736 20.3255 9.49061 20.6288 9.48962 20.9455C9.48864 21.2622 9.6135 21.5664 9.83675 21.791C10.06 22.0156 10.3633 22.1424 10.68 22.1434C10.9967 22.1444 11.3009 22.0195 11.5255 21.7962L17.5088 15.8498L23.6162 21.8032C23.7272 21.9181 23.8601 22.0095 24.0071 22.072C24.154 22.1345 24.312 22.1669 24.4717 22.1673C24.6314 22.1677 24.7895 22.136 24.9368 22.0741C25.084 22.0123 25.2173 21.9215 25.3288 21.8072C25.4403 21.6929 25.5277 21.5573 25.5859 21.4086C25.6441 21.2599 25.6718 21.101 25.6675 20.9414C25.6631 20.7817 25.6268 20.6246 25.5606 20.4793C25.4945 20.3339 25.3998 20.2033 25.2822 20.0952L18.333 13.3228C18.1088 13.1038 17.8075 12.9819 17.4942 12.9832C17.1809 12.9845 16.8806 13.109 16.6583 13.3298Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Send: {
          paths: [
            {
              d: "M31.8185 0C33.5755 0 35 1.4245 35 3.1815L35 31.8185C35 32.2363 34.9177 32.65 34.7578 33.036C34.5979 33.422 34.3636 33.7727 34.0682 34.0682C33.7727 34.3636 33.422 34.5979 33.036 34.7578C32.65 34.9177 32.2363 35 31.8185 35L3.1815 35C2.7637 35 2.34999 34.9177 1.96399 34.7578C1.578 34.5979 1.22727 34.3636 0.93184 34.0682C0.63641 33.7727 0.402063 33.422 0.242177 33.036C0.0822919 32.65 -5.58581e-16 32.2363 2.83651e-30 31.8185L4.85723e-17 3.1815C4.85723e-17 1.4245 1.4245 0 3.1815 0L31.8185 0ZM16.6583 13.3298L9.842 20.1023C9.61736 20.3255 9.49061 20.6288 9.48962 20.9455C9.48864 21.2622 9.6135 21.5664 9.83675 21.791C10.06 22.0156 10.3633 22.1424 10.68 22.1434C10.9967 22.1444 11.3009 22.0195 11.5255 21.7962L17.5088 15.8498L23.6162 21.8032C23.7272 21.9181 23.8601 22.0095 24.0071 22.072C24.154 22.1345 24.312 22.1669 24.4717 22.1673C24.6314 22.1677 24.7895 22.136 24.9368 22.0741C25.084 22.0123 25.2173 21.9215 25.3288 21.8072C25.4403 21.6929 25.5277 21.5573 25.5859 21.4086C25.6441 21.2599 25.6718 21.101 25.6675 20.9414C25.6631 20.7817 25.6268 20.6246 25.5606 20.4793C25.4945 20.3339 25.3998 20.2033 25.2822 20.0952L18.333 13.3228C18.1088 13.1038 17.8075 12.9819 17.4942 12.9832C17.1809 12.9845 16.8806 13.109 16.6583 13.3298Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Send: {
          paths: [
            {
              d: "M31.8185 0C33.5755 0 35 1.4245 35 3.1815L35 31.8185C35 32.2363 34.9177 32.65 34.7578 33.036C34.5979 33.422 34.3636 33.7727 34.0682 34.0682C33.7727 34.3636 33.422 34.5979 33.036 34.7578C32.65 34.9177 32.2363 35 31.8185 35L3.1815 35C2.7637 35 2.34999 34.9177 1.96399 34.7578C1.578 34.5979 1.22727 34.3636 0.93184 34.0682C0.63641 33.7727 0.402063 33.422 0.242177 33.036C0.0822919 32.65 -5.58581e-16 32.2363 2.83651e-30 31.8185L4.85723e-17 3.1815C4.85723e-17 1.4245 1.4245 0 3.1815 0L31.8185 0ZM16.6583 13.3298L9.842 20.1023C9.61736 20.3255 9.49061 20.6288 9.48962 20.9455C9.48864 21.2622 9.6135 21.5664 9.83675 21.791C10.06 22.0156 10.3633 22.1424 10.68 22.1434C10.9967 22.1444 11.3009 22.0195 11.5255 21.7962L17.5088 15.8498L23.6162 21.8032C23.7272 21.9181 23.8601 22.0095 24.0071 22.072C24.154 22.1345 24.312 22.1669 24.4717 22.1673C24.6314 22.1677 24.7895 22.136 24.9368 22.0741C25.084 22.0123 25.2173 21.9215 25.3288 21.8072C25.4403 21.6929 25.5277 21.5573 25.5859 21.4086C25.6441 21.2599 25.6718 21.101 25.6675 20.9414C25.6631 20.7817 25.6268 20.6246 25.5606 20.4793C25.4945 20.3339 25.3998 20.2033 25.2822 20.0952L18.333 13.3228C18.1088 13.1038 17.8075 12.9819 17.4942 12.9832C17.1809 12.9845 16.8806 13.109 16.6583 13.3298Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0%, 0%)" },
            },
          ],
        },
      },
      variantValues: { variant: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="35px"
      height="35px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
      paths={[
        {
          d: "M31.8185 0C33.5755 0 35 1.4245 35 3.1815L35 31.8185C35 32.2363 34.9177 32.65 34.7578 33.036C34.5979 33.422 34.3636 33.7727 34.0682 34.0682C33.7727 34.3636 33.422 34.5979 33.036 34.7578C32.65 34.9177 32.2363 35 31.8185 35L3.1815 35C2.7637 35 2.34999 34.9177 1.96399 34.7578C1.578 34.5979 1.22727 34.3636 0.93184 34.0682C0.63641 33.7727 0.402063 33.422 0.242177 33.036C0.0822919 32.65 -5.58581e-16 32.2363 2.83651e-30 31.8185L4.85723e-17 3.1815C4.85723e-17 1.4245 1.4245 0 3.1815 0L31.8185 0ZM16.6583 13.3298L9.842 20.1023C9.61736 20.3255 9.49061 20.6288 9.48962 20.9455C9.48864 21.2622 9.6135 21.5664 9.83675 21.791C10.06 22.0156 10.3633 22.1424 10.68 22.1434C10.9967 22.1444 11.3009 22.0195 11.5255 21.7962L17.5088 15.8498L23.6162 21.8032C23.7272 21.9181 23.8601 22.0095 24.0071 22.072C24.154 22.1345 24.312 22.1669 24.4717 22.1673C24.6314 22.1677 24.7895 22.136 24.9368 22.0741C25.084 22.0123 25.2173 21.9215 25.3288 21.8072C25.4403 21.6929 25.5277 21.5573 25.5859 21.4086C25.6441 21.2599 25.6718 21.101 25.6675 20.9414C25.6631 20.7817 25.6268 20.6246 25.5606 20.4793C25.4945 20.3339 25.3998 20.2033 25.2822 20.0952L18.333 13.3228C18.1088 13.1038 17.8075 12.9819 17.4942 12.9832C17.1809 12.9845 16.8806 13.109 16.6583 13.3298Z",
          fill: "rgba(76,203,104,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Send")}
      {...rest}
    ></Icon>
  );
}
