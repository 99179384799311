/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  Flex,
  SelectField,
  SwitchField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function ItemEdit(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="20px"
      direction="row"
      width="852px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(220,222,224,1)"
      borderRadius="4px"
      padding="5px 5px 5px 5px"
      {...getOverrideProps(overrides, "ItemEdit")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "SpeakerChanges")}
      >
        <SelectField
          width="135px"
          height="unset"
          placeholder="Speaker"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="quiet"
          {...getOverrideProps(overrides, "SelectField")}
        ></SelectField>
        <TextField
          width="135px"
          height="unset"
          placeholder="Speaker"
          alignItems="center"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="quiet"
          {...getOverrideProps(overrides, "TextField")}
        ></TextField>
        <SwitchField
          width="unset"
          height="unset"
          label="pour tous"
          shrink="0"
          size="small"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="start"
          {...getOverrideProps(overrides, "SwitchField")}
        ></SwitchField>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(13,26,38,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Value"
        {...getOverrideProps(overrides, "Value")}
      ></Text>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Buttons")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="link"
          children="valider"
          {...getOverrideProps(overrides, "Button3878192")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          isDisabled={false}
          variation="warning"
          children="annuler"
          {...getOverrideProps(overrides, "Button3879212")}
        ></Button>
      </Flex>
    </Flex>
  );
}
