/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function AppleButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, "Calendrier Apple": {}, AppleButton: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M20.8298 28.4827C19.2144 30.0486 17.4508 29.8014 15.753 29.0596C13.9563 28.3014 12.308 28.2685 10.4125 29.0596C8.03892 30.0816 6.78621 29.7849 5.36866 28.4827C-2.67507 20.1918 -1.48829 7.56573 7.64333 7.1042C9.86854 7.21958 11.418 8.32395 12.7201 8.42285C14.6651 8.02725 16.5277 6.88992 18.6046 7.03827C21.0935 7.23607 22.9726 8.22505 24.2088 10.0052C19.0661 13.0876 20.2858 19.8621 25 21.7577C24.0605 24.2301 22.8407 26.6861 20.8133 28.4992L20.8298 28.4827ZM12.5553 7.0053C12.308 3.32958 15.2915 0.296695 18.72 0C19.198 4.25263 14.8629 7.41738 12.5553 7.0053Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Calendrier Apple": { color: "rgba(154,226,161,1)" },
        AppleButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M20.8298 28.4827C19.2144 30.0486 17.4508 29.8014 15.753 29.0596C13.9563 28.3014 12.308 28.2685 10.4125 29.0596C8.03892 30.0816 6.78621 29.7849 5.36866 28.4827C-2.67507 20.1918 -1.48829 7.56573 7.64333 7.1042C9.86854 7.21958 11.418 8.32395 12.7201 8.42285C14.6651 8.02725 16.5277 6.88992 18.6046 7.03827C21.0935 7.23607 22.9726 8.22505 24.2088 10.0052C19.0661 13.0876 20.2858 19.8621 25 21.7577C24.0605 24.2301 22.8407 26.6861 20.8133 28.4992L20.8298 28.4827ZM12.5553 7.0053C12.308 3.32958 15.2915 0.296695 18.72 0C19.198 4.25263 14.8629 7.41738 12.5553 7.0053Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Calendrier Apple": { color: "rgba(49,112,61,1)" },
        AppleButton: {},
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M20.8298 28.4827C19.2144 30.0486 17.4508 29.8014 15.753 29.0596C13.9563 28.3014 12.308 28.2685 10.4125 29.0596C8.03892 30.0816 6.78621 29.7849 5.36866 28.4827C-2.67507 20.1918 -1.48829 7.56573 7.64333 7.1042C9.86854 7.21958 11.418 8.32395 12.7201 8.42285C14.6651 8.02725 16.5277 6.88992 18.6046 7.03827C21.0935 7.23607 22.9726 8.22505 24.2088 10.0052C19.0661 13.0876 20.2858 19.8621 25 21.7577C24.0605 24.2301 22.8407 26.6861 20.8133 28.4992L20.8298 28.4827ZM12.5553 7.0053C12.308 3.32958 15.2915 0.296695 18.72 0C19.198 4.25263 14.8629 7.41738 12.5553 7.0053Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Calendrier Apple": { color: "rgba(239,240,240,1)" },
        AppleButton: {},
      },
      variantValues: { variant: "Disable" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M20.8298 28.4827C19.2144 30.0486 17.4508 29.8014 15.753 29.0596C13.9563 28.3014 12.308 28.2685 10.4125 29.0596C8.03892 30.0816 6.78621 29.7849 5.36866 28.4827C-2.67507 20.1918 -1.48829 7.56573 7.64333 7.1042C9.86854 7.21958 11.418 8.32395 12.7201 8.42285C14.6651 8.02725 16.5277 6.88992 18.6046 7.03827C21.0935 7.23607 22.9726 8.22505 24.2088 10.0052C19.0661 13.0876 20.2858 19.8621 25 21.7577C24.0605 24.2301 22.8407 26.6861 20.8133 28.4992L20.8298 28.4827ZM12.5553 7.0053C12.308 3.32958 15.2915 0.296695 18.72 0C19.198 4.25263 14.8629 7.41738 12.5553 7.0053Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Calendrier Apple": { color: "rgba(199,239,202,1)" },
        AppleButton: {},
      },
      variantValues: { variant: "NoSelect" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="230px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "AppleButton")}
      {...rest}
    >
      <Icon
        width="25px"
        height="29.67px"
        viewBox={{ minX: 0, minY: 0, width: 25, height: 29.673267364501953 }}
        paths={[
          {
            d: "M20.8298 28.4827C19.2144 30.0486 17.4508 29.8014 15.753 29.0596C13.9563 28.3014 12.308 28.2685 10.4125 29.0596C8.03892 30.0816 6.78621 29.7849 5.36866 28.4827C-2.67507 20.1918 -1.48829 7.56573 7.64333 7.1042C9.86854 7.21958 11.418 8.32395 12.7201 8.42285C14.6651 8.02725 16.5277 6.88992 18.6046 7.03827C21.0935 7.23607 22.9726 8.22505 24.2088 10.0052C19.0661 13.0876 20.2858 19.8621 25 21.7577C24.0605 24.2301 22.8407 26.6861 20.8133 28.4992L20.8298 28.4827ZM12.5553 7.0053C12.308 3.32958 15.2915 0.296695 18.72 0C19.198 4.25263 14.8629 7.41738 12.5553 7.0053Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Calendrier Apple"
        {...getOverrideProps(overrides, "Calendrier Apple")}
      ></Text>
    </Flex>
  );
}
