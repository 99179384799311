import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AudioContainerC from '../react-components/AudioContainerC';
import TabBarC from '../react-components/TabBarC';
import ItemContainerC from '../react-components/ItemContainerC';
import BackgroundC from '../react-components/BackgroundC';
import KeyPointContainerC from '../react-components/KeyPointContainerC';
import SideBarC from '../react-components/SideBarC';
import { downloadData, uploadData } from 'aws-amplify/storage';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { getAudio } from '../graphql/queries';
import { updateAudio } from '../graphql/mutations';

const TranscriptPage = () => {
  const { audioId } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [audioPath, setAudioPath] = useState('');
  const [audioTime, setAudioTime] = useState(null);
  const [keyPoints, setKeyPoints] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [gptMetaData, setGptMetaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchAudioData = async () => {
      try {
        const client = generateClient();
        const user = await getCurrentUser();
        const userId = user.userId;

        const result = await client.graphql({
          query: getAudio,
          variables: { userId, id: audioId },
        });

        const audioData = result.data.getAudio;
        if (audioData) {
          const { audioPath, transcriptionPath, keyPoints, speakers, gptMetaData } = audioData;

          const audioDownload = await downloadData({ path: audioPath }).result;
          const audioBlob = new Blob([audioDownload.body], { type: 'audio/mp3' });
          setAudioPath(URL.createObjectURL(audioBlob));

          const transcriptionDownload = await downloadData({ path: transcriptionPath }).result;
          const transcriptionJson = await transcriptionDownload.body.text();
          const data = JSON.parse(transcriptionJson);

          let speakerMap = JSON.parse(speakers).reduce((acc, { speaker_id, name }) => {
            acc[speaker_id] = name;
            return acc;
          }, {});

          const formattedItems = data.segments
            .map(segment => ({
              start: segment.start,
              end: segment.end,
              text: segment.text,
              speaker: segment.speaker,
              speakerName: speakerMap[segment.speaker] || segment.speaker,
            }))
            .filter(item => /^SPEAKER_\d{2}$/.test(item.speaker));

          setItems(formattedItems);
          setSpeakers(JSON.parse(speakers));
          setKeyPoints(JSON.parse(keyPoints));
          setGptMetaData(gptMetaData);
        }
      } catch (error) {
        console.error('Error fetching audio data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAudioData();
  }, [audioId]);

  useEffect(() => {
    console.log('Key Points state updated:', keyPoints);
  }, [keyPoints]);

  const setAudioCurrentTime = (time) => {
    if (audioRef.current) {
      audioRef.current.setCurrentTimeExternal(time);
    }
  };

  const handleSaveChanges = async (applyToAll, newSpeakers, localChanges, formattedItems) => {
    const client = generateClient();
    const user = await getCurrentUser();
    const userId = user.userId;

    try {
      // Step 1: Use the received formattedItems to create JSON and send it to S3
      console.log('Received formattedItems before constructing JSON:', formattedItems);

      const updatedTranscriptJson = {
        segments: formattedItems.map(item => ({
          start: item.start,
          end: item.end,
          text: item.text,
          speaker: item.speaker,
        })),
      };

      console.log('Constructed JSON for S3:', updatedTranscriptJson);

      await uploadTranscriptToS3(updatedTranscriptJson);

      // Step 2: Update the API with speaker changes
      if (applyToAll || localChanges.size > 0) {
        const updatedSpeakers = speakers.map(speaker => {
          const newSpeaker = newSpeakers.find(ns => ns.speaker_id === speaker.speaker_id);
          return newSpeaker ? { ...speaker, name: newSpeaker.name } : speaker;
        });

        console.log('Updated Speakers for API:', updatedSpeakers);

        await client.graphql({
          query: updateAudio,
          variables: {
            input: {
              userId,
              id: audioId,
              speakers: JSON.stringify(updatedSpeakers),
            },
          },
        });

        setSpeakers(updatedSpeakers);
      }

      navigate('/app/audios');

    } catch (error) {
      console.error('Error saving changes or uploading to S3:', error);
    }
  };

  const uploadTranscriptToS3 = async (transcriptJson) => {
    try {
      const session = await fetchAuthSession();
      const identityId = session.identityId;
      const s3Path = `reviews/${identityId}/${audioId}.json`;

      console.log('Uploading Transcript JSON to S3:', transcriptJson);

      await uploadData({
        path: s3Path,
        data: JSON.stringify(transcriptJson),
      });

      console.log('Transcript JSON successfully uploaded to S3:', s3Path);
    } catch (error) {
      console.error('Error uploading transcript to S3:', error);
    }
  };

  const sendFormattedItemsToParent = (formattedItems) => {
    console.log('Formatted Items Received:', formattedItems);
  };

  return (
    <div className="TranscriptPage" style={{ width: '100vw', height: '100vh', overflowY: 'auto', position: 'relative' }}>
      <BackgroundC>
        <div style={{ position: 'relative', zIndex: 1002 }}>
          <SideBarC />
        </div>
        <div style={{ marginLeft: '90px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 80px)', minHeight: '100%' }}>
          <div style={{ display: 'flex', width: '100%', maxWidth: '1440px', flex: '1 0 auto', justifyContent: 'space-between', padding: '10px', boxSizing: 'border-box' }}>
            <div style={{ flex: '1 1 66%', paddingRight: '10px', overflow: 'visible' }}>
              <div style={{ marginBottom: '15px' }}>
                <TabBarC gptMetaData={gptMetaData} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'visible' }}>
                <AudioContainerC audio={audioPath} setAudioTime={setAudioTime} audioRef={audioRef} loading={loading} />
                <ItemContainerC
                  items={items}
                  audioTime={audioTime}
                  setAudioCurrentTime={setAudioCurrentTime}
                  setNewSpeakers={setSpeakers}
                  handleSaveChanges={handleSaveChanges}
                  sendFormattedItemsToParent={sendFormattedItemsToParent}  // Pass the function down
                  loading={loading}
                  gptMetaData={gptMetaData}
                />
              </div>
            </div>
            <div style={{ flex: '1 1 27%', paddingTop: '25px', paddingLeft: '10px', paddingRight: '20px', overflow: 'visible' }}>
              <KeyPointContainerC keyPoints={keyPoints} audioTime={audioTime} setAudioCurrentTime={setAudioCurrentTime} loading={loading} />
            </div>
          </div>
        </div>
      </BackgroundC>
    </div>
  );
};

export default TranscriptPage;