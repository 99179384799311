/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function TemplatexText(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Vector40051200: {},
        Vector40051201: {},
        Group: {},
        "TemplatexText/TemplateLogo": {},
        "Mod\u00E8les de compte rendu": {},
        TemplatexText: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector40051200: {
          paths: [
            {
              d: "M0 0L0 7.8L23.4 7.8L23.4 0L0 0ZM20.8 2.6L2.6 2.6L2.6 5.2L20.8 5.2L20.8 2.6ZM0 10.4L0 23.4L10.4 23.4L10.4 10.4L0 10.4ZM7.8 13L2.6 13L2.6 20.8L7.8 20.8L7.8 13Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "evenodd",
            },
          ],
        },
        Vector40051201: {
          paths: [
            {
              d: "M10.4 0L0 0L0 2.6L10.4 2.6L10.4 0ZM0 5.2L10.4 5.2L10.4 7.8L0 7.8L0 5.2ZM10.4 10.4L0 10.4L0 13L10.4 13L10.4 10.4Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Group: {},
        "TemplatexText/TemplateLogo": {},
        "Mod\u00E8les de compte rendu": { color: "rgba(154,226,161,1)" },
        TemplatexText: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector40051200: {
          paths: [
            {
              d: "M0 0L0 7.8L23.4 7.8L23.4 0L0 0ZM20.8 2.6L2.6 2.6L2.6 5.2L20.8 5.2L20.8 2.6ZM0 10.4L0 23.4L10.4 23.4L10.4 10.4L0 10.4ZM7.8 13L2.6 13L2.6 20.8L7.8 20.8L7.8 13Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "evenodd",
            },
          ],
        },
        Vector40051201: {
          paths: [
            {
              d: "M10.4 0L0 0L0 2.6L10.4 2.6L10.4 0ZM0 5.2L10.4 5.2L10.4 7.8L0 7.8L0 5.2ZM10.4 10.4L0 10.4L0 13L10.4 13L10.4 10.4Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Group: {},
        "TemplatexText/TemplateLogo": {},
        "Mod\u00E8les de compte rendu": {
          fontWeight: "700",
          color: "rgba(49,112,61,1)",
        },
        TemplatexText: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="150px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "TemplatexText")}
      {...rest}
    >
      <Flex
        gap="13px"
        direction="row"
        width="31.2px"
        height="31.2px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="3.9000000953674316px 3.9000000953674316px 3.9000000953674316px 3.9000000953674316px"
        display="flex"
        {...getOverrideProps(overrides, "TemplatexText/TemplateLogo")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="23.4px"
          height="23.4px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group")}
        >
          <Icon
            width="23.4px"
            height="23.4px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 23.399999618530273,
              height: 23.399999618530273,
            }}
            paths={[
              {
                d: "M0 0L0 7.8L23.4 7.8L23.4 0L0 0ZM20.8 2.6L2.6 2.6L2.6 5.2L20.8 5.2L20.8 2.6ZM0 10.4L0 23.4L10.4 23.4L10.4 10.4L0 10.4ZM7.8 13L2.6 13L2.6 20.8L7.8 20.8L7.8 13Z",
                fill: "rgba(76,203,104,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector40051200")}
          ></Icon>
          <Icon
            width="10.4px"
            height="13px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 10.399999618530273,
              height: 12.999999046325684,
            }}
            paths={[
              {
                d: "M10.4 0L0 0L0 2.6L10.4 2.6L10.4 0ZM0 5.2L10.4 5.2L10.4 7.8L0 7.8L0 5.2ZM10.4 10.4L0 10.4L0 13L10.4 13L10.4 10.4Z",
                fill: "rgba(76,203,104,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="44.44%"
            bottom="0%"
            left="55.56%"
            right="0%"
            {...getOverrideProps(overrides, "Vector40051201")}
          ></Icon>
        </Flex>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Modèles de compte rendu"
        {...getOverrideProps(overrides, "Mod\u00E8les de compte rendu")}
      ></Text>
    </Flex>
  );
}
