/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function EventButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Vector: {},
        "Missions et \u00C9v\u00E8nements": {},
        EventButton: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M26.6667 3.33333L25 3.33333L25 0L21.6667 0L21.6667 3.33333L8.33333 3.33333L8.33333 0L5 0L5 3.33333L3.33333 3.33333C1.5 3.33333 0 4.83333 0 6.66667L0 30C7.40149e-16 30.8841 0.351189 31.7319 0.976311 32.357C1.60143 32.9821 2.44928 33.3333 3.33333 33.3333L26.6667 33.3333C28.5167 33.3333 30 31.85 30 30L30 6.66667C30 5.78261 29.6488 4.93477 29.0237 4.30964C28.3986 3.68452 27.5507 3.33333 26.6667 3.33333ZM26.6667 30L3.33333 30L3.33333 13.3333L26.6667 13.3333L26.6667 30ZM3.33333 10L3.33333 6.66667L26.6667 6.66667L26.6667 10L3.33333 10ZM12.6 27.4333L22.5 17.55L20.7167 15.7833L12.6 23.9L9.08333 20.3833L7.31667 22.15L12.6 27.4333Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Missions et \u00C9v\u00E8nements": { color: "rgba(48,64,80,1)" },
        EventButton: { width: "405px" },
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M26.6667 3.33333L25 3.33333L25 0L21.6667 0L21.6667 3.33333L8.33333 3.33333L8.33333 0L5 0L5 3.33333L3.33333 3.33333C1.5 3.33333 0 4.83333 0 6.66667L0 30C7.40149e-16 30.8841 0.351189 31.7319 0.976311 32.357C1.60143 32.9821 2.44928 33.3333 3.33333 33.3333L26.6667 33.3333C28.5167 33.3333 30 31.85 30 30L30 6.66667C30 5.78261 29.6488 4.93477 29.0237 4.30964C28.3986 3.68452 27.5507 3.33333 26.6667 3.33333ZM26.6667 30L3.33333 30L3.33333 13.3333L26.6667 13.3333L26.6667 30ZM3.33333 10L3.33333 6.66667L26.6667 6.66667L26.6667 10L3.33333 10ZM12.6 27.4333L22.5 17.55L20.7167 15.7833L12.6 23.9L9.08333 20.3833L7.31667 22.15L12.6 27.4333Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Missions et \u00C9v\u00E8nements": { fontWeight: "800" },
        EventButton: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="460px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "EventButton")}
      {...rest}
    >
      <Icon
        width="30px"
        height="33.33px"
        viewBox={{ minX: 0, minY: 0, width: 30, height: 33.333335876464844 }}
        paths={[
          {
            d: "M26.6667 3.33333L25 3.33333L25 0L21.6667 0L21.6667 3.33333L8.33333 3.33333L8.33333 0L5 0L5 3.33333L3.33333 3.33333C1.5 3.33333 0 4.83333 0 6.66667L0 30C7.40149e-16 30.8841 0.351189 31.7319 0.976311 32.357C1.60143 32.9821 2.44928 33.3333 3.33333 33.3333L26.6667 33.3333C28.5167 33.3333 30 31.85 30 30L30 6.66667C30 5.78261 29.6488 4.93477 29.0237 4.30964C28.3986 3.68452 27.5507 3.33333 26.6667 3.33333ZM26.6667 30L3.33333 30L3.33333 13.3333L26.6667 13.3333L26.6667 30ZM3.33333 10L3.33333 6.66667L26.6667 6.66667L26.6667 10L3.33333 10ZM12.6 27.4333L22.5 17.55L20.7167 15.7833L12.6 23.9L9.08333 20.3833L7.31667 22.15L12.6 27.4333Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="600"
        color="rgba(13,26,38,1)"
        lineHeight="19.363636016845703px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Missions et Évènements"
        {...getOverrideProps(overrides, "Missions et \u00C9v\u00E8nements")}
      ></Text>
    </Flex>
  );
}
