import React, { useState, useEffect } from 'react';
import { Flex, View, Button, Alert, Text } from '@aws-amplify/ui-react';
import { AudioItem } from '../ui-components';
import { useNavigate } from 'react-router-dom';

const AudioListC = ({ audioItems }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [deleteHoverIndex, setDeleteHoverIndex] = useState(null);
  const [deleteFocusIndex, setDeleteFocusIndex] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [sortedAudioItems, setSortedAudioItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sortedItems = [...audioItems].sort((a, b) => new Date(b.date) - new Date(a.date));
    setSortedAudioItems(sortedItems);
  }, [audioItems]);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  const handleFocus = (index) => setFocusedIndex(index);
  const handleBlur = () => setFocusedIndex(null);
  const handleDeleteMouseEnter = (index) => setDeleteHoverIndex(index);
  const handleDeleteMouseLeave = () => setDeleteHoverIndex(null);
  const handleDeleteFocus = (index) => setDeleteFocusIndex(index);
  const handleDeleteBlur = () => setDeleteFocusIndex(null);

  const handleDeleteClick = (item) => {
    if (item.status !== 'Pending') {
      setItemToDelete(item);
      setShowDialog(true);
    }
  };

  const confirmDelete = () => {
    console.log(`Audio ${itemToDelete.audio} deleted`);
    setShowDialog(false);
    setItemToDelete(null);
  };

  const cancelDelete = () => {
    setShowDialog(false);
    setItemToDelete(null);
  };

  const handleItemClick = (item, index) => {
    if (item.status === 'Success' || item.status === 'Review') {
      navigate(`/app/transcription/${item.id}`);
    }
  };

  const getVariant = (item, index) => {
    if (deleteFocusIndex === index && (item.status === 'Success' || item.status === 'Error' || item.status === 'Review')) return `${item.status}DelFocus`;
    if (deleteHoverIndex === index && (item.status === 'Success' || item.status === 'Error' || item.status === 'Review')) return `${item.status}DelHover`;
    if (focusedIndex === index && (item.status === 'Success' || item.status === 'Review')) return `${item.status}Focus`;
    if (hoveredIndex === index && (item.status === 'Success' || item.status === 'Review')) return `${item.status}Hover`;
    return item.status;
  };

  return (
    <>
      {showDialog && (
        <View
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1002',
          }}
        >
          <Alert
            variation="warning"
            isDismissible={false}
            hasIcon={true}
            heading="Confirmation"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              maxWidth: '400px',
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              border: '2px solid black',
            }}
          >
            <Text style={{ paddingTop: '15px' }}>Supprimer {itemToDelete?.audio} ?</Text>
            <Text style={{ paddingTop: '5px' }}> Cette action est irréversible.</Text>
            <Flex justifyContent="flex-end" gap="10px" marginTop="20px">
              <Button size="small" variation="link" onClick={cancelDelete}>
                Annuler
              </Button>
              <Button size="small" variation="destructive" onClick={confirmDelete}>
                Supprimer
              </Button>
            </Flex>
          </Alert>
        </View>
      )}
      <Flex direction="column" gap="10px" width="100%">
        {sortedAudioItems.map((item, index) => {
          const variant = getVariant(item, index);

          const audioItemComponent = (
            <AudioItem
              key={index}
              variant={variant}
              overrides={{
                Audio: {
                  children: item.audio,
                },
                Category: {
                  children: item.category,
                },
                Template: {
                  children: item.template,
                },
                Date: {
                  children: new Date(item.date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  }).replace(/,/g, ' '), // Format the date and replace commas with spaces
                },
                AudioItem: {
                  onMouseEnter: () => (item.status === 'Success' || item.status === 'Review') && handleMouseEnter(index),
                  onMouseLeave: () => (item.status === 'Success' || item.status === 'Review') && handleMouseLeave(),
                  onFocus: () => (item.status === 'Success' || item.status === 'Review') && handleFocus(index),
                  onBlur: () => (item.status === 'Success' || item.status === 'Review') && handleBlur(),
                  style: { cursor: (item.status === 'Success' || item.status === 'Review') ? 'pointer' : 'default' },
                  tabIndex: (item.status === 'Success' || item.status === 'Review') ? 0 : -1, // Ensure only Success or Review items are focusable
                  outline: 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                  gap: '10px',
                  width: '100%',
                  onClick: (e) => {
                    if ((item.status === 'Success' || item.status === 'Review') && !e.target.closest('.delete-icon')) {
                      handleItemClick(item, index);
                    }
                  },
                },
                icon: {
                  onMouseEnter: () => (item.status !== 'Pending') && handleDeleteMouseEnter(index),
                  onMouseLeave: handleDeleteMouseLeave,
                  onFocus: () => (item.status !== 'Pending') && handleDeleteFocus(index),
                  onBlur: handleDeleteBlur,
                  onClick: () => handleDeleteClick(item),
                  className: 'delete-icon',
                  style: { cursor: (item.status !== 'Pending') ? 'pointer' : 'default' },
                  tabIndex: (item.status !== 'Pending') ? 0 : -1, // Ensure delete icon is not focusable for Pending items
                  outline: 'none',
                },
              }}
            />
          );

          return (
            <div key={index}>
              {audioItemComponent}
            </div>
          );
        })}
      </Flex>
    </>
  );
};

export default AudioListC;