/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function PlayAndPause(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { PlayAndPause: {} },
      variantValues: { variant: "PlayPrimary" },
    },
    {
      overrides: {
        PlayAndPause: {
          paths: [
            {
              d: "M14.2857 25L14.2857 0L21.4286 0L21.4286 25L14.2857 25ZM0 25L0 0L7.14286 0L7.14286 25L0 25Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(22.5%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PausePrimary" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(0,0,0,0)",
          paths: [
            {
              d: "M14.2857 25L14.2857 0L21.4286 0L21.4286 25L14.2857 25ZM0 25L0 0L7.14286 0L7.14286 25L0 25Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
              style: { transform: "translate(22.5%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PauseTransFocus" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(34,66,38,1)",
          paths: [
            {
              d: "M14.2857 25L14.2857 0L21.4286 0L21.4286 25L14.2857 25ZM0 25L0 0L7.14286 0L7.14286 25L0 25Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(22.5%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PausePrimaryHover" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(34,66,38,1)",
          paths: [
            {
              d: "M14.2857 25L14.2857 0L21.4286 0L21.4286 25L14.2857 25ZM0 25L0 0L7.14286 0L7.14286 25L0 25Z",
              fill: "rgba(0,0,0,1)",
              fillRule: "nonzero",
              style: { transform: "translate(22.5%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PlayPrimaryFocus" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(0,0,0,0)",
          paths: [
            {
              d: "M14.2857 25L14.2857 0L21.4286 0L21.4286 25L14.2857 25ZM0 25L0 0L7.14286 0L7.14286 25L0 25Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "nonzero",
              style: { transform: "translate(22.5%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PauseTrans" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(0,0,0,0)",
          paths: [
            {
              d: "M14.2857 25L14.2857 0L21.4286 0L21.4286 25L14.2857 25ZM0 25L0 0L7.14286 0L7.14286 25L0 25Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
              style: { transform: "translate(22.5%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PauseTransHover" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(0,0,0,0)",
          paths: [
            {
              d: "M0 0L0 25L19.6429 12.5L0 0Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "nonzero",
              style: { transform: "translate(25%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PlayTrans" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(0,0,0,0)",
          paths: [
            {
              d: "M0 0L0 25L19.6429 12.5L0 0Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
              style: { transform: "translate(25%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PlayTransHover" },
    },
    {
      overrides: {
        PlayAndPause: {
          backgroundColor: "rgba(0,0,0,0)",
          paths: [
            {
              d: "M0 0L0 25L19.6429 12.5L0 0Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
              style: { transform: "translate(25%, 20%)" },
            },
          ],
        },
      },
      variantValues: { variant: "PlayTransFocus" },
    },
    {
      overrides: { PlayAndPause: { backgroundColor: "rgba(34,66,38,1)" } },
      variantValues: { variant: "PlayPrimaryHover" },
    },
    {
      overrides: { PlayAndPause: { backgroundColor: "rgba(34,66,38,1)" } },
      variantValues: { variant: "PlayPrimaryFocus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="40.02px"
      height="40.02px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      border="0.01px SOLID rgba(255,255,255,1)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(76,203,104,1)"
      viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
      paths={[
        {
          d: "M0 0L0 25L19.6429 12.5L0 0Z",
          fill: "rgba(13,26,38,1)",
          fillRule: "nonzero",
          style: { transform: "translate(25%, 20%)" },
        },
      ]}
      {...getOverrideProps(overrides, "PlayAndPause")}
      {...rest}
    ></Icon>
  );
}
