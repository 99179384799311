/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function SummaryButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, "Synth\u00E8se": {}, SummaryButton: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 20L0 16.6667L20 16.6667L20 20L0 20ZM0 11.6667L0 8.33333L30 8.33333L30 11.6667L0 11.6667ZM0 3.33333L0 0L30 0L30 3.33333L0 3.33333Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Synth\u00E8se": { color: "rgba(48,64,80,1)" },
        SummaryButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 20L0 16.6667L20 16.6667L20 20L0 20ZM0 11.6667L0 8.33333L30 8.33333L30 11.6667L0 11.6667ZM0 3.33333L0 0L30 0L30 3.33333L0 3.33333Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        "Synth\u00E8se": { fontWeight: "800" },
        SummaryButton: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="460px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "SummaryButton")}
      {...rest}
    >
      <Icon
        width="30px"
        height="20px"
        viewBox={{ minX: 0, minY: 0, width: 30, height: 19.999998092651367 }}
        paths={[
          {
            d: "M0 20L0 16.6667L20 16.6667L20 20L0 20ZM0 11.6667L0 8.33333L30 8.33333L30 11.6667L0 11.6667ZM0 3.33333L0 0L30 0L30 3.33333L0 3.33333Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="600"
        color="rgba(13,26,38,1)"
        lineHeight="19.363636016845703px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Synthèse"
        {...getOverrideProps(overrides, "Synth\u00E8se")}
      ></Text>
    </Flex>
  );
}
