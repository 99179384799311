import React, { useState, useRef } from 'react';
import { Button, Flex, TextField, Heading, View, Alert, Text } from '@aws-amplify/ui-react';

const ManageC = ({ templates, selectedTemplateIndex, addTemplate, deleteTemplate, addBlockToTemplate, validateTemplates, hasChanges, onCancel, setNewBlockAdded }) => {
  const [newTemplateName, setNewTemplateName] = useState('');
  const [addingTemplate, setAddingTemplate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const addBlockButtonRef = useRef(null);

  const handleAddTemplate = () => {
    const trimmedTemplateName = newTemplateName.trim().toLowerCase();
    if (trimmedTemplateName === '') {
      setErrorMessage('Le nom du modèle ne peut pas être vide');
      return;
    }
    if (trimmedTemplateName === 'automatique') {
      setErrorMessage('Le nom "automatique" est réservé et ne peut pas être utilisé.');
      return;
    }
    if (templates.some(template => template.name.trim().toLowerCase() === trimmedTemplateName)) {
      setErrorMessage('Un modèle avec ce nom existe déjà');
      return;
    }
    if (templates.length >= 5) {
      setErrorMessage('Vous ne pouvez pas ajouter plus de 5 modèles');
      return;
    }
    addTemplate(newTemplateName.trim());
    setNewTemplateName('');
    setAddingTemplate(false);
  };

  const handleCancelAddTemplate = () => {
    setNewTemplateName('');
    setAddingTemplate(false);
  };

  const handleAddBlock = () => {
    if (templates[selectedTemplateIndex].blocks.length >= 20) {
      setErrorMessage('Vous ne pouvez pas ajouter plus de 20 blocs à un modèle');
      return;
    }
    addBlockToTemplate(selectedTemplateIndex);
    setNewBlockAdded(true);
    if (addBlockButtonRef.current) {
      addBlockButtonRef.current.blur();
    }
  };

  const handleDeleteTemplate = (index) => {
    if (hasChanges) {
      setErrorMessage('Veuillez valider les modifications avant de supprimer un modèle.');
      return;
    }
    if (templates.length <= 1) {
      setErrorMessage('Vous ne pouvez pas supprimer le dernier modèle');
      return;
    }
    setConfirmDelete(true);
  };

  const confirmDeleteTemplate = () => {
    deleteTemplate(selectedTemplateIndex);
    setConfirmDelete(false);
  };

  const dismissError = () => {
    setErrorMessage('');
  };

  return (
    <>
      {errorMessage && (
        <View
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1002',
          }}
        >
          <Alert
            variation="error"
            isDismissible={false}
            hasIcon={true}
            heading="Erreur"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              maxWidth: '400px',
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              border: '2px solid black',
            }}
          >
            <Text style={{ paddingTop: '20px' }}>{errorMessage}</Text>
            <Flex justifyContent="flex-end" gap="10px" marginTop="20px">
              <Button size="small" variation="warning" onClick={dismissError}>
                OK
              </Button>
            </Flex>
          </Alert>
        </View>
      )}

      {confirmDelete && (
        <View
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1002'
          }}
        >
          <Alert
            variation="warning"
            isDismissible={false}
            hasIcon={true}
            heading="Confirmation"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              maxWidth: '400px',
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              border: '2px solid black',
            }}
          >
            <Text style={{ paddingTop: '15px' }}>Supprimer {templates[selectedTemplateIndex]?.name} ?</Text>
            <Text style={{ paddingTop: '5px' }}>Cette action est irréversible.</Text>
            <Flex justifyContent="flex-end" gap="10px" marginTop="20px">
              <Button size="small" variation="link" onClick={() => setConfirmDelete(false)}>
                Annuler
              </Button>
              <Button size="small" variation="destructive" onClick={confirmDeleteTemplate}>
                Supprimer
              </Button>
            </Flex>
          </Alert>
        </View>
      )}

      <Flex direction="column" justifyContent="space-between" padding="30px 0" height="100%" width="100%">
        <Flex direction="column" gap="20px" alignItems="center" padding="0 10px">
          {addingTemplate ? (
            <Flex direction="column" gap="10px" width="100%">
              <TextField
                placeholder="Nom du nouveau modèle"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
              />
              <Flex gap="10px" justifyContent="space-between">
                <Button onClick={handleCancelAddTemplate} variation="warning" style={{ flex: 1, marginRight: '5px' }}>Annuler</Button>
                <Button onClick={handleAddTemplate} variation="primary" style={{ flex: 1, marginLeft: '5px' }}>Ajouter</Button>
              </Flex>
            </Flex>
          ) : (
            <Button onClick={() => setAddingTemplate(true)} variation="link" size="large" style={{ alignSelf: 'center' }}>+ Ajouter un modèle</Button>
          )}
        </Flex>

        <Flex direction="column" gap="20px" alignItems="center" justifyContent="flex-start" flex="1" style={{ marginTop: 'auto', paddingTop: '33%' }}>
          {templates[selectedTemplateIndex] && (
            <Flex direction="column" gap="30px" width="100%" alignItems="center">
              <Heading level={5} textAlign="center" width="100%">{templates[selectedTemplateIndex].name}</Heading>
              <Button ref={addBlockButtonRef} onClick={handleAddBlock} variation="link" style={{ alignSelf: 'center' }}>+ Ajouter un bloc</Button>
            </Flex>
          )}
        </Flex>

        <Flex direction="column" gap="20px" alignItems="center" padding="0 10px 20px" style={{ marginTop: 'auto', width: '100%' }}>
          {templates[selectedTemplateIndex] && (
            <Button onClick={() => handleDeleteTemplate(selectedTemplateIndex)} variation="destructive" width="100%" disabled={hasChanges}>
              Supprimer le modèle
            </Button>
          )}
        </Flex>

        {hasChanges && (
          <Flex gap="10px" justifyContent="space-between" padding="0 10px 20px" marginTop="20px">
            <Button onClick={onCancel} variation="warning" style={{ flex: 1 }}>Annuler</Button>
            <Button onClick={validateTemplates} variation="primary" style={{ flex: 1 }}>Valider</Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default ManageC;