import React, { useState, useRef } from 'react';
import { Rec } from '../ui-components';
import RecAudioPlayerC from './RecAudioPlayerC'; // Adjust the import path as necessary
import { Flex, Button, View, Alert, Text } from '@aws-amplify/ui-react';

const RecC = ({ onAudioRecorded }) => {
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const handleStartRecording = () => {
    if (audioURL) {
      setShowConfirmation(true);
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      let mediaRecorder;
      try {
        mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      } catch (e) {
        mediaRecorder = new MediaRecorder(stream); // Fallback for Safari
      }

      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);

        // Create a File object from the blob with a timestamped name
        const date = new Date().toISOString().slice(0, 10); // Get the date in YYYY-MM-DD format
        const audioFile = new File([audioBlob], `Enregistrement-${date}.mp3`, { type: 'audio/mp3' });
        onAudioRecorded(audioFile); // Pass the audio file to the callback function
      };

      mediaRecorder.start();
      setRecording(true);
      setPaused(false);
    });
  };

  const handlePauseRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.pause();
      setPaused(true);
    }
  };

  const handleResumeRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.resume();
      setPaused(false);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      setPaused(false);
    }
  };

  const handleRecClick = () => {
    if (!recording) {
      handleStartRecording();
    } else if (paused) {
      handleResumeRecording();
    } else {
      handlePauseRecording();
    }
  };

  const handleConfirmRestart = () => {
    setShowConfirmation(false);
    setAudioURL('');
    startRecording();
  };

  const handleCancelRestart = () => {
    setShowConfirmation(false);
  };

  const getVariant = () => {
    if (!recording) {
      if (active) return "RecFocus";
      if (hover) return "RecHover";
      return "Rec";
    } else if (paused) {
      if (active) return "RecPlayFocus";
      if (hover) return "RecPlayHover";
      return "RecPlay";
    } else {
      if (active) return "RecPauseFocus";
      if (hover) return "RecPauseHover";
      return "RecPause";
    }
  };

  return (
    <>
      {showConfirmation && (
        <View
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1002',
          }}
        >
          <Alert
            variation="warning"
            isDismissible={false}
            hasIcon={true}
            heading="Confirmation"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              maxWidth: '400px',
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              border: '2px solid black',
            }}
          >
            <Text style={{ paddingTop: '15px' }}>Redémarrer un enregistrement effacera le précédent. Cette action est irréversible.</Text>
            <Flex justifyContent="flex-end" gap="10px" marginTop="20px">
              <Button size="small" variation="link" onClick={handleCancelRestart}>
                Annuler
              </Button>
              <Button size="small" variation="destructive" onClick={handleConfirmRestart}>
                Redémarrer
              </Button>
            </Flex>
          </Alert>
        </View>
      )}
      <Flex direction="column" alignItems="center" gap="0px" paddingTop="30px">
        <View
          onClick={handleRecClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onMouseDown={() => setActive(true)}
          onMouseUp={() => setActive(false)}
          onBlur={() => setActive(false)}
          tabIndex={0} // Make the div focusable
        >
          <Rec variant={getVariant()} />
        </View>
        {recording && (
          <Button onClick={handleStopRecording} variation="primary" marginTop="20px">
            Terminer
          </Button>
        )}
        {audioURL && (
          <View width="100%" marginTop="5px">
            <RecAudioPlayerC audio={audioURL} />
          </View>
        )}
      </Flex>
    </>
  );
};

export default RecC;