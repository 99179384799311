/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function ShareButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, Partager: {}, ShareButton: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 21.0526L0 13.1579L10.5263 10.5263L0 7.89474L0 0L25 10.5263L0 21.0526Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Partager: { color: "rgba(154,226,161,1)" },
        ShareButton: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 21.0526L0 13.1579L10.5263 10.5263L0 7.89474L0 0L25 10.5263L0 21.0526Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Partager: { color: "rgba(49,112,61,1)" },
        ShareButton: {},
      },
      variantValues: { variant: "Focus" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 21.0526L0 13.1579L10.5263 10.5263L0 7.89474L0 0L25 10.5263L0 21.0526Z",
              fill: "rgba(239,240,240,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Partager: { color: "rgba(239,240,240,1)" },
        ShareButton: {},
      },
      variantValues: { variant: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="230px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "ShareButton")}
      {...rest}
    >
      <Icon
        width="25px"
        height="21.05px"
        viewBox={{ minX: 0, minY: 0, width: 25, height: 21.052631378173828 }}
        paths={[
          {
            d: "M0 21.0526L0 13.1579L10.5263 10.5263L0 7.89474L0 0L25 10.5263L0 21.0526Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="600"
        color="rgba(76,203,104,1)"
        lineHeight="16.94318199157715px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Partager"
        {...getOverrideProps(overrides, "Partager")}
      ></Text>
    </Flex>
  );
}
