import React, { useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { SummaryButton, NoteButton, EventButton, MindMapButton } from '../ui-components';

const SummaryMenuC = ({ focusedMenuItem, setFocusedMenuItem }) => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttons = [
    { Component: SummaryButton, name: 'summary' },
    { Component: NoteButton, name: 'note' },
    { Component: EventButton, name: 'event' },
    { Component: MindMapButton, name: 'mindmap' }
  ];

  const getVariant = (name) => {
    if (focusedMenuItem === name) return 'Focus';
    if (hoveredButton === name) return 'Hover';
    return 'Default';
  };

  return (
    <Flex direction="column" gap="30px" width="100%">
      {buttons.map(({ Component, name }) => (
        <Component
          key={name}
          width="100%"
          variant={getVariant(name)}
          onClick={() => setFocusedMenuItem(name)}
          onMouseEnter={() => setHoveredButton(name)}
          onMouseLeave={() => setHoveredButton(null)}
          onFocus={() => setFocusedMenuItem(name)}
          onBlur={() => {}}
          className="summary-menu-button"
          overrides={{
            [Component.name]: {
              width: '100%',
              cursor: 'pointer',
              style: {
                cursor: 'pointer',
              },
            }
          }}
        />
      ))}
    </Flex>
  );
};

export default SummaryMenuC;