import React from 'react';
import { View } from '@aws-amplify/ui-react';
import { HistoricContainer } from '../ui-components';
import HistoricListC from './HistoricListC';

const HistoricContainerC = ({ historicItems, currentAudio }) => {
  return (
    <HistoricContainer
      overrides={{
        HistoricContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '480px',
          height: '744px',
          padding: '20px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <View style={{ flex: 1, overflowY: 'auto' }}>
        <HistoricListC historicItems={historicItems} currentAudio={currentAudio} />
      </View>
    </HistoricContainer>
  );
};

export default HistoricContainerC;