import React from 'react';
import { View, Flex } from '@aws-amplify/ui-react';
import { TemplateContainer } from '../ui-components';
import SummaryMenuC from './SummaryMenuC';
import ShareC from './ShareC';

const MenuContainerC = ({ focusedMenuItem, setFocusedMenuItem }) => {
  return (
    <TemplateContainer
      overrides={{
        TemplateContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '480px',
          height: '744px',
          padding: '35px 10px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <Flex
        direction="column"
        justifyContent="space-between"
        style={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <View style={{ paddingLeft: '30px', paddingRight: '30px' }}>
          <SummaryMenuC 
            focusedMenuItem={focusedMenuItem}
            setFocusedMenuItem={setFocusedMenuItem}
          />
        </View>
        <View style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <ShareC focusedMenuItem={focusedMenuItem} />
        </View>
      </Flex>
    </TemplateContainer>
  );
};

export default MenuContainerC;