import React from 'react';
import BlockListC from './BlockListC';
import { BlockContainer } from '../ui-components';
import { Loader } from '@aws-amplify/ui-react';

const BlockContainerC = ({ blocks, onBlocksChange, newBlockAdded, loading }) => {
  return (
    <BlockContainer
      overrides={{
        BlockContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minWidth: '455px',
          height: '700px',
          padding: '10px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <Loader size="large" style={{ transform: 'scale(2.5)' }} />
        </div>
      ) : (
        <div style={{ flex: 1, overflowY: 'auto', padding: '10px' }}>
          <BlockListC blocks={blocks} onBlocksChange={onBlocksChange} newBlockAdded={newBlockAdded} />
        </div>
      )}
    </BlockContainer>
  );
};

export default BlockContainerC;