import React, { useState, useRef, useEffect } from 'react';
import { Flex, View } from '@aws-amplify/ui-react';
import { AudioBarxTime, PlayAndPause } from '../ui-components';

const RecAudioPlayerC = ({ audio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const localAudioRef = useRef(null);
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const barRef = useRef(null);

  useEffect(() => {
    const audioElement = localAudioRef.current;

    const getDuration = (event) => {
      event.target.currentTime = 0;
      setDuration(event.target.duration);
      event.target.removeEventListener('timeupdate', getDuration);
    };

    const updateProgress = () => {
      setCurrentTime(audioElement.currentTime);
    };

    const initializeDuration = () => {
      if (audioElement.duration === Infinity || isNaN(Number(audioElement.duration))) {
        audioElement.currentTime = 1e101;
        audioElement.addEventListener('timeupdate', getDuration);
      } else {
        setDuration(audioElement.duration);
      }
    };

    audioElement.addEventListener('loadedmetadata', initializeDuration);
    audioElement.addEventListener('timeupdate', updateProgress);

    return () => {
      audioElement.removeEventListener('loadedmetadata', initializeDuration);
      audioElement.removeEventListener('timeupdate', updateProgress);
    };
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    } else {
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  };

  const handlePlayPause = () => {
    const audioElement = localAudioRef.current;
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleBarClick = (e) => {
    const audioElement = localAudioRef.current;
    const bar = barRef.current;
    const rect = bar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = Math.min(Math.max((offsetX / bar.clientWidth) * duration, 0), duration); // Ensure newTime is within bounds
    audioElement.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleBarDrag = (e) => {
    e.preventDefault();
    const audioElement = localAudioRef.current;
    const bar = barRef.current;
    const rect = bar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = Math.min(Math.max((offsetX / bar.clientWidth) * duration, 0), duration); // Ensure newTime is within bounds
    audioElement.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const getVariant = () => {
    if (isPlaying) {
      if (focus) return 'PauseTransFocus';
      if (hover) return 'PauseTransHover';
      return 'PauseTrans';
    } else {
      if (focus) return 'PlayTransFocus';
      if (hover) return 'PlayTransHover';
      return 'PlayTrans';
    }
  };

  const overrides = {
    Time: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 0px',
      boxSizing: 'border-box',
      overrides: {
        TimeA: {
          children: formatTime(currentTime),
          width: 'auto',
          flex: '0 0 auto',
          margin: '0',
          padding: '0',
        },
        TimeB: {
          children: formatTime(duration),
          width: 'auto',
          flex: '0 0 auto',
          margin: '0',
          padding: '0',
          textAlign: 'right',
        },
      },
    },
    AudioBar: {
      width: '100%',
      overrides: {
        Barbackground: {
          width: '100%',
        },
        Bar: {
          width: `${(currentTime / duration) * 100}%`,
        },
      },
    },
    AudioBarxTime: {
      width: '100%',
      onClick: handleBarClick,
      onMouseDown: (e) => {
        e.preventDefault();
        window.addEventListener('mousemove', handleBarDrag);
        window.addEventListener(
          'mouseup',
          () => {
            window.removeEventListener('mousemove', handleBarDrag);
          },
          { once: true }
        );
      },
      cursor: 'pointer',
    },
  };

  return (
    <Flex direction="column" alignItems="center" width="100%" maxWidth="720px" margin="auto" padding="10px">
      <audio ref={localAudioRef} id="audioplayer" src={audio} style={{ display: 'none' }}></audio>
      <Flex direction="row" alignItems="center" width="100%" gap="10px">
        <View
          onClick={handlePlayPause}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onMouseDown={() => setFocus(true)}
          onMouseUp={() => setFocus(false)}
          onBlur={() => setFocus(false)}
          style={{ cursor: 'pointer' }}
        >
          <PlayAndPause variant={getVariant()} />
        </View>
        <View ref={barRef} style={{ width: '100%' }}>
          <AudioBarxTime overrides={overrides} />
        </View>
      </Flex>
    </Flex>
  );
};

export default RecAudioPlayerC;