import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Flex } from '@aws-amplify/ui-react';
import { AudioLogo, TemplateLogo, AccountLogo, LogoutLogo, AudioxText, TemplatexText, AccountxText, LogoutxText } from '../ui-components';
import logoVector from '../assets/LogoVector.svg';
import { signOut } from 'aws-amplify/auth';

const SideBarC = () => {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [focusedItem, setFocusedItem] = useState(null);

  const handleMouseEnterSidebar = () => setIsHovered(true);
  const handleMouseLeaveSidebar = () => setIsHovered(false);

  const handleMouseEnterItem = (item) => setHoveredItem(item);
  const handleMouseLeaveItem = () => setHoveredItem(null);

  const handleFocus = (item) => setFocusedItem(item);
  const handleBlur = () => setFocusedItem(null);

  useEffect(() => {
    const pathToItemMap = {
      '/app/audios': 'Audio',
      '/app/templates': 'Template',
      '/app/account': 'Account',
      '/app/logout': 'Logout'
    };
    const currentItem = pathToItemMap[location.pathname];
    if (currentItem) {
      setFocusedItem(currentItem);
    }
  }, [location.pathname]);

  const getVariant = (item) => {
    if (focusedItem === item) return 'Focus';
    if (hoveredItem === item) return 'Hover';
    return 'Default';
  };

  const renderLogoOrText = (item, LogoComponent, TextComponent) => {
    return (
      <Flex direction="row" alignItems="center" gap="10px" style={{ width: '100%', position: 'relative' }}>
        <div style={{ opacity: isHovered ? 0 : 1, transform: isHovered ? 'translateX(-20px)' : 'translateX(0)', transition: 'opacity 0.3s, transform 0.3s' }}>
          <LogoComponent variant={getVariant(item)} />
        </div>
        <div style={{ opacity: isHovered ? 1 : 0, transform: `translateX(${isHovered ? '0' : '-20px'}) translateX(-50%)`, position: 'absolute', left: '50%', transition: 'opacity 0.3s, transform 0.3s', whiteSpace: 'nowrap' }}>
          <TextComponent variant={getVariant(item)} />
        </div>
      </Flex>
    );
  };

  const handleLogout = async () => {
    try {
      await signOut();
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      width={isHovered ? '185px' : '70px'}
      height="100vh"
      padding="20px 10px"
      backgroundColor="rgba(255, 255, 255, 1)"
      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
      position="fixed"
      left="0"
      top="0"
      zIndex="1001"
      gap="20px"
      onMouseEnter={handleMouseEnterSidebar}
      onMouseLeave={handleMouseLeaveSidebar}
      style={{ transition: 'width 0.3s, z-index 0.3s' }}
    >
      <Flex direction="column" alignItems="center" gap="50px">
        <img src={logoVector} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '30px' }} />
        <div 
          onMouseEnter={() => handleMouseEnterItem('Audio')} 
          onMouseLeave={handleMouseLeaveItem}
          onFocus={() => handleFocus('Audio')}
          onBlur={handleBlur}
          tabIndex="0"
        >
          <Link 
            to="/app/audios" 
            style={{ textDecoration: 'none' }}
          >
            {renderLogoOrText('Audio', AudioLogo, AudioxText)}
          </Link>
        </div>
        <div 
          onMouseEnter={() => handleMouseEnterItem('Template')} 
          onMouseLeave={handleMouseLeaveItem}
          onFocus={() => handleFocus('Template')}
          onBlur={handleBlur}
          tabIndex="0"
        >
          <Link 
            to="/app/templates" 
            style={{ textDecoration: 'none' }}
          >
            {renderLogoOrText('Template', TemplateLogo, TemplatexText)}
          </Link>
        </div>
        <div 
          onMouseEnter={() => handleMouseEnterItem('Account')} 
          onMouseLeave={handleMouseLeaveItem}
          onFocus={() => handleFocus('Account')}
          onBlur={handleBlur}
          tabIndex="0"
        >
          <Link 
            to="/app/account" 
            style={{ textDecoration: 'none' }}
          >
            {renderLogoOrText('Account', AccountLogo, AccountxText)}
          </Link>
        </div>
      </Flex>
      <div 
        onMouseEnter={() => handleMouseEnterItem('Logout')} 
        onMouseLeave={handleMouseLeaveItem}
        onFocus={() => handleFocus('Logout')}
        onBlur={handleBlur}
        tabIndex="0"
        style={{ marginBottom: '20px' }}
        onClick={handleLogout}
      >
        <Link 
          to="/app/logout" 
          style={{ textDecoration: 'none' }}
        >
          {renderLogoOrText('Logout', LogoutLogo, LogoutxText)}
        </Link>
      </div>
    </Flex>
  );
};

export default SideBarC;