import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Flex, Loader } from '@aws-amplify/ui-react';
import { TabBarItem } from '../ui-components';

const TabBarTemC = ({ templates, selectedTemplateIndex, onTemplateChange, loading }) => {
  const [fontSize, setFontSize] = useState('16px');
  const containerRef = useRef(null);

  const handleTabClick = (index) => {
    onTemplateChange(index);
  };

  const adjustFontSize = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const totalItems = templates.length;
      const maxItemLength = Math.max(...templates.map(template => template.name.length));
      const newFontSize = Math.min(20, containerWidth / (totalItems * maxItemLength));
      setFontSize(`${newFontSize}px`);
    }
  }, [templates]);

  useEffect(() => {
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, [adjustFontSize, templates]);

  return (
    <Flex ref={containerRef} direction="row" justifyContent="space-between" width="100%">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '54px' }}>
          <Loader size="large" style={{ transform: 'scale(1.5)' }} />
        </div>
      ) : (
        templates.map((template, index) => (
          <Flex key={index} flex="1" justifyContent="center">
            <TabBarItem
              variant={index === selectedTemplateIndex ? 'Active' : 'Default'}
              overrides={{
                Item: {
                  children: template.name,
                  fontSize,
                },
                TabBarItem: {
                  onClick: () => handleTabClick(index),
                  style: { cursor: 'pointer', flexBasis: 'auto', flexShrink: 0 },
                },
              }}
            />
          </Flex>
        ))
      )}
    </Flex>
  );
};

export default TabBarTemC;