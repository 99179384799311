/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function TemplateLogo(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Vector3992369: {},
        Vector3992370: {},
        Group: {},
        TemplateLogo: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector3992369: {
          paths: [
            {
              d: "M0 0L0 7.8L23.4 7.8L23.4 0L0 0ZM20.8 2.6L2.6 2.6L2.6 5.2L20.8 5.2L20.8 2.6ZM0 10.4L0 23.4L10.4 23.4L10.4 10.4L0 10.4ZM7.8 13L2.6 13L2.6 20.8L7.8 20.8L7.8 13Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "evenodd",
            },
          ],
        },
        Vector3992370: {
          paths: [
            {
              d: "M10.4 0L0 0L0 2.6L10.4 2.6L10.4 0ZM0 5.2L10.4 5.2L10.4 7.8L0 7.8L0 5.2ZM10.4 10.4L0 10.4L0 13L10.4 13L10.4 10.4Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Group: {},
        TemplateLogo: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector3992369: {
          paths: [
            {
              d: "M0 0L0 7.8L23.4 7.8L23.4 0L0 0ZM20.8 2.6L2.6 2.6L2.6 5.2L20.8 5.2L20.8 2.6ZM0 10.4L0 23.4L10.4 23.4L10.4 10.4L0 10.4ZM7.8 13L2.6 13L2.6 20.8L7.8 20.8L7.8 13Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "evenodd",
            },
          ],
        },
        Vector3992370: {
          paths: [
            {
              d: "M10.4 0L0 0L0 2.6L10.4 2.6L10.4 0ZM0 5.2L10.4 5.2L10.4 7.8L0 7.8L0 5.2ZM10.4 10.4L0 10.4L0 13L10.4 13L10.4 10.4Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        Group: {},
        TemplateLogo: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="13.000000953674316px"
      direction="row"
      width="31.2px"
      height="31.2px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="3.9000000953674316px 3.9000000953674316px 3.9000000953674316px 3.9000000953674316px"
      backgroundColor="rgba(0,0,0,0)"
      display="flex"
      {...getOverrideProps(overrides, "TemplateLogo")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="23.4px"
        height="23.4px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group")}
      >
        <Icon
          width="23.4px"
          height="23.4px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 23.399999618530273,
            height: 23.399999618530273,
          }}
          paths={[
            {
              d: "M0 0L0 7.8L23.4 7.8L23.4 0L0 0ZM20.8 2.6L2.6 2.6L2.6 5.2L20.8 5.2L20.8 2.6ZM0 10.4L0 23.4L10.4 23.4L10.4 10.4L0 10.4ZM7.8 13L2.6 13L2.6 20.8L7.8 20.8L7.8 13Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "evenodd",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector3992369")}
        ></Icon>
        <Icon
          width="10.4px"
          height="13px"
          viewBox={{ minX: 0, minY: 0, width: 10.399999618530273, height: 13 }}
          paths={[
            {
              d: "M10.4 0L0 0L0 2.6L10.4 2.6L10.4 0ZM0 5.2L10.4 5.2L10.4 7.8L0 7.8L0 5.2ZM10.4 10.4L0 10.4L0 13L10.4 13L10.4 10.4Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="44.44%"
          bottom="0%"
          left="55.56%"
          right="0%"
          {...getOverrideProps(overrides, "Vector3992370")}
        ></Icon>
      </Flex>
    </Flex>
  );
}
