/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        mail
        plan
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAudio = /* GraphQL */ `
  query GetAudio($userId: ID!, $id: ID!) {
    getAudio(userId: $userId, id: $id) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const listAudio = /* GraphQL */ `
  query ListAudio(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelAudioFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAudio(
      userId: $userId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        id
        name
        audioPath
        transcriptionPath
        status
        automatic
        speakers
        keyPoints
        gptMetaData
        createdAt
        updatedAt
        categoryAudiosUserId
        categoryAudiosId
        templateAudiosUserId
        templateAudiosId
        PlusPlan
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const audioByIdAndUserId = /* GraphQL */ `
  query AudioByIdAndUserId(
    $id: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAudioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audioByIdAndUserId(
      id: $id
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        id
        name
        audioPath
        transcriptionPath
        status
        automatic
        speakers
        keyPoints
        gptMetaData
        createdAt
        updatedAt
        categoryAudiosUserId
        categoryAudiosId
        templateAudiosUserId
        templateAudiosId
        PlusPlan
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const audioByTranscriptionPathAndUserId = /* GraphQL */ `
  query AudioByTranscriptionPathAndUserId(
    $transcriptionPath: String!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAudioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audioByTranscriptionPathAndUserId(
      transcriptionPath: $transcriptionPath
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        id
        name
        audioPath
        transcriptionPath
        status
        automatic
        speakers
        keyPoints
        gptMetaData
        createdAt
        updatedAt
        categoryAudiosUserId
        categoryAudiosId
        templateAudiosUserId
        templateAudiosId
        PlusPlan
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($userId: ID!, $id: ID!) {
    getCategory(userId: $userId, id: $id) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      userId: $userId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($userId: ID!, $id: ID!) {
    getTemplate(userId: $userId, id: $id) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTemplates(
      userId: $userId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const templatesByIdAndUserId = /* GraphQL */ `
  query TemplatesByIdAndUserId(
    $id: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByIdAndUserId(
      id: $id
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuota = /* GraphQL */ `
  query GetQuota($userId: ID!) {
    getQuota(userId: $userId) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuotas = /* GraphQL */ `
  query ListQuotas(
    $userId: ID
    $filter: ModelQuotaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuotas(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        audioQuota
        audioCount
        categoryQuota
        categoryCount
        templateQuota
        templateCount
        s3Quota
        s3Count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
