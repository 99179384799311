import React, { useState, useRef, useEffect } from 'react';
import { Flex, View } from '@aws-amplify/ui-react';
import { Send } from '../ui-components';

const CustomTextArea = React.forwardRef(({ value, onChange, placeholder, disabled }, ref) => (
  <textarea
    ref={ref}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    disabled={disabled}
    style={{
      width: '100%',
      height: 'auto',
      maxHeight: '375px',
      resize: 'none',
      padding: '10px',
      border: disabled ? '1px solid #DCDEE1' : '1px solid #DCDDE1',
      backgroundColor: disabled ? '#F5F5F5' : 'white',
      borderRadius: '3px',
      boxSizing: 'border-box',
      lineHeight: '1.5',
      overflowY: 'auto',
      outline: 'none',
      cursor: disabled ? 'not-allowed' : 'text',
    }}
    rows="1"
    onFocus={(e) => (e.target.style.border = '1px solid black')}
    onBlur={(e) => (e.target.style.border = disabled ? '1px solid #DCDEE1' : '1px solid #DCDDE1')}
  />
));

const SendC = ({ chatItems = [] }) => {
  const [message, setMessage] = useState('');
  const [sendVariant, setSendVariant] = useState('Disable');
  const textAreaRef = useRef(null);
  const [lastId, setLastId] = useState(0);
  const [canSend, setCanSend] = useState(true);
  const [pendingMessageId, setPendingMessageId] = useState(null);

  useEffect(() => {
    // Load message from local storage on mount
    const savedMessage = localStorage.getItem('chatMessage');
    if (savedMessage) {
      setMessage(savedMessage);
      if (savedMessage.trim() !== '') {
        setSendVariant('Default');
      }
    }

    let highestId = 0;
    let hasMatchingResponse = false;

    chatItems.forEach(item => {
      const id = parseInt(item.id, 10);
      if (!isNaN(id) && id > highestId) {
        highestId = id;
      }
      if (item.item === 'audiodeem' && pendingMessageId !== null && id === pendingMessageId) {
        hasMatchingResponse = true;
      }
    });

    setLastId(highestId + 1);

    if (pendingMessageId === null || hasMatchingResponse) {
      setCanSend(true);
      setPendingMessageId(null); // Reset pendingMessageId if there's a matching response
    } else {
      setCanSend(false);
    }
  }, [chatItems, pendingMessageId]);

  const handleSendClick = () => {
    if (message.trim() !== '' && canSend) {
      console.log(JSON.stringify({ item: 'User', id: lastId.toString(), text: message, status: 'pending' }));
      setMessage('');
      setSendVariant('Disable');
      setPendingMessageId(lastId); // Set pendingMessageId to the id of the sent message
      setLastId(lastId + 1);
      setCanSend(false);
      localStorage.removeItem('chatMessage'); // Clear the message from local storage on send
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto';
      }
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
    localStorage.setItem('chatMessage', newValue); // Save the message to local storage on change
    if (newValue.trim() !== '' && canSend) {
      setSendVariant('Default');
    } else {
      setSendVariant('Disable');
    }
    adjustTextAreaHeight();
  };

  const handleMouseEnter = () => {
    if (message.trim() !== '' && canSend) setSendVariant('Hover');
  };

  const handleMouseLeave = () => {
    if (message.trim() !== '' && canSend) setSendVariant('Default');
  };

  const handleFocus = () => {
    if (message.trim() !== '' && canSend) setSendVariant('Focus');
  };

  const handleBlur = () => {
    if (message.trim() !== '' && canSend) setSendVariant('Default');
  };

  const handleMouseDown = () => {
    if (message.trim() !== '' && canSend) setSendVariant('Focus');
  };

  const handleMouseUp = () => {
    if (message.trim() !== '' && canSend) {
      setSendVariant('Default');
      handleSendClick();
    }
  };

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  return (
    <Flex direction="row" alignItems="flex-end" gap="20px" style={{ width: '100%', margin: '0 auto' }}>
      <Flex direction="column" width="100%">
        <CustomTextArea
          ref={textAreaRef}
          value={message}
          onChange={handleInputChange}
          placeholder="Question..."
          disabled={!canSend}
        />
      </Flex>
      <View
        style={{ cursor: message.trim() !== '' && canSend ? 'pointer' : 'not-allowed', display: 'flex', alignItems: 'center', marginBottom: '5px' }}
        onMouseDown={message.trim() !== '' && canSend ? handleMouseDown : undefined}
        onMouseUp={message.trim() !== '' && canSend ? handleMouseUp : undefined}
        onMouseEnter={message.trim() !== '' && canSend ? handleMouseEnter : undefined}
        onMouseLeave={message.trim() !== '' && canSend ? handleMouseLeave : undefined}
        onFocus={message.trim() !== '' && canSend ? handleFocus : undefined}
        onBlur={message.trim() !== '' && canSend ? handleBlur : undefined}
      >
        <Send variant={sendVariant} />
      </View>
    </Flex>
  );
};

export default SendC;