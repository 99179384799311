import React from 'react';
import { View, Loader, Heading, Flex, useTheme } from '@aws-amplify/ui-react';
import { ManageContainer } from '../ui-components';
import ManageC from './ManageC';

const ManageContainerC = ({
  loading,
  templates,
  selectedTemplateIndex,
  addTemplate,
  deleteTemplate,
  addBlockToTemplate,
  validateTemplates,
  hasChanges,
  onCancel,
  setNewBlockAdded,
}) => {
  const { tokens } = useTheme(); // Access the theme tokens

  return (
    <ManageContainer
      overrides={{
        ManageContainer: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '480px',
          height: '744px',
          padding: '20px',
          boxSizing: 'border-box',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <Flex direction="column" justifyContent="center" alignItems="center" gap="20px" height="100%" padding="20px 5px 0 5px">
        {!loading && (
          <Heading
            level={4}
            style={{
              fontWeight: 600, // Semi-bold
              fontSize: '24px',
              textAlign: 'center',
              marginTop: '20px', // Apply the same padding/margin at the top as the bottom padding
            }}
          >
            Créez et modifiez vos{' '}
            <span style={{ color: tokens.colors.green[20] }}>modèles</span>
          </Heading>
        )}

        {loading ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1000,
            }}
          >
            <Loader size="large" style={{ transform: 'scale(2)' }} />
          </Flex>
        ) : (
          <View style={{ flex: 1, overflowY: 'auto', width: '100%' }}>
            <ManageC
              templates={templates}
              selectedTemplateIndex={selectedTemplateIndex}
              addTemplate={addTemplate}
              deleteTemplate={deleteTemplate}
              addBlockToTemplate={addBlockToTemplate}
              validateTemplates={validateTemplates}
              hasChanges={hasChanges}
              onCancel={onCancel}
              setNewBlockAdded={setNewBlockAdded}
              style={{ width: '100%' }}
            />
          </View>
        )}
      </Flex>
    </ManageContainer>
  );
};

export default ManageContainerC;