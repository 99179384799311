/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function HistoricItem(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Square: {},
        Line: {},
        SquarexLine: {},
        Heading: {},
        Content: {},
        Text: {},
        TextLayout: {},
        HistoricItem: {},
      },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Square: { backgroundColor: "rgba(154,226,161,1)" },
        Line: {},
        SquarexLine: {},
        Heading: { color: "rgba(48,64,80,1)" },
        Content: { color: "rgba(48,64,80,1)" },
        Text: {},
        TextLayout: {},
        HistoricItem: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Square: { backgroundColor: "rgba(49,112,61,1)" },
        Line: {},
        SquarexLine: {},
        Heading: { fontWeight: "700" },
        Content: { fontWeight: "500" },
        Text: {},
        TextLayout: {},
        HistoricItem: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="460px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "HistoricItem")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "SquarexLine")}
      >
        <View
          width="20px"
          height="20px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(76,203,104,1)"
          {...getOverrideProps(overrides, "Square")}
        ></View>
        <View
          width="2px"
          height="unset"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(0,0,0,0)"
          {...getOverrideProps(overrides, "Line")}
        ></View>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 18px 0px"
        display="flex"
        {...getOverrideProps(overrides, "TextLayout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Text")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="16.94318199157715px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Heading"
            {...getOverrideProps(overrides, "Heading")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(13,26,38,1)"
            lineHeight="14.522727012634277px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Content"
            {...getOverrideProps(overrides, "Content")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
