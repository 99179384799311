/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getQuota } from "../graphql/queries";
import { updateQuota } from "../graphql/mutations";
const client = generateClient();
export default function QuotaUpdateForm(props) {
  const {
    userId: userIdProp,
    quota: quotaModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    userId: "",
    audioQuota: "",
    audioCount: "",
    categoryQuota: "",
    categoryCount: "",
    templateQuota: "",
    templateCount: "",
    s3Quota: "",
    s3Count: "",
  };
  const [userId, setUserId] = React.useState(initialValues.userId);
  const [audioQuota, setAudioQuota] = React.useState(initialValues.audioQuota);
  const [audioCount, setAudioCount] = React.useState(initialValues.audioCount);
  const [categoryQuota, setCategoryQuota] = React.useState(
    initialValues.categoryQuota
  );
  const [categoryCount, setCategoryCount] = React.useState(
    initialValues.categoryCount
  );
  const [templateQuota, setTemplateQuota] = React.useState(
    initialValues.templateQuota
  );
  const [templateCount, setTemplateCount] = React.useState(
    initialValues.templateCount
  );
  const [s3Quota, setS3Quota] = React.useState(initialValues.s3Quota);
  const [s3Count, setS3Count] = React.useState(initialValues.s3Count);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = quotaRecord
      ? { ...initialValues, ...quotaRecord }
      : initialValues;
    setUserId(cleanValues.userId);
    setAudioQuota(cleanValues.audioQuota);
    setAudioCount(cleanValues.audioCount);
    setCategoryQuota(cleanValues.categoryQuota);
    setCategoryCount(cleanValues.categoryCount);
    setTemplateQuota(cleanValues.templateQuota);
    setTemplateCount(cleanValues.templateCount);
    setS3Quota(cleanValues.s3Quota);
    setS3Count(cleanValues.s3Count);
    setErrors({});
  };
  const [quotaRecord, setQuotaRecord] = React.useState(quotaModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = userIdProp
        ? (
            await client.graphql({
              query: getQuota.replaceAll("__typename", ""),
              variables: { userId: userIdProp },
            })
          )?.data?.getQuota
        : quotaModelProp;
      setQuotaRecord(record);
    };
    queryData();
  }, [userIdProp, quotaModelProp]);
  React.useEffect(resetStateValues, [quotaRecord]);
  const validations = {
    userId: [{ type: "Required" }],
    audioQuota: [],
    audioCount: [],
    categoryQuota: [],
    categoryCount: [],
    templateQuota: [],
    templateCount: [],
    s3Quota: [],
    s3Count: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          userId,
          audioQuota: audioQuota ?? null,
          audioCount: audioCount ?? null,
          categoryQuota: categoryQuota ?? null,
          categoryCount: categoryCount ?? null,
          templateQuota: templateQuota ?? null,
          templateCount: templateCount ?? null,
          s3Quota: s3Quota ?? null,
          s3Count: s3Count ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateQuota.replaceAll("__typename", ""),
            variables: {
              input: {
                userId: quotaRecord.userId,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "QuotaUpdateForm")}
      {...rest}
    >
      <TextField
        label="User id"
        isRequired={true}
        isReadOnly={true}
        value={userId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId: value,
              audioQuota,
              audioCount,
              categoryQuota,
              categoryCount,
              templateQuota,
              templateCount,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.userId ?? value;
          }
          if (errors.userId?.hasError) {
            runValidationTasks("userId", value);
          }
          setUserId(value);
        }}
        onBlur={() => runValidationTasks("userId", userId)}
        errorMessage={errors.userId?.errorMessage}
        hasError={errors.userId?.hasError}
        {...getOverrideProps(overrides, "userId")}
      ></TextField>
      <TextField
        label="Audio quota"
        isRequired={false}
        isReadOnly={false}
        value={audioQuota}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota: value,
              audioCount,
              categoryQuota,
              categoryCount,
              templateQuota,
              templateCount,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.audioQuota ?? value;
          }
          if (errors.audioQuota?.hasError) {
            runValidationTasks("audioQuota", value);
          }
          setAudioQuota(value);
        }}
        onBlur={() => runValidationTasks("audioQuota", audioQuota)}
        errorMessage={errors.audioQuota?.errorMessage}
        hasError={errors.audioQuota?.hasError}
        {...getOverrideProps(overrides, "audioQuota")}
      ></TextField>
      <TextField
        label="Audio count"
        isRequired={false}
        isReadOnly={false}
        value={audioCount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount: value,
              categoryQuota,
              categoryCount,
              templateQuota,
              templateCount,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.audioCount ?? value;
          }
          if (errors.audioCount?.hasError) {
            runValidationTasks("audioCount", value);
          }
          setAudioCount(value);
        }}
        onBlur={() => runValidationTasks("audioCount", audioCount)}
        errorMessage={errors.audioCount?.errorMessage}
        hasError={errors.audioCount?.hasError}
        {...getOverrideProps(overrides, "audioCount")}
      ></TextField>
      <TextField
        label="Category quota"
        isRequired={false}
        isReadOnly={false}
        value={categoryQuota}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount,
              categoryQuota: value,
              categoryCount,
              templateQuota,
              templateCount,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.categoryQuota ?? value;
          }
          if (errors.categoryQuota?.hasError) {
            runValidationTasks("categoryQuota", value);
          }
          setCategoryQuota(value);
        }}
        onBlur={() => runValidationTasks("categoryQuota", categoryQuota)}
        errorMessage={errors.categoryQuota?.errorMessage}
        hasError={errors.categoryQuota?.hasError}
        {...getOverrideProps(overrides, "categoryQuota")}
      ></TextField>
      <TextField
        label="Category count"
        isRequired={false}
        isReadOnly={false}
        value={categoryCount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount,
              categoryQuota,
              categoryCount: value,
              templateQuota,
              templateCount,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.categoryCount ?? value;
          }
          if (errors.categoryCount?.hasError) {
            runValidationTasks("categoryCount", value);
          }
          setCategoryCount(value);
        }}
        onBlur={() => runValidationTasks("categoryCount", categoryCount)}
        errorMessage={errors.categoryCount?.errorMessage}
        hasError={errors.categoryCount?.hasError}
        {...getOverrideProps(overrides, "categoryCount")}
      ></TextField>
      <TextField
        label="Template quota"
        isRequired={false}
        isReadOnly={false}
        value={templateQuota}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount,
              categoryQuota,
              categoryCount,
              templateQuota: value,
              templateCount,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.templateQuota ?? value;
          }
          if (errors.templateQuota?.hasError) {
            runValidationTasks("templateQuota", value);
          }
          setTemplateQuota(value);
        }}
        onBlur={() => runValidationTasks("templateQuota", templateQuota)}
        errorMessage={errors.templateQuota?.errorMessage}
        hasError={errors.templateQuota?.hasError}
        {...getOverrideProps(overrides, "templateQuota")}
      ></TextField>
      <TextField
        label="Template count"
        isRequired={false}
        isReadOnly={false}
        value={templateCount}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount,
              categoryQuota,
              categoryCount,
              templateQuota,
              templateCount: value,
              s3Quota,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.templateCount ?? value;
          }
          if (errors.templateCount?.hasError) {
            runValidationTasks("templateCount", value);
          }
          setTemplateCount(value);
        }}
        onBlur={() => runValidationTasks("templateCount", templateCount)}
        errorMessage={errors.templateCount?.errorMessage}
        hasError={errors.templateCount?.hasError}
        {...getOverrideProps(overrides, "templateCount")}
      ></TextField>
      <TextField
        label="S3 quota"
        isRequired={false}
        isReadOnly={false}
        value={s3Quota}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount,
              categoryQuota,
              categoryCount,
              templateQuota,
              templateCount,
              s3Quota: value,
              s3Count,
            };
            const result = onChange(modelFields);
            value = result?.s3Quota ?? value;
          }
          if (errors.s3Quota?.hasError) {
            runValidationTasks("s3Quota", value);
          }
          setS3Quota(value);
        }}
        onBlur={() => runValidationTasks("s3Quota", s3Quota)}
        errorMessage={errors.s3Quota?.errorMessage}
        hasError={errors.s3Quota?.hasError}
        {...getOverrideProps(overrides, "s3Quota")}
      ></TextField>
      <TextField
        label="S3 count"
        isRequired={false}
        isReadOnly={false}
        value={s3Count}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              userId,
              audioQuota,
              audioCount,
              categoryQuota,
              categoryCount,
              templateQuota,
              templateCount,
              s3Quota,
              s3Count: value,
            };
            const result = onChange(modelFields);
            value = result?.s3Count ?? value;
          }
          if (errors.s3Count?.hasError) {
            runValidationTasks("s3Count", value);
          }
          setS3Count(value);
        }}
        onBlur={() => runValidationTasks("s3Count", s3Count)}
        errorMessage={errors.s3Count?.errorMessage}
        hasError={errors.s3Count?.hasError}
        {...getOverrideProps(overrides, "s3Count")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(userIdProp || quotaModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(userIdProp || quotaModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
