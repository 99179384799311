import React from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { KeyPointItem } from '../ui-components';

const KeyPointListC = ({ keyPoints }) => {
  return (
    <Flex direction="column" gap="0px" width="100%">
      {keyPoints.map((keyPoint, index) => (
        <KeyPointItem
          key={index}
          variant={index === keyPoints.length - 1 ? 'last' : 'Default'}
          overrides={{
            KeyPoint: {
              children: keyPoint,
            },
            KeyPointItem: {
                width: '100%',
                boxSizing: 'border-box',
                paddingTop: index === 0 ? '20px' : '0px',
              }
          }}
        />
      ))}
    </Flex>
  );
};

export default KeyPointListC;