/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      mail
      plan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAudio = /* GraphQL */ `
  mutation CreateAudio(
    $input: CreateAudioInput!
    $condition: ModelAudioConditionInput
  ) {
    createAudio(input: $input, condition: $condition) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const updateAudio = /* GraphQL */ `
  mutation UpdateAudio(
    $input: UpdateAudioInput!
    $condition: ModelAudioConditionInput
  ) {
    updateAudio(input: $input, condition: $condition) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const deleteAudio = /* GraphQL */ `
  mutation DeleteAudio(
    $input: DeleteAudioInput!
    $condition: ModelAudioConditionInput
  ) {
    deleteAudio(input: $input, condition: $condition) {
      userId
      id
      name
      audioPath
      transcriptionPath
      status
      category {
        userId
        id
        name
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      template {
        userId
        id
        name
        templatePath
        createdAt
        updatedAt
        PlusPlan
        __typename
      }
      automatic
      speakers
      keyPoints
      gptMetaData
      createdAt
      updatedAt
      categoryAudiosUserId
      categoryAudiosId
      templateAudiosUserId
      templateAudiosId
      PlusPlan
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      userId
      id
      name
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      userId
      id
      name
      templatePath
      audios {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      PlusPlan
      __typename
    }
  }
`;
export const createQuota = /* GraphQL */ `
  mutation CreateQuota(
    $input: CreateQuotaInput!
    $condition: ModelQuotaConditionInput
  ) {
    createQuota(input: $input, condition: $condition) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuota = /* GraphQL */ `
  mutation UpdateQuota(
    $input: UpdateQuotaInput!
    $condition: ModelQuotaConditionInput
  ) {
    updateQuota(input: $input, condition: $condition) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuota = /* GraphQL */ `
  mutation DeleteQuota(
    $input: DeleteQuotaInput!
    $condition: ModelQuotaConditionInput
  ) {
    deleteQuota(input: $input, condition: $condition) {
      userId
      audioQuota
      audioCount
      categoryQuota
      categoryCount
      templateQuota
      templateCount
      s3Quota
      s3Count
      createdAt
      updatedAt
      __typename
    }
  }
`;
