/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Rec(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { Rec: {} }, variantValues: { variant: "Rec" } },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M13.625 0C10.0114 3.55271e-15 6.54585 1.43549 3.99067 3.99067C1.43549 6.54585 3.55271e-15 10.0114 0 13.625C3.55271e-15 17.2386 1.43549 20.7041 3.99067 23.2593C6.54585 25.8145 10.0114 27.25 13.625 27.25C17.2386 27.25 20.7041 25.8145 23.2593 23.2593C25.8145 20.7041 27.25 17.2386 27.25 13.625C27.25 10.0114 25.8145 6.54585 23.2593 3.99067C20.7041 1.43549 17.2386 0 13.625 0Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
              style: { transform: "translate(15%, 15%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecFocus" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M13.625 0C10.0114 3.55271e-15 6.54585 1.43549 3.99067 3.99067C1.43549 6.54585 3.55271e-15 10.0114 0 13.625C3.55271e-15 17.2386 1.43549 20.7041 3.99067 23.2593C6.54585 25.8145 10.0114 27.25 13.625 27.25C17.2386 27.25 20.7041 25.8145 23.2593 23.2593C25.8145 20.7041 27.25 17.2386 27.25 13.625C27.25 10.0114 25.8145 6.54585 23.2593 3.99067C20.7041 1.43549 17.2386 0 13.625 0Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
              style: { transform: "translate(15%, 15%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecHover" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M12 21L12 0L18 0L18 21L12 21ZM0 21L0 0L6 0L6 21L0 21Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "nonzero",
              style: { transform: "translate(27.5%, 22.5%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecPause" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M12 21L12 0L18 0L18 21L12 21ZM0 21L0 0L6 0L6 21L0 21Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
              style: { transform: "translate(27.5%, 22.5%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecPauseFocus" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M12 21L12 0L18 0L18 21L12 21ZM0 21L0 0L6 0L6 21L0 21Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
              style: { transform: "translate(27.5%, 22.5%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecPauseHover" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M0 0L0 21L16.5 10.5L0 0Z",
              fill: "rgba(76,203,104,1)",
              fillRule: "nonzero",
              style: { transform: "translate(35%, 22.5%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecPlay" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M0 0L0 21L16.5 10.5L0 0Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
              style: { transform: "translate(35%, 22.5%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecPlayFocus" },
    },
    {
      overrides: {
        Rec: {
          paths: [
            {
              d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
              fill: "rgba(199,239,202,1)",
              fillRule: "nonzero",
              style: { transform: "translate(0px, 0px)" },
            },
            {
              d: "M0 0L0 21L16.5 10.5L0 0Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
              style: { transform: "translate(35%, 22.5%)" },
            },
          ],
        },
      },
      variantValues: { variant: "RecPlayHover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="40px"
      height="40px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
      paths={[
        {
          d: "M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z",
          fill: "rgba(199,239,202,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0px, 0px)" },
        },
        {
          d: "M13.625 0C10.0114 3.55271e-15 6.54585 1.43549 3.99067 3.99067C1.43549 6.54585 3.55271e-15 10.0114 0 13.625C3.55271e-15 17.2386 1.43549 20.7041 3.99067 23.2593C6.54585 25.8145 10.0114 27.25 13.625 27.25C17.2386 27.25 20.7041 25.8145 23.2593 23.2593C25.8145 20.7041 27.25 17.2386 27.25 13.625C27.25 10.0114 25.8145 6.54585 23.2593 3.99067C20.7041 1.43549 17.2386 0 13.625 0Z",
          fill: "rgba(76,203,104,1)",
          fillRule: "nonzero",
          style: { transform: "translate(15%, 15%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Rec")}
      {...rest}
    ></Icon>
  );
}
