import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Flex, Loader } from '@aws-amplify/ui-react';
import { TabBarItem } from '../ui-components';

const TabBarCatC = ({ categories, onCategoryChange, loading }) => {
  const [activeCategory, setActiveCategory] = useState('Tous');
  const [fontSize, setFontSize] = useState('16px');
  const containerRef = useRef(null);

  const handleTabClick = (category) => {
    setActiveCategory(category);
    onCategoryChange(category);
  };

  const adjustFontSize = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const totalItems = ['Tous', ...categories].length;
      const maxItemLength = Math.max(...['Tous', ...categories].map(cat => cat.length));
      const newFontSize = Math.min(20, containerWidth / (totalItems * maxItemLength));
      setFontSize(`${newFontSize}px`);
    }
  }, [categories]);

  useEffect(() => {
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, [adjustFontSize, categories]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {/* Original component rendered with hidden visibility */}
      <Flex
        ref={containerRef}
        direction="row"
        justifyContent="space-between"
        width="100%"
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        {['Tous', ...categories].map((category, index) => (
          <Flex key={index} flex="1" justifyContent="center">
            <TabBarItem
              variant={category === activeCategory ? 'Active' : 'Default'}
              overrides={{
                Item: {
                  children: category,
                  fontSize,
                },
                TabBarItem: {
                  onClick: () => handleTabClick(category),
                  style: { cursor: 'pointer', flexBasis: 'auto', flexShrink: 0 },
                },
              }}
            />
          </Flex>
        ))}
      </Flex>

      {/* Loader overlay */}
      {loading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: light background to make loader more visible
        }}>
          <Loader size="large" style={{ transform: 'scale(1.5)' }} />
        </div>
      )}
    </div>
  );
};

export default TabBarCatC;