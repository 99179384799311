import React from 'react';
import TabBarC from '../react-components/TabBarC';
import BackgroundC from '../react-components/BackgroundC';
import SideBarC from '../react-components/SideBarC';
import ChatContainerC from '../react-components/ChatContainerC';
import HistoricContainerC from '../react-components/HistoricContainerC';

const AnalysePage = () => {
  const chatItems = [
    { item: 'User', id: '1', status: 'success', text: 'Comment Renault a-t-il géré les défis liés à la crise des semi-conducteurs en 2022 ?' },
    { item: 'audiodeem', id: '1', status: 'success', text: 'Renault a optimisé la gestion de ses stocks, ajusté la production pour maximiser l’utilisation des composants disponibles et priorisé la fabrication de véhicules à forte marge.' },
    { item: 'User', id: '2', status: 'success', text: 'Quels ont été les principaux facteurs contribuant à l’augmentation de la marge par véhicule de 40% par rapport à 2020 ?' },
    { item: 'audiodeem', id: '2', status: 'success', text: 'Les principaux facteurs incluent l’amélioration de l’efficacité opérationnelle, la réduction des coûts de production, et l’augmentation des prix de vente moyens.' },
    { item: 'User', id: '3', status: 'success', text: 'Quels sont les objectifs principaux de l’accord conclu entre Renault, Nissan et Mitsubishi le 6 février ?' },
    { item: 'audiodeem', id: '3', status: 'success', text: 'Les objectifs sont de maximiser la création de valeur pour toutes les parties, augmenter l’agilité et renforcer les plans stratégiques des entreprises.' },
    { item: 'User', id: '4', status: 'success', text: 'Comment les projets Ampère et Horse sont-ils intégrés dans la stratégie globale de Renault pour l’électrification et la réduction des émissions ?' },
    { item: 'audiodeem', id: '4', status: 'success', text: 'Ampère se concentre sur les véhicules électriques et logiciels, tandis que Horse se focalise sur les technologies de motorisation thermique et hybride à faible émission.' },
    { item: 'User', id: '5', status: 'success', text: 'Quelle a été l’ampleur de la participation des employés dans le plan d’actionnariat salarié lancé en novembre ?' },
    { item: 'audiodeem', id: '5', status: 'success', text: '40 000 employés ont acheté des actions Renault, montrant un fort engagement.' },
    { item: 'User', id: '6', status: 'success', text: 'Comment cette participation a-t-elle impacté la confiance des équipes dans la stratégie de l’entreprise ?' },
    { item: 'audiodeem', id: '6', status: 'success', text: 'La participation a renforcé la confiance, avec une augmentation de près de 30% depuis 2020.' },
    { item: 'User', id: '7', status: 'success', text: 'Quelles sont les six plateformes digitales développées par Renault et quels bénéfices en sont attendus ?' },
    { item: 'audiodeem', id: '7', status: 'success', text: 'Les plateformes visent à améliorer l’efficacité, harmoniser les données, augmenter la productivité et réduire les coûts énergétiques.' },
    { item: 'User', id: '8', status: 'success', text: 'Comment le partenariat avec Google vise-t-il à réduire la consommation énergétique des usines de Renault ?' },
    { item: 'audiodeem', id: '8', status: 'success', text: 'Le partenariat avec Google vise à réduire la consommation énergétique des usines de 30% d’ici 2025.' },
    { item: 'User', id: '9', status: 'success', text: 'Quels nouveaux modèles Renault a-t-il lancés entre 2023 et 2025 et quels résultats sont attendus de ces lancements ?' },
    { item: 'audiodeem', id: '9', status: 'success', text: 'Renault prévoit 18 nouveaux modèles, dont Jogger, Espace et Clio, visant à renforcer sa présence sur les segments à forte croissance.' },
    { item: 'User', id: '10', status: 'success', text: 'Comment les performances de Dacia et Alpine en 2022 ont-elles contribué aux résultats globaux du groupe ?' },
    { item: 'audiodeem', id: '10', status: 'success', text: 'Dacia a enregistré une croissance de 7% et Alpine de 33%, contribuant positivement aux résultats de Renault.' },
    { item: 'User', id: '11', status: 'success', text: 'Quelle a été la marge opérationnelle de Renault en 2022 et comment cela se compare-t-il à 2021 ?' },
    { item: 'audiodeem', id: '11', status: 'success', text: 'En 2022, la marge opérationnelle était de 5,6%, une amélioration significative par rapport aux 2,8% de 2021.' },
    { item: 'User', id: '12', status: 'success', text: 'Quelles sont les prévisions financières de Renault pour 2023 en termes de marge opérationnelle et de free cash flow opérationnel ?' },
    { item: 'audiodeem', id: '12', status: 'success', text: 'Pour 2023, Renault prévoit une marge opérationnelle supérieure ou égale à 6% et un free cash flow opérationnel supérieur ou égal à 2 milliards d’euros.' },
    { item: 'User', id: '13', status: 'success', text: 'Quels sont les objectifs de Renault en matière de réduction des émissions de CO2 et de neutralité carbone ?' },
    { item: 'audiodeem', id: '13', status: 'success', text: 'Renault vise la neutralité carbone en 2040 en Europe et en 2050 mondialement, avec 100% des nouveaux véhicules électrifiés d’ici 2030 en Europe.' },
    { item: 'User', id: '14', status: 'success', text: 'Comment l’initiative "The Future is Neutral" contribue-t-elle aux efforts de décarbonation du groupe ?' },
    { item: 'audiodeem', id: '14', status: 'success', text: 'Elle se concentre sur le recyclage des batteries et d’autres initiatives, générant 840 millions d’euros en revenus, contribuant à la décarbonation.' },
    { item: 'User', id: '15', status: 'success', text: 'Quels changements ont été apportés à la composition du Conseil d’administration lors de l’Assemblée Générale ?' },
    { item: 'audiodeem', id: '15', status: 'success', text: 'Luca De Meo a été nommé administrateur, Jean-Dominique Senard renouvelé en tant que président, et Annette Winclair renouvelée comme administratrice.' },
    { item: 'User', id: '16', status: 'success', text: 'Quels sont les éléments clés de la politique de rémunération des mandataires sociaux pour 2023 ?' },
    { item: 'audiodeem', id: '16', status: 'success', text: 'Pour 2023, la rémunération fixe du directeur général reste à 1,3 million d’euros, avec une rémunération variable à 150% de ce montant. Un plan d’action de performance additionnel de 30% est conditionné par la réalisation du plan de transformation.' },
];;

  const historicItems = [
    {
      audio: 'Assemblée Générale Renault',
      date: '2024-07-09T17:08:56Z',
    }
  ];

  return (
    <div className="AnalysePage" style={{ width: '100vw', height: '100vh', overflowY: 'auto', position: 'relative' }}>
      <BackgroundC>
        <div style={{ position: 'relative', zIndex: 1002 }}>
          <SideBarC />
        </div>
        <div style={{ marginLeft: '90px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 80px)', minHeight: '100%' }}>
          <div style={{ display: 'flex', width: '100%', maxWidth: '1440px', flex: '1 0 auto', justifyContent: 'space-between', padding: '10px', boxSizing: 'border-box' }}>
            <div style={{ flex: '1 1 66%', paddingRight: '10px', overflow: 'visible' }}>
              <div style={{ marginBottom: '15px' }}>
                <TabBarC />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'visible' }}>
                <ChatContainerC chatItems={chatItems} />
              </div>
            </div>
            <div style={{ flex: '1 1 27%', paddingTop: '25px', paddingLeft: '10px', paddingRight: '20px', overflow: 'visible' }}>
            <HistoricContainerC historicItems={historicItems} currentAudio={'Audio File 2'} />
            </div>
          </div>
        </div>
      </BackgroundC>
    </div>
  );
}

export default AnalysePage;