import React, { useState, useEffect } from 'react';
import {
  Flex,
  TextField,
  SelectField,
  Button,
  View,
  Text,
  Alert,
  useTheme
} from '@aws-amplify/ui-react';
import { PiUploadSimpleLight } from 'react-icons/pi';

const UploadC = ({ categories: initialCategories, templates, onUpload, maxCategory, recordedAudio }) => {
  const [fileName, setFileName] = useState('');
  const [displayFileName, setDisplayFileName] = useState('');
  const [fileCategory, setFileCategory] = useState('');
  const [fileTemplate, setFileTemplate] = useState('');
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState(initialCategories);
  const [newCategory, setNewCategory] = useState('');
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { tokens } = useTheme();

  useEffect(() => {
    if (recordedAudio) {
      setFile(recordedAudio);
      setFileName(recordedAudio.name);
      const fileNameWithoutExtension = recordedAudio.name.replace(/\.[^/.]+$/, "");
      setDisplayFileName(fileNameWithoutExtension);
    }
  }, [recordedAudio]);

  useEffect(() => {
    setCategories(initialCategories);
  }, [initialCategories]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && (droppedFile.type.startsWith('audio/') || droppedFile.type.startsWith('video/'))) {
      setFile(droppedFile);
      setFileName(droppedFile.name);
      const fileNameWithoutExtension = droppedFile.name.replace(/\.[^/.]+$/, "");
      setDisplayFileName(fileNameWithoutExtension);
    } else {
      setErrorMessage('Veuillez télécharger un fichier audio ou vidéo.');
      setShowError(true);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type.startsWith('audio/') || selectedFile.type.startsWith('video/'))) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
      setDisplayFileName(fileNameWithoutExtension);
    } else {
      setErrorMessage('Veuillez télécharger un fichier audio ou vidéo.');
      setShowError(true);
    }
  };

  const handleAddCategoryClick = () => {
    if (categories.length >= maxCategory) {
      setErrorMessage(`Vous ne pouvez pas ajouter plus de ${maxCategory} catégories.`);
      setShowError(true);
    } else {
      setShowAddCategory(true);
    }
  };

  const handleUpload = () => {
    if (file && displayFileName && fileCategory && fileTemplate) {
      const normalizedCategories = categories.map(category => category.trim().toLowerCase());
      const normalizedNewCategory = newCategory.trim().toLowerCase();

      if (showAddCategory && normalizedCategories.includes(normalizedNewCategory)) {
        setErrorMessage('Cette catégorie existe déjà.');
        setShowError(true);
      } else {
        if (showAddCategory) {
          setCategories([...categories, newCategory]);
        }
        const extension = fileName.substring(fileName.lastIndexOf('.'));
        const completeFileName = `${displayFileName}${extension}`;
        
        const isAutomatic = fileTemplate === 'Automatique';
        
        onUpload({ file, fileName: completeFileName, fileCategory, fileTemplate: isAutomatic ? null : fileTemplate, isAutomatic });
        resetForm();
      }
    } else {
      setErrorMessage('Veuillez remplir tous les champs et sélectionner un fichier.');
      setShowError(true);
    }
  };

  const handleCancelAddCategory = () => {
    setNewCategory('');
    setShowAddCategory(false);
    setFileCategory('');
  };

  const resetForm = () => {
    setFile(null);
    setFileName('');
    setDisplayFileName('');
    setFileCategory('');
    setFileTemplate('');
    setNewCategory('');
    setShowAddCategory(false);
    setCategories(initialCategories);
  };

  const dismissError = () => {
    setShowError(false);
    setErrorMessage('');
  };

  return (
    <Flex direction="column" gap="1.2rem" alignItems="center" padding="2rem 0 0 0" width="100%">
      {showError && (
        <View
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1002',
          }}
        >
          <Alert
            variation="error"
            isDismissible={false}
            hasIcon={true}
            heading="Erreur"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              maxWidth: '400px',
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              border: '2px solid black',
            }}
          >
            <Text style={{ paddingTop: '20px' }}>{errorMessage}</Text>
            <Flex justifyContent="flex-end" gap="10px" marginTop="20px">
              <Button size="small" variation="warning" onClick={dismissError}>
                OK
              </Button>
            </Flex>
          </Alert>
        </View>
      )}
      <View
        border={`2px dashed ${file ? tokens.colors.brand.primary[80] : tokens.colors.neutral[40]}`}
        backgroundColor={file ? tokens.colors.brand.primary[10] : tokens.colors.neutral[10]}
        padding="2rem"
        width="100%"
        height="180px"
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept="audio/*,video/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="fileInput"
        />
        <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          {!file && <PiUploadSimpleLight size={50} color={tokens.colors.brand.primary[40]} />}
          <span style={{ paddingTop: '1rem', lineHeight: '1.5' }}>
            {file ? `${file.name}` : 'Enregistrer ou sélectionner un fichier audio ou vidéo'}
          </span>
        </label>
      </View>
      <TextField
        label="Nom de l'audio"
        labelHidden
        value={displayFileName}
        onChange={(e) => setDisplayFileName(e.target.value)}
        placeholder="Nom de l'audio"
        width="100%"
      />
      {showAddCategory ? (
        <Flex direction="row" gap="1rem" width="100%">
          <TextField
            label="Nouvelle catégorie"
            labelHidden
            value={newCategory}
            onChange={(e) => {
              setNewCategory(e.target.value);
              setFileCategory(e.target.value);
            }}
            placeholder="Nouvelle catégorie"
            width="100%"
          />
          <Button onClick={handleCancelAddCategory} variation="warning">
            Annuler
          </Button>
        </Flex>
      ) : (
        <SelectField
          label="Catégorie"
          labelHidden
          placeholder="Catégorie"
          value={fileCategory}
          onChange={(e) => setFileCategory(e.target.value)}
          width="100%"
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </SelectField>
      )}
      {!showAddCategory && (
        <Button onClick={handleAddCategoryClick} variation="link">
          + Ajouter une catégorie
        </Button>
      )}
      <SelectField
        label="Modèle"
        labelHidden
        placeholder="Modèle"
        value={fileTemplate}
        onChange={(e) => setFileTemplate(e.target.value)}
        width="100%"
      >
        <option value="Automatique">Automatique</option>
        {templates.map((template) => (
          <option key={template} value={template}>
            {template}
          </option>
        ))}
      </SelectField>
      <Button onClick={handleUpload} variation="primary" width="100%" marginBottom="0">
        Transférer
      </Button>
    </Flex>
  );
};

export default UploadC;