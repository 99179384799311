/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function LogoutLogo(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Vector: {}, LogoutLogo: {} },
      variantValues: { variant: "Default" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 20.8L0 0L10.426 0L10.426 1.3L1.3 1.3L1.3 19.5L10.426 19.5L10.426 20.8L0 20.8ZM16.2006 15.0007L15.288 14.0647L18.3027 11.05L6.7496 11.05L6.7496 9.75L18.3027 9.75L15.2867 6.734L16.1993 5.8006L20.8 10.4L16.2006 15.0007Z",
              fill: "rgba(154,226,161,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoutLogo: {},
      },
      variantValues: { variant: "Hover" },
    },
    {
      overrides: {
        Vector: {
          paths: [
            {
              d: "M0 20.8L0 0L10.426 0L10.426 1.3L1.3 1.3L1.3 19.5L10.426 19.5L10.426 20.8L0 20.8ZM16.2006 15.0007L15.288 14.0647L18.3027 11.05L6.7496 11.05L6.7496 9.75L18.3027 9.75L15.2867 6.734L16.1993 5.8006L20.8 10.4L16.2006 15.0007Z",
              fill: "rgba(49,112,61,1)",
              fillRule: "nonzero",
            },
          ],
        },
        LogoutLogo: {},
      },
      variantValues: { variant: "Focus" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="13.000000953674316px"
      direction="row"
      width="31.2px"
      height="31.2px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="5.200000286102295px 5.200000286102295px 5.200000286102295px 5.200000286102295px"
      backgroundColor="rgba(0,0,0,0)"
      display="flex"
      {...getOverrideProps(overrides, "LogoutLogo")}
      {...rest}
    >
      <Icon
        width="20.8px"
        height="20.8px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 20.799999237060547,
          height: 20.799999237060547,
        }}
        paths={[
          {
            d: "M0 20.8L0 0L10.426 0L10.426 1.3L1.3 1.3L1.3 19.5L10.426 19.5L10.426 20.8L0 20.8ZM16.2006 15.0007L15.288 14.0647L18.3027 11.05L6.7496 11.05L6.7496 9.75L18.3027 9.75L15.2867 6.734L16.1993 5.8006L20.8 10.4L16.2006 15.0007Z",
            fill: "rgba(76,203,104,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Vector")}
      ></Icon>
    </Flex>
  );
}
